import type { ChangeEvent } from 'react';
import { useMemo, useRef, useState } from 'react';

import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { InputIconAffix } from 'components/Input/affixes/InputIconAffix';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';
import { Stack } from 'components/Stack/Stack';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { useSearchFacetReset } from 'modules/search/hooks/facets/useSearchFacetReset';
import { useSearchSelectFacetOption } from 'modules/search/hooks/facets/useSearchSelectFacetOption';
import { spacing } from 'theme/theme';

import {
  SearchFacetOptionInput,
  SearchFacetOptionLabel,
  SearchFacetOptionsHolder,
} from './SearchFacetOptions.styled';

export type Props = {
  facetType: SearchRefinementType;
  initialValue?: string[] | string;
  options: { value: string; label: string }[];
  searchable?: boolean;
  variant?: 'accordion' | 'dropdown';
};

export function SearchFacetRadioButtonOptions({
  initialValue = [],
  facetType,
  options,
  searchable,
  variant = 'dropdown',
}: Props) {
  const resetFacet = useSearchFacetReset(facetType);
  const selectFacet = useSearchSelectFacetOption(facetType);

  const filterSearchRef = useRef<HTMLInputElement | null>(null);
  const [filterValue, setFilterValue] = useState('');

  const renderedOptions = useMemo(
    () =>
      searchable
        ? options.filter((option) =>
            option.label.toLowerCase().includes(filterValue.toLowerCase()),
          )
        : options,
    [searchable, filterValue, options],
  );

  return (
    <SearchFacetOptionsHolder>
      {searchable ? (
        <Input
          aria-label="search filter options"
          inputProps={{
            value: filterValue,
            onChange: (e) => setFilterValue(e.target.value),
            qaId: 'search-facet-options-filter-input',
            placeholder: 'Search',
            ref: filterSearchRef,
            type: 'text',
            inputMode: 'search',
          }}
          prefix={<InputIconAffix iconName="search" noPaddingRight />}
          affixVariant="transparent"
        />
      ) : null}
      <ScrollArea
        direction="vertical"
        height="290px"
        showScrollShadowStart={searchable}
        showScrollShadowEnd
        qaId="search-facet-scroll-area"
        scrollShadowSize={spacing[24]}
      >
        <Stack direction="column" spacing={spacing[8]}>
          {renderedOptions.map((option) => (
            <SearchFacetOptionLabel
              key={option.label}
              data-qa-id="search-facet-option"
            >
              <SearchFacetOptionInput
                defaultChecked={initialValue === option.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  selectFacet(e.target.value)
                }
                value={option.value}
                name={`${facetType}-${variant}`} // We have to add variant to the `name` to make distinct radio groups, otherwise the "overflow" version will default to being empty.
                type="radio"
              />
              {option.label}
            </SearchFacetOptionLabel>
          ))}
        </Stack>
      </ScrollArea>
      <Button
        variant="secondary"
        type="button"
        onClick={resetFacet}
        data-qa-id="search-reset-facet-options"
      >
        {getText('Reset')}
      </Button>
    </SearchFacetOptionsHolder>
  );
}
