import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardNotificationPreferencesRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardNotificationPreferencesLoadable } from './OrgDashboardNotificationPreferencesLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardNotificationPreferencesRoute>,
) {
  await OrgDashboardNotificationPreferencesLoadable.load();
}

export default function OrgDashboardNotificationPreferencesHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardNotificationPreferencesLoadable />;
}
