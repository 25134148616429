import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardManageIntegrationsRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardIntegrationsPageContainerLoadable } from './OrgDashboardIntegrationsPageContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardManageIntegrationsRoute>,
) {
  await OrgDashboardIntegrationsPageContainerLoadable.load();
}

// TODO: create a separate route for manage integration type
export default function OrgDashboardManageIntegrationsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardIntegrationsPageContainerLoadable />;
}
