import queryString from 'query-string';

import type {
  ApiISODateTimeNoTzString,
  ApiISODateTimeString,
} from 'api/types/ApiTypedDate';

import { getJson } from './http/getJson';

export function getUserTimezone() {
  if (!Intl?.DateTimeFormat) return null;

  const format = Intl.DateTimeFormat();

  if (!format?.resolvedOptions) return null;

  const { timeZone } = format.resolvedOptions();

  return timeZone;
}

export async function getTimezoneFromLatLng(
  lat: number,
  lng: number,
): Promise<string> {
  return getJson<{
    utc: ApiISODateTimeString;
    atPosition: ApiISODateTimeNoTzString;
    timezone: string;
  }>(
    queryString.stringifyUrl({
      url: '/data/time/at-position',
      query: { lat, lng },
    }),
  ).then(({ timezone }) => timezone);
}
