import type { RouteObject } from 'react-router';

import * as orgDashboardCheckoutCartHorizonRouteExports from 'modules/orgDashboard/checkout/pages/Cart/OrgDashboardCheckoutCart.horizon-route';
import * as orgDashboardManageAdminsHorizonRouteExports from 'modules/orgDashboard/pages/admins/manage/OrgDashboardManageAdmins.horizon-route';
import * as orgDashboardApiAccessHorizonRouteExports from 'modules/orgDashboard/pages/apiAccess/manage/OrgDashboardApiAccess.horizon-route';
import * as orgDashboardCheckoutBillingAddressHorizonRouteExports from 'modules/orgDashboard/pages/checkout/billingAddress/OrgDashboardCheckoutBillingAddress.horizon-route';
import * as orgDashboardCartHorizonRouteExports from 'modules/orgDashboard/pages/checkout/cart/OrgDashboardCart.horizon-route';
import * as orgDashboardPayByCreditCardHorizonRouteExports from 'modules/orgDashboard/pages/checkout/payByCreditCard/OrgDashboardPayByCreditCard.horizon-route';
import * as orgDashboardBuyCreditPacksHorizonRouteExports from 'modules/orgDashboard/pages/credits/buyPacks/OrgDashboardBuyCreditPacks.horizon-route';
import * as orgDashboardListCreditsHorizonRouteExports from 'modules/orgDashboard/pages/credits/list/OrgDashboardListCredits.horizon-route';
import * as orgDashboardViewCreditPackHorizonRouteExports from 'modules/orgDashboard/pages/credits/viewPack/OrgDashboardViewCreditPack.horizon-route';
import * as orgDashboardCreateEventHorizonRouteExports from 'modules/orgDashboard/pages/events/create/OrgDashboardCreateEvent.horizon-route';
import * as orgDashboardEditEventHorizonRouteExports from 'modules/orgDashboard/pages/events/edit/OrgDashboardEditEvent.horizon-route';
import * as orgDashboardPreviewEventHorizonRouteExports from 'modules/orgDashboard/pages/events/preview/OrgDashboardPreviewEvent.horizon-route';
import * as orgDashboardRepostEventHorizonRouteExports from 'modules/orgDashboard/pages/events/repost/OrgDashboardRepostEvent.horizon-route';
import * as orgDashboardViewEventHorizonRouteExports from 'modules/orgDashboard/pages/events/view/OrgDashboardViewEvent.horizon-route';
import * as orgDashboardGradFairsHomeHorizonRouteExports from 'modules/orgDashboard/pages/gradFairs/home/OrgDashboardGradFairsHome.horizon-route';
import * as orgDashboardViewGradFairInviteHorizonRouteExports from 'modules/orgDashboard/pages/gradFairs/invite/OrgDashboardViewGradFairInvite.horizon-route';
import * as orgDashboardManageIntegrationsHorizonRouteExports from 'modules/orgDashboard/pages/integrations/manage/OrgDashboardManageIntegrations.horizon-route';
import * as orgDashboardCreateInternshipHorizonRouteExports from 'modules/orgDashboard/pages/internships/create/OrgDashboardCreateInternship.horizon-route';
import * as orgDashboardEditInternshipHorizonRouteExports from 'modules/orgDashboard/pages/internships/edit/OrgDashboardEditInternship.horizon-route';
import * as orgDashboardPreviewInternshipHorizonRouteExports from 'modules/orgDashboard/pages/internships/preview/OrgDashboardPreviewInternship.horizon-route';
import * as orgDashboardRepostInternshipHorizonRouteExports from 'modules/orgDashboard/pages/internships/repost/OrgDashboardRepostInternship.horizon-route';
import * as orgDashboardViewInternshipHorizonRouteExports from 'modules/orgDashboard/pages/internships/view/OrgDashboardViewInternship.horizon-route';
import * as orgDashboardInvoiceBillingAddressHorizonRouteExports from 'modules/orgDashboard/pages/invoices/billingAddress/OrgDashboardInvoiceBillingAddress.horizon-route';
import * as orgDashboardInvoicesListHorizonRouteExports from 'modules/orgDashboard/pages/invoices/list/OrgDashboardInvoicesList.horizon-route';
import * as orgDashboardViewInvoiceHorizonRouteExports from 'modules/orgDashboard/pages/invoices/view/OrgDashboardViewInvoice.horizon-route';
import * as orgDashboardCreateJobHorizonRouteExports from 'modules/orgDashboard/pages/jobs/create/OrgDashboardCreateJob.horizon-route';
import * as orgDashboardEditJobHorizonRouteExports from 'modules/orgDashboard/pages/jobs/edit/OrgDashboardEditJob.horizon-route';
import * as orgDashboardPreviewJobHorizonRouteExports from 'modules/orgDashboard/pages/jobs/preview/OrgDashboardPreviewJob.horizon-route';
import * as orgDashboardRepostJobHorizonRouteExports from 'modules/orgDashboard/pages/jobs/repost/OrgDashboardRepostJob.horizon-route';
import * as orgDashboardViewJobHorizonRouteExports from 'modules/orgDashboard/pages/jobs/view/OrgDashboardViewJob.horizon-route';
import * as orgDashboardManageListingsHorizonRouteExports from 'modules/orgDashboard/pages/listings/manage/OrgDashboardManageListings.horizon-route';
import * as orgDashboardPostAListingHorizonRouteExports from 'modules/orgDashboard/pages/listings/post/OrgDashboardPostAListingPage.horizon-route';
import * as orgDashboardViewListingApplicationHorizonRouteExports from 'modules/orgDashboard/pages/listings/viewApplication/OrgDashboardViewListingApplication.horizon-route';
import * as orgDashboardNotFoundHorizonRouteExports from 'modules/orgDashboard/pages/notFound/OrgDashboardNotFound.horizon-route';
import * as orgDashboardNotificationPreferencesHorizonRouteExports from 'modules/orgDashboard/pages/notificationPreferences/manage/OrgDashboardNotificationPreferences.horizon-route';
import * as orgDashboardEditOrgHorizonRouteExports from 'modules/orgDashboard/pages/orgs/edit/OrgDashboardEditOrg.horizon-route';
import * as orgDashboardPreviewOrgHorizonRouteExports from 'modules/orgDashboard/pages/orgs/preview/OrgDashboardPreviewOrg.horizon-route';
import * as orgDashboardPaymentsListHorizonRouteExports from 'modules/orgDashboard/pages/payments/list/OrgDashboardListPaymentsHorizonRoute';
import * as orgDashboardPaymentSummaryHorizonRouteExports from 'modules/orgDashboard/pages/payments/summary/OrgDashboardPaymentSummary.horizon-route';
import * as orgDashboardViewPaymentHorizonRouteExports from 'modules/orgDashboard/pages/payments/view/OrgDashboardViewPayment.horizon-route';
import * as orgDashboardViewJobRecommendedCandidateHorizonRouteExports from 'modules/orgDashboard/pages/recommendedCandidates/view/OrgDashboardViewJobRecommendedCandidate.horizon-route';
import * as orgDashboardCreateVolopHorizonRouteExports from 'modules/orgDashboard/pages/volops/create/OrgDashboardCreateVolop.horizon-route';
import * as orgDashboardEditVolopHorizonRouteExports from 'modules/orgDashboard/pages/volops/edit/OrgDashboardEditVolop.horizon-route';
import * as orgDashboardPreviewVolopHorizonRouteExports from 'modules/orgDashboard/pages/volops/preview/OrgDashboardPreviewVolop.horizon-route';
import * as orgDashboardRepostVolopHorizonRouteExports from 'modules/orgDashboard/pages/volops/repost/OrgDashboardRepostVolop.horizon-route';
import * as orgDashboardViewVolopHorizonRouteExports from 'modules/orgDashboard/pages/volops/view/OrgDashboardViewVolop.horizon-route';
import * as orgDashboardOrgSubLayoutHorizonRouteExports from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayout.horizon-route';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

import { orgSelectRouteObjects } from './org-select.route-objects';
import {
  orgDashboardApiAccessRoute,
  orgDashboardBuyCreditPacksRoute,
  orgDashboardCartRoute,
  orgDashboardCheckoutBillingAddressRoute,
  orgDashboardCheckoutCartRoute,
  orgDashboardCreateEventRoute,
  orgDashboardCreateInternshipRoute,
  orgDashboardCreateJobRoute,
  orgDashboardCreateVolopRoute,
  orgDashboardEditEventRoute,
  orgDashboardEditInternshipRoute,
  orgDashboardEditJobRoute,
  orgDashboardEditOrgRoute,
  orgDashboardEditVolopRoute,
  orgDashboardGradFairsHomeRoute,
  orgDashboardInvoiceBillingAddressRoute,
  orgDashboardListCreditsRoute,
  orgDashboardListInvoicesRoute,
  orgDashboardListPaymentsRoute,
  orgDashboardManageAdminsRoute,
  orgDashboardManageIntegrationTypeRoute,
  orgDashboardManageIntegrationsRoute,
  orgDashboardManageListingsRoute,
  orgDashboardNotificationPreferencesRoute,
  orgDashboardPayByCreditCardRoute,
  orgDashboardPaymentSummaryRoute,
  orgDashboardPostAListingRoute,
  orgDashboardPreviewEventRoute,
  orgDashboardPreviewInternshipRoute,
  orgDashboardPreviewJobRoute,
  orgDashboardPreviewOrgRoute,
  orgDashboardPreviewVolopRoute,
  orgDashboardRepostEventRoute,
  orgDashboardRepostInternshipRoute,
  orgDashboardRepostJobRoute,
  orgDashboardRepostVolopRoute,
  orgDashboardRouteGroup,
  orgDashboardViewApplicationRoute,
  orgDashboardViewCreditPackRoute,
  orgDashboardViewEventRoute,
  orgDashboardViewGradFairInviteRoute,
  orgDashboardViewInternshipRoute,
  orgDashboardViewInvoiceRoute,
  orgDashboardViewJobRecommendedCandidateRoute,
  orgDashboardViewJobRoute,
  orgDashboardViewPaymentRoute,
  orgDashboardViewVolopRoute,
} from './orgDashboard.routes';

// The org dashboard is never server-side rendered
export const orgDashboardRouteObjects = [
  ...orgSelectRouteObjects,
  {
    path: orgDashboardRouteGroup.wrapperPath,
    ...horizonRouteToObject(orgDashboardOrgSubLayoutHorizonRouteExports),
    children: [
      {
        path: orgDashboardManageListingsRoute.relativePath,
        ...horizonRouteToObject(orgDashboardManageListingsHorizonRouteExports),
      },
      {
        path: orgDashboardEditOrgRoute.relativePath,
        ...horizonRouteToObject(orgDashboardEditOrgHorizonRouteExports),
      },
      {
        path: orgDashboardManageAdminsRoute.relativePath,
        ...horizonRouteToObject(orgDashboardManageAdminsHorizonRouteExports),
      },
      {
        path: orgDashboardEditJobRoute.relativePath,
        ...horizonRouteToObject(orgDashboardEditJobHorizonRouteExports),
      },
      {
        path: orgDashboardRepostJobRoute.relativePath,
        ...horizonRouteToObject(orgDashboardRepostJobHorizonRouteExports),
      },
      {
        path: orgDashboardEditInternshipRoute.relativePath,
        ...horizonRouteToObject(orgDashboardEditInternshipHorizonRouteExports),
      },
      {
        path: orgDashboardRepostInternshipRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardRepostInternshipHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardEditVolopRoute.relativePath,
        ...horizonRouteToObject(orgDashboardEditVolopHorizonRouteExports),
      },
      {
        path: orgDashboardRepostVolopRoute.relativePath,
        ...horizonRouteToObject(orgDashboardRepostVolopHorizonRouteExports),
      },
      {
        path: orgDashboardEditEventRoute.relativePath,
        ...horizonRouteToObject(orgDashboardEditEventHorizonRouteExports),
      },
      {
        path: orgDashboardRepostEventRoute.relativePath,
        ...horizonRouteToObject(orgDashboardRepostEventHorizonRouteExports),
      },
      {
        path: orgDashboardListInvoicesRoute.relativePath,
        ...horizonRouteToObject(orgDashboardInvoicesListHorizonRouteExports),
      },
      {
        path: orgDashboardViewInvoiceRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewInvoiceHorizonRouteExports),
      },
      {
        path: orgDashboardInvoiceBillingAddressRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardInvoiceBillingAddressHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardListPaymentsRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPaymentsListHorizonRouteExports),
      },
      {
        path: orgDashboardViewPaymentRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewPaymentHorizonRouteExports),
      },
      {
        path: orgDashboardPaymentSummaryRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPaymentSummaryHorizonRouteExports),
      },
      {
        path: orgDashboardCheckoutBillingAddressRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardCheckoutBillingAddressHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardPayByCreditCardRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPayByCreditCardHorizonRouteExports),
      },
      {
        path: orgDashboardCartRoute.relativePath,
        ...horizonRouteToObject(orgDashboardCartHorizonRouteExports),
      },
      {
        path: orgDashboardBuyCreditPacksRoute.relativePath,
        ...horizonRouteToObject(orgDashboardBuyCreditPacksHorizonRouteExports),
      },
      {
        path: orgDashboardListCreditsRoute.relativePath,
        ...horizonRouteToObject(orgDashboardListCreditsHorizonRouteExports),
      },
      {
        path: orgDashboardViewCreditPackRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewCreditPackHorizonRouteExports),
      },
      {
        path: orgDashboardApiAccessRoute.relativePath,
        ...horizonRouteToObject(orgDashboardApiAccessHorizonRouteExports),
      },
      {
        path: orgDashboardManageIntegrationsRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardManageIntegrationsHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardManageIntegrationTypeRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardManageIntegrationsHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardGradFairsHomeRoute.relativePath,
        ...horizonRouteToObject(orgDashboardGradFairsHomeHorizonRouteExports),
      },
      {
        path: orgDashboardViewGradFairInviteRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardViewGradFairInviteHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardNotificationPreferencesRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardNotificationPreferencesHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardPreviewOrgRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPreviewOrgHorizonRouteExports),
      },
      {
        path: orgDashboardViewJobRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewJobHorizonRouteExports),
      },
      {
        path: orgDashboardViewInternshipRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewInternshipHorizonRouteExports),
      },
      {
        path: orgDashboardViewVolopRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewVolopHorizonRouteExports),
      },
      {
        path: orgDashboardViewEventRoute.relativePath,
        ...horizonRouteToObject(orgDashboardViewEventHorizonRouteExports),
      },
      {
        path: orgDashboardViewApplicationRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardViewListingApplicationHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardViewJobRecommendedCandidateRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardViewJobRecommendedCandidateHorizonRouteExports,
        ),
      },
      // Preview listing routes
      {
        path: orgDashboardPreviewJobRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPreviewJobHorizonRouteExports),
      },
      {
        path: orgDashboardPreviewInternshipRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardPreviewInternshipHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardPreviewVolopRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPreviewVolopHorizonRouteExports),
      },
      {
        path: orgDashboardPreviewEventRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPreviewEventHorizonRouteExports),
      },
      {
        path: orgDashboardPostAListingRoute.relativePath,
        ...horizonRouteToObject(orgDashboardPostAListingHorizonRouteExports),
      },
      {
        path: orgDashboardCreateJobRoute.relativePath,
        ...horizonRouteToObject(orgDashboardCreateJobHorizonRouteExports),
      },
      {
        path: orgDashboardCreateInternshipRoute.relativePath,
        ...horizonRouteToObject(
          orgDashboardCreateInternshipHorizonRouteExports,
        ),
      },
      {
        path: orgDashboardCreateVolopRoute.relativePath,
        ...horizonRouteToObject(orgDashboardCreateVolopHorizonRouteExports),
      },
      {
        path: orgDashboardCreateEventRoute.relativePath,
        ...horizonRouteToObject(orgDashboardCreateEventHorizonRouteExports),
      },
      {
        path: orgDashboardCheckoutCartRoute.relativePath,
        ...horizonRouteToObject(orgDashboardCheckoutCartHorizonRouteExports),
      },
      {
        path: '*',
        ...horizonRouteToObject(orgDashboardNotFoundHorizonRouteExports),
      },
    ],
  },
] satisfies RouteObject[];
