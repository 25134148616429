import type { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

export function getIsNoAdPage(content: CmsApiPage) {
  if (content) {
    // no ads on home page when logged out
    if (['en', 'es', 'pt'].includes(content.url)) {
      return 'no-ads-if-logged-out';
    }
    // no ads on idealist days page
    if (content.url?.includes('en/days/home')) {
      return true;
    }

    if (content.url?.includes('organizations')) {
      return true;
    }

    if (content.url?.includes('sign-up-to-post')) {
      return true;
    }
  }
  return false;
}
