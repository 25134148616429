import { routeGroup } from 'routing/routes';

export const savedSearchesRoute = routeGroup.route(
  'saved-searches/:savedSearchId',
);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const searchJobsRouteReference = routeGroup.route('-lang/-segment', [
  'areasOfFocus',
  'functions',
  'locationType',
  'professionalLevel',
  'education',
  'jobType',
  'orgType',
  'locale',
  'radius',
  'recency',
  'synonymId',
  'q',
  'page',
  'sort',
]);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const searchInternshipsRouteReference = routeGroup.route(
  '-lang/-segment',
  [
    'areasOfFocus',
    'functions',
    'locationType',
    'orgType',
    'locale',
    'radius',
    'recency',
    'payment',
    'q',
    'synonymId',
    'page',
    'sort',
  ],
);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const searchVolopsRouteReference = routeGroup.route('-lang/-segment', [
  'areasOfFocus',
  'functions',
  'locationType',
  'locale',
  'radius',
  'recency',
  'source',
  'startsLT',
  'endsGT',
  'q',
  'page',
  'sort',
]);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const searchEventsRouteReference = routeGroup.route('-lang/-segment', [
  'areasOfFocus',
  'functions',
  'locationType',
  'locale',
  'radius',
  'recency',
  'source',
  'startsLT',
  'endsGT',
  'q',
  'page',
  'sort',
]);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const searchOrgsRouteReference = routeGroup.route('-lang/-segment', [
  'areasOfFocus',
  'orgType',
  'locale',
  'q',
  'page',
  'sort',
]);
