import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useUserEnvironment } from 'contexts/UserEnvironmentContext';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';

type Props = {
  loginPathname?: string;
  children: ReactNode;
};

export function RequireAuthentication({
  children,
  loginPathname = '/login',
}: Props) {
  const location = useLocation();
  const { user } = useUserEnvironment();
  const loggedIn = Boolean(user);

  if (loggedIn) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  if (isRenderingHorizonServer()) {
    throw new Error('Missing redirect for require authentication in horizon');
  }

  return (
    <Navigate
      to={loginPathname}
      state={{
        redirectTo: `${location.pathname}?${location.search}`,
        flashMessage: {
          type: 'LOGIN_REQUIRED',
        },
      }}
    />
  );
}
