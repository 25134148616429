import type { RouteObject } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import * as userDashboardAccountSettingsHorizonRouteExports from 'modules/userDashboard/pages/AccountSettings/UserDashboardAccountSettings.horizon-route';
import * as userDashboardAddPaymentMethodHorizonRouteExports from 'modules/userDashboard/pages/AddPaymentMethod/UserDashboardAddPaymentMethodPage.horizon-route';
import * as userDashboardApplicationHistoryHorizonRouteExports from 'modules/userDashboard/pages/ApplicationHistory/UserDashboardApplicationHistory.horizon-route';
import * as userDashboardApplicationSummaryHorizonRouteExports from 'modules/userDashboard/pages/ApplicationSummary/UserDashboardApplicationSummary.horizon-route';
import * as userDashboardCreateEventHorizonRouteExports from 'modules/userDashboard/pages/CreateEvent/UserDashboardCreateEvent.horizon-route';
import * as userDashboardEditEventHorizonRouteExports from 'modules/userDashboard/pages/EditEvent/UserDashboardEditEvent.horizon-route';
import * as userDashboardEmploymentProfileOnboardingHorizonRouteExports from 'modules/userDashboard/pages/EmploymentProfileOnboarding/UserDashboardEmploymentProfileOnboarding.horizon-route';
import * as userDashboardManageEventsHorizonRouteExports from 'modules/userDashboard/pages/ManageEvents/UserDashboardManageEvents.horizon-route';
import * as userDashboardNotFoundHorizonRouteExports from 'modules/userDashboard/pages/NotFound/UserDashboardNotFound.horizon-route';
import * as userDashboardNotificationPreferencesHorizonRouteExports from 'modules/userDashboard/pages/NotificationPreferences/UserDashboardNotificationPreferences.horizon-route';
import { UserDashboardPostAListingPageLoadable } from 'modules/userDashboard/pages/PostAListing/UserDashboardPostAListingPageLoadable';
import * as userDashboardProfessionalExperienceHorizonRouteExports from 'modules/userDashboard/pages/ProfessionalExperience/UserDashboardProfessionalExperience.horizon-route';
import * as userDashboardProfileHorizonRouteExports from 'modules/userDashboard/pages/Profile/UserDashboardProfile.horizon-route';
import * as userDashboardSavedItemsHorizonRouteExports from 'modules/userDashboard/pages/SavedItems/UserDashboardSavedItems.horizon-route';
import * as userDashboardSelectOrgHorizonRouteExports from 'modules/userDashboard/pages/SelectOrg/UserDashboardSelectOrg.horizon-route';
import * as userDashboardViewEventHorizonRouteExports from 'modules/userDashboard/pages/ViewEvent/UserDashboardViewEvent.horizon-route';
import * as userDashboardSubLayoutHorizonRouteExports from 'modules/userDashboard/sub-layout/UserDashboardSubLayout.horizon-route';
import { SIGN_UP_TO_POST_URL_FOR_LOCALE } from 'routing/route-constants';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

import {
  userDashboardAccountSettingsRoute,
  userDashboardApplicationHistoryRoute,
  userDashboardApplicationSummaryRoute,
  userDashboardCreateEventRoute,
  userDashboardEditEventRoute,
  userDashboardManageEventsRoute,
  userDashboardNotificationPreferencesRoute,
  userDashboardPaymentMethodsAddCardRoute,
  userDashboardProfessionalExperienceRoute,
  userDashboardProfileRoute,
  userDashboardRouteGroup,
  userDashboardSavedItemsRoute,
  userDashboardViewEmploymentOnboardingRoute,
  userDashboardViewEventRoute,
} from './userDashboard.routes';

export function getUserDashboardRouteObjects(): RouteObject[] {
  return [
    {
      path: userDashboardApplicationSummaryRoute.fullPath,
      ...horizonRouteToObject(
        userDashboardApplicationSummaryHorizonRouteExports,
      ),
    },
    {
      path: '/post-a-listing',
      handle: {
        variant: 'base',
      } satisfies LayoutHandleProps,
      element: (
        // RequireAuthentication with loginPathname crashes when used with horizonRouteToObject
        <RequireAuthentication
          loginPathname={SIGN_UP_TO_POST_URL_FOR_LOCALE[CURRENT_LOCALE()]}
        >
          <UserDashboardPostAListingPageLoadable />
        </RequireAuthentication>
      ),
    },
    {
      path: userDashboardCreateEventRoute.fullPath,
      ...horizonRouteToObject(userDashboardCreateEventHorizonRouteExports),
    },
    {
      path: userDashboardViewEmploymentOnboardingRoute.fullPath,
      ...horizonRouteToObject(
        userDashboardEmploymentProfileOnboardingHorizonRouteExports,
      ),
    },
    {
      path: userDashboardViewEventRoute.fullPath,
      ...horizonRouteToObject(userDashboardViewEventHorizonRouteExports),
    },
    {
      ...horizonRouteToObject(userDashboardSubLayoutHorizonRouteExports),
      children: [
        {
          path: '/select-org',
          ...horizonRouteToObject(userDashboardSelectOrgHorizonRouteExports),
        },
        {
          path: userDashboardEditEventRoute.fullPath,
          ...horizonRouteToObject(userDashboardEditEventHorizonRouteExports),
        },
        {
          path: userDashboardManageEventsRoute.fullPath,
          ...horizonRouteToObject(userDashboardManageEventsHorizonRouteExports),
        },
        {
          path: userDashboardNotificationPreferencesRoute.fullPath,
          ...horizonRouteToObject(
            userDashboardNotificationPreferencesHorizonRouteExports,
          ),
        },
        {
          path: userDashboardProfileRoute.fullPath,
          ...horizonRouteToObject(userDashboardProfileHorizonRouteExports),
        },
        {
          path: userDashboardProfessionalExperienceRoute.fullPath,
          ...horizonRouteToObject(
            userDashboardProfessionalExperienceHorizonRouteExports,
          ),
        },
        {
          path: userDashboardAccountSettingsRoute.fullPath,
          ...horizonRouteToObject(
            userDashboardAccountSettingsHorizonRouteExports,
          ),
        },
        {
          path: userDashboardPaymentMethodsAddCardRoute.fullPath,
          ...horizonRouteToObject(
            userDashboardAddPaymentMethodHorizonRouteExports,
          ),
        },
        {
          path: userDashboardSavedItemsRoute.fullPath,
          ...horizonRouteToObject(userDashboardSavedItemsHorizonRouteExports),
        },
        {
          path: userDashboardApplicationHistoryRoute.fullPath,
          ...horizonRouteToObject(
            userDashboardApplicationHistoryHorizonRouteExports,
          ),
        },
        {
          path: userDashboardRouteGroup.fullWrapperPath,
          ...horizonRouteToObject(userDashboardNotFoundHorizonRouteExports),
        },
      ],
    },
  ] satisfies RouteObject[];
}
