import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

import { IDEALIST_UTM_PARAMS } from 'utils/constants/general/idealistUtmParams';
import { hasUTMParam } from 'utils/url/hasUTMParam';
import { isExternalURL } from 'utils/url/isExternalURL';
import { pathWithParams } from 'utils/url/pathWithParams';

type Args<T extends HTMLElement> = {
  ref: RefObject<T | null> | undefined;
};

export function useLinksHaveUTMParams<T extends HTMLElement>({ ref }: Args<T>) {
  const [linksHaveUTMParams, setLinksHaveUTMParams] = useState(false);

  useEffect(() => {
    if (linksHaveUTMParams) return;

    if (ref && ref.current) {
      const linkEls = [...ref.current.getElementsByTagName('a')];
      linkEls.forEach((link) => {
        const href = link.getAttribute('href') || '';

        if (isExternalURL(href) && !hasUTMParam(href)) {
          link.setAttribute('href', pathWithParams(href, IDEALIST_UTM_PARAMS));
        }
      });
    }

    setLinksHaveUTMParams(true);
  }, [linksHaveUTMParams, ref, setLinksHaveUTMParams]);
}
