export const viewJobRoutePaths = [
  // en
  '/en/job',
  '/en/nonprofit-job',
  '/en/government-job',
  '/en/consultant-job',
  '/en/social-enterprise-job',
  '/en/recruiter-job',
  '/en/business-job',
  '/en/community-job',

  // es
  '/es/empleo',
  '/es/ong-empleo',
  '/es/gobierno-empleo',
  '/es/consultor-empleo',
  '/es/empresa-social-empleo',
  '/es/agencia-de-contratacion-empleo',
  '/es/empresa-empleo',
  '/es/colectivo-ciudadano-empleo',

  // pt
  '/pt/emprego',
  '/pt/ong-emprego',
  '/pt/governo-emprego',
  '/pt/consultoria-emprego',
  '/pt/empreendedorismo-social-emprego',
  '/pt/recrutador-emprego',
  '/pt/empresa-emprego',
  '/pt/coletivos-emprego',
];

export const viewInternshipRoutePaths = [
  // en
  '/en/internship',
  '/en/nonprofit-internship',
  '/en/government-internship',
  '/en/consultant-internship',
  '/en/social-enterprise-internship',
  '/en/recruiter-internship',
  '/en/business-internship',
  '/en/community-internship',

  // es
  '/es/pasantia',
  '/es/ong-pasantia',
  '/es/gobierno-pasantia',
  '/es/consultor-pasantia',
  '/es/empresa-social-pasantia',
  '/es/agencia-de-contratacion-pasantia',
  '/es/empresa-pasantia',
  '/es/colectivo-ciudadano-pasantia',

  // pt
  '/pt/estagio',
  '/pt/ong-estagio',
  '/pt/governo-estagio',
  '/pt/consultoria-estagio',
  '/pt/empreendedorismo-social-estagio',
  '/pt/recrutador-estagio',
  '/pt/empresa-estagio',
  '/pt/coletivos-estagio',
];

export const viewVolopRoutePaths = [
  '/en/volunteer-opportunity',
  '/es/oportunidad-voluntariado',
  '/pt/oportunidade-voluntariado',
];

export const viewEventRoutePaths = ['/en/event', '/es/evento', '/pt/evento'];

export const viewOrgRoutePaths = [
  // en
  '/en/nonprofit',
  '/en/government',
  '/en/consultant',
  '/en/social-enterprise',
  '/en/recruiter',
  '/en/business',
  '/en/community',

  // es
  '/es/ong',
  '/es/gobierno',
  '/es/consultor',
  '/es/empresa-social',
  '/es/agencia-de-contratacion',
  '/es/empresa',
  '/es/colectivo-ciudadano',

  // pt
  '/pt/ong',
  '/pt/governo',
  '/pt/consultoria',
  '/pt/empreendedorismo-social',
  '/pt/recrutador',
  '/pt/empresa',
  '/pt/coletivos',
];
