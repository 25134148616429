import type { RadiusType } from 'modules/search/helpers/radius';
import { searchOrgsRoutePathByLocale } from 'modules/search/routing/search.route-paths';
import { searchOrgsRouteReference } from 'modules/search/routing/search.routes';
import type { SearchOrgFiltersByName } from 'modules/search/types/FiltersByName/SearchOrgFiltersByName';
import type { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchGetQueryParamsFromFiltersByName } from './searchGetQueryParamsFromFiltersByName';

type Args = {
  lang: UserLocale;
  q?: string;
  radius?: RadiusType | string | null;
  filtersByName?: Omit<SearchOrgFiltersByName, 'type'>;
  sort?: SearchSortType;
  pageIndex?: number;
};

export function searchGetOrgsRoutePath({
  lang,
  filtersByName,
  q,
  radius,
  sort,
  pageIndex,
}: Args) {
  const queryParams = searchGetQueryParamsFromFiltersByName(filtersByName);

  if (q) queryParams.q = q;
  if (radius) queryParams.radius = radius;
  if (sort && sort !== 'relevance') queryParams.sort = sort;
  if (pageIndex) queryParams.page = pageIndex + 1;

  return searchOrgsRouteReference
    .with(queryParams)
    .replace('/-lang/-segment', searchOrgsRoutePathByLocale[lang]);
}
