import { redirect } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { getHorizonUserEnvironmentPromiseFromContext } from 'horizon/utils/getHorizonUserEnvironmentPromiseFromContext';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { IntegrateAtsLinksLoadable } from './IntegrateAtsLinksLoadable';

export async function loader({
  request,
  context,
}: HorizonLoaderFunctionArgs<void>) {
  if (isRenderingHorizonServer()) {
    const userEnvironment = await getHorizonUserEnvironmentPromiseFromContext({
      request,
      context,
    });

    if (!userEnvironment.user) return redirect('/login');
  }

  await IntegrateAtsLinksLoadable.load();
}

export default function OrgDashboardIntegrateAtsLinksHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return (
    <RequireAuthentication>
      <IntegrateAtsLinksLoadable />
    </RequireAuthentication>
  );
}
