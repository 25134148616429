import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiHorizonFetchJob } from 'modules/listing/api/job/listingApiFetchJob';
import { isListingExpired } from 'modules/listing/helpers/isListingExpired';
import { ExpiredJobPageLoadable } from 'modules/listing/pages/jobs/expired/ExpiredJobPageLoadable';
import { UnpublishedJobPageLoadable } from 'modules/listing/pages/jobs/unpublished/UnpublishedJobPageLoadable';
import { viewJobReferenceRoute } from 'modules/listing/routing/listing.routes';
import { SearchJobsRefinements } from 'modules/search/pages/Jobs/components/SearchJobsRefinements';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ViewJobPageLoadable } from './ViewJobPageLoadable';

export const handle = {
  defaultSearchType: 'JOB',
  searchRefinements: <SearchJobsRefinements />,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params: { jobIdDashSlug },
}: HorizonAppRouteLoaderArgs<typeof viewJobReferenceRoute>) {
  const [jobId] = jobIdDashSlug.split('-');

  try {
    const [job] = await Promise.all([
      listingApiHorizonFetchJob({ request, context })(jobId),
      // It's possible this loadable won't be used if the job is unpublished
      // but in most cases optimistically loading it is best
      ViewJobPageLoadable.load(),
    ]);

    switch (job.type) {
      case 'JOB':
        return {
          type: isListingExpired(job.expires) ? 'EXPIRED_JOB' : job.type,
          job,
          handle: {
            hideFooterDivider: 'hide-footer-divider-if-logged-out',
            searchFromPageType: 'LISTING',
            urlByLocale: job.url,
          } satisfies LayoutHandleProps,
        } as const;
      case 'UNPUBLISHED_JOB':
        await UnpublishedJobPageLoadable.load();
        return { type: 'UNPUBLISHED_JOB', unpublishedJob: job } as const;
    }
  } catch {
    return null;
  }
}

export default function ViewJobHorizonRoute({
  loaderData,
}: HorizonAppRouteComponentProps<typeof loader>) {
  switch (loaderData?.type) {
    case undefined:
      return <NotFoundPageLoadable noLayout />;
    case 'JOB':
      return <ViewJobPageLoadable job={loaderData.job} />;
    case 'EXPIRED_JOB':
      return <ExpiredJobPageLoadable job={loaderData.job} />;
    case 'UNPUBLISHED_JOB':
      return (
        <UnpublishedJobPageLoadable
          unpublishedJob={loaderData.unpublishedJob}
        />
      );
  }
}
