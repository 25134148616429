import { searchFacetNamesById } from 'modules/search/constants/searchFacetNamesById';
import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import type { SearchFacetId } from 'modules/search/types/SearchFacet';
import type { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

export function searchConvertFiltersByIdToFiltersByName(
  filtersById: SearchFiltersById,
): SearchFiltersByName {
  const filtersByName: SearchFiltersByName = { type: filtersById.type };

  (Object.keys(filtersById) as SearchFacetId[]).forEach((facetId) => {
    const value = filtersById[facetId];

    if (value) {
      if (facetId === 'actionDateFacet') {
        if (typeof value === 'object') {
          // @ts-expect-error TODO: fix types
          if ('endsGT' in value) filtersByName.endsGT = value.endsGT;

          if ('startsLT' in value)
            // @ts-expect-error TODO: fix types
            filtersByName.startsLT = value.startsLT;
        }
      } else {
        const facetName = searchFacetNamesById[facetId];
        if (facetName) {
          // @ts-expect-error TODO: fix types
          filtersByName[facetName] = value;
        }
      }
    }
  });

  return filtersByName;
}
