import { useCallback } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import type { ViewportSize } from 'utils/viewport/getViewportSize';
import { getViewportSize } from 'utils/viewport/getViewportSize';

export function useViewportSizeEffect(callback: (size: ViewportSize) => void) {
  const handleResize = useCallback(() => {
    callback(getViewportSize());
  }, [callback]);

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
}
