import type { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { PROFESSIONAL_LEVELS } from 'utils/constants/general/professionalLevels';

export const professionalLevelFacet = {
  id: 'professionalLevelFacet',
  name: 'professionalLevel',
  get title() {
    return getText('Experience Level');
  },
  type: 'array',
  options: Object.keys(PROFESSIONAL_LEVELS),
  optionTitles: PROFESSIONAL_LEVELS,
  sort: false,
} as const satisfies SearchFacetData;
