import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardEditJobRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardEditJobPageContainerLoadable } from './OrgDashboardEditJobPageContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardEditJobRoute>,
) {
  await OrgDashboardEditJobPageContainerLoadable.load();
}

export default function OrgDashboardEditJobHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardEditJobPageContainerLoadable />;
}
