import type { FindAJobDropdownData } from 'components/FindAJobDropdown/types/FindAJobDropdownData';
import { searchGetInternshipsRoutePath } from 'modules/search/routing/helpers/searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from 'modules/search/routing/helpers/searchGetOrgsRoutePath';

export const findAJobDropdownDataEs: FindAJobDropdownData = {
  search: {
    title: 'Encuentra',
    items: [
      {
        type: 'job',
        title: 'Empleos',
        href: searchGetJobsRoutePath({ lang: 'es' }),
      },
      {
        type: 'internship',
        title: 'Pasantías',
        href: searchGetInternshipsRoutePath({ lang: 'es' }),
      },
      {
        type: 'org',
        title: 'Organizaciones',
        href: searchGetOrgsRoutePath({ lang: 'es' }),
      },
    ],
  },
  resources: {
    title: 'Recursos',
    items: [
      {
        type: 'career-advice',
        title: 'Desarrollo de carrera',
        description:
          'Herramientas y consejos útiles para construir una carrera profesional con impacto.',
        href: '/es/accion?categories=empleocambiamundo&pq=',
      },
    ],
  },
};
