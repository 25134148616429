import { styled } from 'styled-components';

import { colors, cssBreakpoints, spacing } from 'theme/theme';

const SEARCH_FACET_CHIP_WIDTH = '150px';

export const SearchRefinementDropdownHolder = styled.div`
  cursor: pointer;
  order: 1;

  && menu {
    border-radius: 8px;
    padding: ${spacing[24]};
  }

  &:last-child menu {
    padding: 0 ${spacing[24]};

    details:last-child button {
      margin-bottom: ${spacing[24]};
    }
  }

  /* 
  * Remove these styles when 
  * we replace the legacy job family facet
  */
  &[data-facet-type='synonymId'] span[data-variant='default'] {
    background-color: ${colors.selectionBlue};
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[open],
  &:hover > details > summary > span {
    background-color: ${colors.selectionGrey};
  }

  &[data-facet-state='PINNED'] summary > span,
  &[data-facet-state='QUEUED']:has(input:checked:not([value='']))
    summary
    > span,
  &[data-facet-state='QUEUED']:has(
      input[type='startsLT']:not(
          [value=''],
          input[name='endsGT']:not([value=''])
        )
    )
    summary
    > span {
    background-color: ${colors.selectionBlue};
  }

  &[data-facet-state='PINNED'],
  &[data-facet-state='QUEUED']:not(:has(input:checked:not([value='']))),
  &[data-facet-state='QUEUED']:not(
      :has(
        input[name='startsLT']:not([value='']),
        input[name='endsGT']:not([value=''])
      )
    ) {
    order: 0;
  }

  &:last-child:is(:not(:first-child)):has(details input:checked:not([value='']))
    summary
    > span {
    background-color: ${colors.selectionBlue};
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FACET_CHIP_WIDTH} * 6)) {
    /* Handle hiding the overflow menu if all facets are visible */
    &:last-child:not(:has([data-hidden='false'])) {
      display: none;
    }
  }

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    && menu {
      width: 100%;
      margin-top: 0;
    }
  }
`;

// @NOTE: Will be removed when Job Family facet is modified
export const JobFamilyFacetCloseButton = styled.div`
  color: ${colors.selectionGrey};
  height: 16px;
  width: 16px;
`;
