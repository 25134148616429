import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetRadioButtonOptions } from 'modules/search/components/Facet/Options/SearchFacetRadioButtonOptions';
import { useSearchFacetSelectedValue } from 'modules/search/hooks/facets/useSearchFacetSelectedValue';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { RECENCY_PERIOD_RADIO_OR_SELECT_OPTIONS } from 'utils/constants/general/recency';

type Props = {
  variant?: SearchRefinementVariant;
};
export function RecencyFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValue = useSearchFacetSelectedValue('recency');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant facetType="recency" icon="clock" label={getText('Recency')}>
      <SearchFacetRadioButtonOptions
        facetType="recency"
        initialValue={selectedFacetValue}
        options={RECENCY_PERIOD_RADIO_OR_SELECT_OPTIONS()}
      />
    </FacetVariant>
  );
}
