import type { ApiEventId } from 'api/types/ApiTypedId';
import type { ApiEvent } from 'modules/listing/api/event/types/ApiEvent';
import type { ApiUnpublishedEvent } from 'modules/listing/api/event/types/ApiUnpublishedEvent';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  data: ApiEvent | ApiUnpublishedEvent;
};

function mapResponse(response: ApiResponse): ApiEvent | ApiUnpublishedEvent {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (eventId: ApiEventId) => `/data/website/events/${eventId}`,
  mapResponse,
});

// Exports

export const listingApiHorizonFetchEvent = endpoint.createHorizonFetchFn();
