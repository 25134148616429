import type { ActionDateFacetValue } from 'modules/search/constants/searchFacets/actionDateFacet';
import type { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import type { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchAlgoliaIndex } from './searchAlgoliaIndex';
import { searchAlgoliaVolopsIndex } from './searchAlgoliaVolopsIndex';

export const searchAlgoliaIndexSwitch = ({
  filtersById,
  sort,
}: {
  filtersById: SearchFiltersById;
  sort: SearchSortType;
}) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (filtersById.type) {
    case 'VOLOP':
      return searchAlgoliaVolopsIndex({
        sort,
        actionDate: filtersById.actionDateFacet as
          | ActionDateFacetValue
          | null
          | undefined,
      });
    case 'EVENT':
      return searchAlgoliaVolopsIndex({
        sort,
        actionDate: filtersById.actionDateFacet as
          | ActionDateFacetValue
          | null
          | undefined,
      });
    default:
      return searchAlgoliaIndex(sort);
  }
};
