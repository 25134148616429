import { format, isValid, parseISO } from 'date-fns';
import { enUS, es, pt } from 'date-fns/locale';
import debounce from 'lodash/debounce';
import { registerLocale } from 'react-datepicker';

import { useLoadBundleCssOnce } from 'zustand-stores/loadCssBundleStore';

import { DatePickerDateInput } from './DatePickerDateInput';
import { DatePickerLoadable } from './DatePickerLoadable';
import { DatePickerPopperContainer } from './DatePickerPopperContainer';
import { createLocalDateString } from './helpers/createLocalDateString';

//:horizon://import 'react-datepicker/dist/react-datepicker.css';

registerLocale('en', enUS);
registerLocale('es', es);
registerLocale('pt', pt);

type Props = {
  className?: string;
  id?: string;
  name?: string;
  required?: boolean;
  value: string | null | undefined;
  placeholder?: string;
  onChange: (date: string | null) => void;
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  strictParsing?: boolean;
  dateFormat?: string;
  isTimeZoneAware?: boolean;
  showMonthYearPicker?: boolean;
  'data-qa-id'?: string;
};

export function DatePicker({
  className,
  value,
  onChange,
  disablePastDates,
  disableFutureDates,
  strictParsing = true,
  dateFormat,
  isTimeZoneAware,
  showMonthYearPicker = false,
  ...rest
}: Props) {
  const datetimeProps = {
    ...rest,
    qaId: rest['data-qa-id'],
    autoComplete: 'off',
  };

  useLoadBundleCssOnce('reactDatepicker');

  const onChangeDebounced = debounce(onChange, 200);
  const parsedValue = value ? parseISO(value) : null;
  return (
    <DatePickerLoadable
      className={className}
      customInput={<DatePickerDateInput inputProps={datetimeProps} />}
      shouldCloseOnSelect
      locale={CURRENT_LOCALE()}
      name={datetimeProps.name}
      dateFormat={dateFormat || 'P'}
      showMonthYearPicker={showMonthYearPicker}
      minDate={disablePastDates ? new Date() : undefined}
      maxDate={disableFutureDates ? new Date() : undefined}
      popperContainer={DatePickerPopperContainer}
      strictParsing={strictParsing}
      selected={parsedValue}
      onChange={(selectedDate) => {
        if (selectedDate && isValid(selectedDate) && isTimeZoneAware) {
          onChangeDebounced(createLocalDateString(selectedDate));
        } else if (selectedDate && isValid(selectedDate)) {
          const dateString = format(selectedDate, 'yyyy-MM-dd');
          onChangeDebounced(dateString);
        } else {
          onChangeDebounced(null);
        }
      }}
    />
  );
}
