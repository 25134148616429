import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardViewGradFairInviteRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardViewGradFairInviteContainerLoadable } from './OrgDashboardViewGradFairInviteContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardViewGradFairInviteRoute>,
) {
  await OrgDashboardViewGradFairInviteContainerLoadable.load();
}

export default function OrgDashboardViewGradFairInviteHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardViewGradFairInviteContainerLoadable />;
}
