import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { salarySurveyRoute } from 'modules/salaries/routing/salaries.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { CompensationSurveyLoadable } from './CompensationSurveyLoadable';

export const handle = {
  variant: 'base',
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof salarySurveyRoute>,
) {
  await CompensationSurveyLoadable.load();
}

export default function CompensationSurveyHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <CompensationSurveyLoadable />;
}
