import { Button } from 'components/Button/Button';
import { H5 } from 'components/Text/H5';

import { SearchRefinementsHeaderHolder } from './SearchRefinementsHeader.styled';

type Props = {
  hasActiveRefinements?: boolean;
  onClickClearAll?: () => void;
};

export function SearchRefinementsHeader({
  hasActiveRefinements,
  onClickClearAll,
}: Props) {
  return (
    <SearchRefinementsHeaderHolder>
      {hasActiveRefinements ? (
        <Button
          data-qa-id="clear-active-refinements"
          noPadding
          variant="link"
          type="button"
          onClick={onClickClearAll}
        >
          {getText('Clear Filters')}
        </Button>
      ) : (
        <H5 mb={0}>{getText('Filters')}</H5>
      )}
    </SearchRefinementsHeaderHolder>
  );
}
