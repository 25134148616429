import type { ReactNode } from 'react';
import type { LoaderFunction, LoaderFunctionArgs, Params } from 'react-router';

import { RenderLoaderComponent } from './RenderLoaderComponent';
import { renderClientOnlyLoaderToken } from './renderClientOnlyLoaderToken';

type Args<TData, TLoaderArgs extends LoaderFunctionArgs> = {
  /**
   * This is a workaround for routes that we cannot set all SSR data for.
   *
   * This happens for example on the pages inside the org dashboard,
   * for which only have a top-level route on the backend.
   */
  clientOnly?: boolean;

  loader: (args: TLoaderArgs, handlerCtx: unknown) => Promise<TData> | TData;
  render: (data: TData, params: Params) => ReactNode;
};

/**
 * Create a `{ loader, element }` object that ties both together.
 *
 * This ensures that the types returned by the loader are the same being
 * consumed by the page component and provides an easy path to switch(...)
 * when a route can have more than one page (such as published/unpublished/not found)
 */
export function renderLoader<TData, TLoaderArgs extends LoaderFunctionArgs>({
  loader,
  render,
  clientOnly,
}: Args<TData, TLoaderArgs>): {
  loader: LoaderFunction;
  element: ReactNode;
} {
  return {
    loader: (clientOnly
      ? (...args) => {
          if (typeof window === 'undefined')
            return renderClientOnlyLoaderToken as TData;

          // @ts-expect-error compatibility with Horizon - `renderLoader` will be removed once the Horizon migration is completed
          return loader(...args);
        }
      : loader) as LoaderFunction,
    element: <RenderLoaderComponent render={render} />,
  };
}
