import { create } from 'zustand';

import type { ModalPropsForType } from 'containers/Modal/ModalPropsForType';
import type { ModalType } from 'containers/Modal/ModalType';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';

type ModalData<TType extends ModalType> = {
  type: TType;
  props: ModalPropsForType<TType>;
};

const modalStore = create<{
  modal: ModalData<ModalType> | null;
}>(() => ({ modal: null }));

const useModalStore = modalStore; // The store can be used as a hook

// Actions

export function showModal<TType extends ModalType>(
  type: TType,
  props: ModalPropsForType<TType>,
) {
  deferAndStartRouteLoadingAnimation(() => {
    modalStore.setState({ modal: { type, props } });
  });
}

export function hideModal() {
  modalStore.setState({ modal: null });
}

// Hook

export function useModal() {
  const modal = useModalStore((state) => state.modal);
  return { modal };
}
