import { styled } from 'styled-components';

import { colors } from 'theme/theme';

export const PopularSearchesContainer = styled.div`
  background: ${colors.inputGrey};
  border-top: 1px solid ${colors.selectionGrey};
  height: 100%;
  padding: 36px 24px 0;
`;

export const PopularSearchesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
