import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchInternship } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchInternship';
import { orgDashboardPreviewInternshipRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardPreviewInternshipPageLoadable } from './OrgDashboardPreviewInternshipPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: false,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader({
  request,
  context,
  params: { orgId, internshipId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardPreviewInternshipRoute>) {
  const [internship] = await Promise.all([
    orgDashboardApiHorizonFetchInternship({ request, context })(
      orgId,
      internshipId,
    ),
    OrgDashboardPreviewInternshipPageLoadable.load(),
  ]);

  return { internship };
}

export default function OrgDashboardPreviewInternshipHorizonRoute({
  loaderData: { internship },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return (
    <OrgDashboardPreviewInternshipPageLoadable
      key={internship.id}
      internship={internship}
    />
  );
}
