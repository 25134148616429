import type {
  ApiEnvironment,
  ApiUser,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { configureAnalyticsUserData } from 'utils/analytics/setup/configureAnalyticsUserData';
import { configureTrackJs } from 'utils/configureTrackJs';
import { trackReferrals } from 'utils/trackReferral';

type Args = {
  user: ApiUser | null;
  environment: ApiEnvironment;
};

export function configureTracking({ user, environment }: Args) {
  // Tracking
  configureTrackJs(user ? user.id : null);
  configureAnalyticsUserData({ user, environment });
  trackReferrals();
}
