import type { ApiABExperiment } from 'api/abExperiment/types/ApiABExperiment';
import { getAnalyticsAbExperimentsConcatenatedString } from 'utils/analytics/abExperiments/getAnalyticsAbExperimentsConcatenatedString';

function isMixpanelEnabled(): boolean {
  return typeof mixpanel !== 'undefined';
}

export function resetMixpanelUser() {
  if (isMixpanelEnabled()) {
    mixpanel.reset();
  }
}

export function registerMixpanelProperties(
  properties: Record<string, unknown>,
  days?: number,
) {
  if (isMixpanelEnabled()) {
    mixpanel.register(properties, days);
  }
}

export function registerMixpanelPropertiesOnce(
  properties: Record<string, unknown>,
) {
  if (isMixpanelEnabled()) {
    mixpanel.register_once(properties);
  }
}

export function unregisterMixpanelProperty(property: string) {
  if (isMixpanelEnabled()) {
    mixpanel.unregister(property);
  }
}

export function updateMixpanelExperiments(experiments: ApiABExperiment[]) {
  if (experiments.length > 0) {
    registerMixpanelProperties({
      experiments: getAnalyticsAbExperimentsConcatenatedString(experiments),
    });
  } else {
    unregisterMixpanelProperty('experiments');
  }
}

export function trackMixpanelEvent(
  eventName: string,
  properties: Record<string, unknown>,
) {
  if (isMixpanelEnabled()) {
    mixpanel.track(eventName, properties);
  }
}

// only use for external links, not with react-router
export function trackMixpanelLinks(
  el: Element,
  eventName: string,
  properties: Record<string, unknown>,
) {
  if (isMixpanelEnabled()) {
    mixpanel.track_links(el, eventName, properties);
  }
}
