import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardCheckoutCartRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardCheckoutCartPageLoadable } from './OrgDashboardCheckoutCartPageLoadable';

export const handle = {
  variant: 'base',
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardCheckoutCartRoute>,
) {
  await OrgDashboardCheckoutCartPageLoadable.load();
}

export default function OrgDashboardCheckoutCartHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardCheckoutCartPageLoadable />;
}
