import type { SearchFacet } from 'modules/search/types/SearchFacet';
import type { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import type { SearchLocation } from 'modules/search/types/SearchLocation';

import { searchAlgoliaOptionsFilterForFacet } from './searchAlgoliaOptionsFilterForFacet';

type Args = {
  filtersById: SearchFiltersById;
  searchFacets: SearchFacet[];
  searchLocation?: SearchLocation;
};

export function searchAlgoliaOptionsFiltersForFacets({
  filtersById,
  searchFacets,
  searchLocation,
}: Args): string[] {
  const queryStringValues: string[] = [];

  searchFacets.forEach((searchFacet) => {
    const rawValue = filtersById[searchFacet.id];
    if (!rawValue) return;

    const value = searchAlgoliaOptionsFilterForFacet({
      searchFacet,
      rawValue,
      searchLocation,
    });
    if (!value) return;

    queryStringValues.push(value);
  });

  return queryStringValues;
}
