import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardEditInternshipRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardEditInternshipPageContainerLoadable } from './OrgDashboardEditInternshipPageContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardEditInternshipRoute>,
) {
  await OrgDashboardEditInternshipPageContainerLoadable.load();
}

export default function OrgDashboardEditInternshipHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardEditInternshipPageContainerLoadable />;
}
