import { userDashboardProfessionalExperienceRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserDashboardProfessionalExperiencePageLoadable } from './UserDashboardProfessionalExperiencePageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardProfessionalExperienceRoute>,
) {
  await UserDashboardProfessionalExperiencePageLoadable.load();
}

export default function UserDashboardProfessionalExperienceHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UserDashboardProfessionalExperiencePageLoadable />;
}
