import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardRepostVolopRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardRepostVolopPageContainerLoadable } from './OrgDashboardRepostVolopPageContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardRepostVolopRoute>,
) {
  await OrgDashboardRepostVolopPageContainerLoadable.load();
}

export default function OrgDashboardRepostVolopHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardRepostVolopPageContainerLoadable />;
}
