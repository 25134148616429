import type { RouteObject } from 'react-router';

import * as compensationSurveyHorizonRouteExports from 'modules/salaries/pages/CompensationSurvey/CompensationSurvey.horizon-route';
import * as salaryReportHorizonRouteExports from 'modules/salaries/pages/SalaryReport/SalaryReport.horizon-route';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

import { exploreSalaryRoute, salarySurveyRoute } from './salaries.routes';

export const salariesRouteObjects = [
  {
    path: exploreSalaryRoute.fullPath,
    ...horizonRouteToObject(salaryReportHorizonRouteExports),
  },
  {
    path: salarySurveyRoute.fullPath,
    ...horizonRouteToObject(compensationSurveyHorizonRouteExports),
  },
] satisfies RouteObject[];
