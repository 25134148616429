import { useRouteLoaderData } from 'react-router';

import type { SearchJobsRouteLoaderData } from 'modules/search/routing/helpers/SearchJobsRouteLoaderData';

export function useSearchJobsRouteLoaderData() {
  const loaderData = useRouteLoaderData(
    `search-jobs-${CURRENT_LOCALE()}`,
  ) as SearchJobsRouteLoaderData;

  const { synonym, facetCounts } = loaderData || {};

  const {
    familyId,
    jobSynonym: { name },
  } = synonym || {
    familyId: null,
    jobSynonym: { name: null },
  };

  return { jobSynonymName: name, jobFamilyId: familyId, facetCounts };
}
