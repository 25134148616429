import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserDashboardSelectOrgPageLoadable } from './UserDashboardSelectOrgPageLoadable';

export async function loader() {
  await UserDashboardSelectOrgPageLoadable.load();
}

export default function UserDashboardSelectOrgHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UserDashboardSelectOrgPageLoadable />;
}
