import { useEffect } from 'react';

import {
  isClientHydrated,
  setClientHydrated,
} from 'rendering/state/renderingState';

export function useSetClientHydrated() {
  useEffect(() => {
    if (!isClientHydrated()) setClientHydrated();
  }, []);
}
