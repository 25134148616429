import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { useSearchAnalytics } from 'modules/search/hooks/useSearchAnalytics';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';

export function useSearchDateRange(refinementType: SearchRefinementType) {
  const searchFiltersByName = useSearchFiltersByName();

  const { getSearchTrackingData } = useSearchAnalytics();
  const [searchParams] = useSearchParams();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);

  const navigate = useDeferredNavigate();

  const onChangeStart = useCallback(
    (dateString?: string | null) => {
      const value = dateString === null ? undefined : dateString;

      const filterSelected = searchParamsRef.current.has(refinementType, value);

      const date = value ? parseISO(value) : undefined;
      const formatted = date ? format(date, 'yyyy-MM-dd') : undefined;

      const newRoute = searchGetRoutePathSwitch({
        lang: CURRENT_LOCALE(),
        q: searchParamsRef.current.get('q') || '',
        filtersByName: {
          ...searchFiltersByName,
          // @ts-expect-error Fix issue with type union
          endsGT: formatted,
        },
      });

      trackEvent('Changed Search Filter', {
        filter_name: `${refinementType}Facet`,
        [filterSelected ? 'selected_value' : 'deselected_value']: value,
        ...getSearchTrackingData(),
      });

      navigate(newRoute, {
        replace: true,
      });
    },
    [
      getSearchTrackingData,
      navigate,
      refinementType,
      searchFiltersByName,
      searchParamsRef,
    ],
  );

  const onChangeEnd = useCallback(
    (dateString: string | null) => {
      const value = dateString === null ? undefined : dateString;
      const filterSelected = searchParamsRef.current.has(refinementType, value);

      const date = value ? parseISO(value) : undefined;
      const formatted = date ? format(date, 'yyyy-MM-dd') : undefined;

      const newRoute = searchGetRoutePathSwitch({
        lang: CURRENT_LOCALE(),
        q: searchParamsRef.current.get('q') || '',
        filtersByName: {
          ...searchFiltersByName,
          // @ts-expect-error Fix issue with type union
          startsLT: formatted,
        },
      });

      trackEvent('Changed Search Filter', {
        filter_name: `${refinementType}Facet`,
        [filterSelected ? 'selected_value' : 'deselected_value']: value,
        ...getSearchTrackingData(),
      });

      navigate(newRoute, {
        replace: true,
      });
    },
    [
      getSearchTrackingData,
      navigate,
      refinementType,
      searchFiltersByName,
      searchParamsRef,
    ],
  );

  return {
    onChangeStart,
    onChangeEnd,
    startsLT: searchParamsRef.current.get('startsLT'),
    endsGT: searchParamsRef.current.get('endsGT'),
  };
}
