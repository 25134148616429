import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardCheckoutBillingAddressRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardCheckoutBillingAddressContainerLoadable } from './OrgDashboardCheckoutBillingAddressContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardCheckoutBillingAddressRoute>,
) {
  await OrgDashboardCheckoutBillingAddressContainerLoadable.load();
}

export default function OrgDashboardCheckoutBillingAddressHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardCheckoutBillingAddressContainerLoadable />;
}
