import loadable from '@loadable/component';

export const DatePickerLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: "DateTime" */
      'react-datepicker'
    ),
  {
    fallback: <input readOnly />,
    ssr: false,
  },
);
