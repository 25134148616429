import { authMfaRoute } from 'modules/auth/routing/auth.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { MfaPageLoadable } from './MfaPageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof authMfaRoute>,
) {
  await MfaPageLoadable.load();
}

export default function AuthMfaHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <MfaPageLoadable />;
}
