import { userDashboardAccountSettingsRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserDashboardAccountSettingsPageLoadable } from './UserDashboardAccountSettingsPageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardAccountSettingsRoute>,
) {
  await UserDashboardAccountSettingsPageLoadable.load();
}

export default function UserDashboardAccountSettingsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UserDashboardAccountSettingsPageLoadable />;
}
