import type { RouteObject } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { Layout } from 'layouts/Layout/Layout';
import * as userInviteHorizonRouteExports from 'modules/auth/pages/userInvite/UserInvite.horizon-route';
import { authRouteObjects } from 'modules/auth/routing/auth.route-objects';
import { authUserInviteRoute } from 'modules/auth/routing/auth.routes';
import * as cmsHorizonRoute from 'modules/cms/routing/Cms.horizon-route';
import * as importedListingHorizonRouteExports from 'modules/listing/pages/imported/view/ImportedListing.horizon-route';
import { listingRouteObjects } from 'modules/listing/routing/listing.route-objects';
import { importedListingRoute } from 'modules/listing/routing/listing.routes';
import * as viewDonationReceiptHorizonRouteExports from 'modules/orgDashboard/pages/invoices/publicView/ViewDonationReceipt.horizon-route';
import * as viewInvoiceHorizonRouteExports from 'modules/orgDashboard/pages/invoices/publicView/ViewInvoice.horizon-route';
import * as orgDashboardRateRecommendedCandidateHorizonRouteExports from 'modules/orgDashboard/pages/recommendedCandidates/rate/OrgDashboardRateRecommendedCandidate.horizon-route';
import { orgDashboardRouteObjects } from 'modules/orgDashboard/routing/orgDashboard.route-objects';
import { salariesRouteObjects } from 'modules/salaries/routing/salaries.route-objects';
import { searchRouteObjects } from 'modules/search/routing/search.route-objects';
import { CreateOrgPageContainerLoadable } from 'modules/userDashboard/pages/CreateOrg/CreateOrgPageContainerLoadable';
import { getUserDashboardRouteObjects } from 'modules/userDashboard/routing/userDashboard.route-objects';
import { userProfileRouteObjects } from 'modules/userProfile/routing/userProfile.route-objects';
import * as invalidLinkHorizonRouteExports from 'pages/InvalidLink/InvalidLink.horizon-route';
import * as unsubscribeHorizonRouteExports from 'pages/Unsubscribe/Unsubscribe.horizon-route';

import { SIGN_UP_TO_POST_URL_FOR_LOCALE } from './route-constants';
import {
  emailRateRecommendedCandidateRoute,
  viewDonationReceiptRoute,
  viewInvoiceRoute,
} from './routes';
import { horizonRouteToObject } from './utils/horizonRouteToObject';

export function getRouteObjects(): RouteObject[] {
  return [
    {
      Component: Layout,
      children: [
        ...[
          '/unsubscribe/:preferenceName/:userId/:timestamp',
          '/unsubscribe/org/:orgId/:preferenceName/:userId/:timestamp',
        ].map((path) => ({
          path,
          ...horizonRouteToObject(unsubscribeHorizonRouteExports),
        })),
        {
          path: '/invalid-or-expired-link',
          ...horizonRouteToObject(invalidLinkHorizonRouteExports),
        },
        {
          path: viewInvoiceRoute.fullPath,
          ...horizonRouteToObject(viewInvoiceHorizonRouteExports),
        },
        {
          path: viewDonationReceiptRoute.fullPath,
          ...horizonRouteToObject(viewDonationReceiptHorizonRouteExports),
        },

        {
          path: '/*',
          ...horizonRouteToObject(cmsHorizonRoute),
        },

        ...authRouteObjects,
        ...listingRouteObjects,
        ...searchRouteObjects,
        ...salariesRouteObjects,
        ...getUserDashboardRouteObjects(),
        ...orgDashboardRouteObjects,
        ...userProfileRouteObjects,
      ],
    },

    /*
     * Routes below this line need to be converted to the new routing paradigm
     *
     * To accomplish that:
     * 1. Update the loadable to have a fallback `<LoadingSection />` instead of
     *    `<LoadableLoadingPage />`, because the layout will already have the header
     * 2. Remove the layout from the page, the layout is now placed on the router.
     * 3. Move it to the corresponding `element: <_TheLayout/>` wrapper. If the route
     *    layout requires data from a user-only module, use a loadable layout to
     *    avoid bloating the main bundle
     * 4. Add `handle` properties as necessary to modify the layout
     * ---------------------------------------------------------------------------
     */

    {
      path: importedListingRoute.fullPath,
      ...horizonRouteToObject(importedListingHorizonRouteExports),
    },

    {
      path: authUserInviteRoute.fullPath,
      ...horizonRouteToObject(userInviteHorizonRouteExports),
    },
    {
      path: '/organizations/create',
      element: (
        // RequireAuthentication with loginPathname crashes when used with horizonRouteToObject
        <RequireAuthentication
          loginPathname={SIGN_UP_TO_POST_URL_FOR_LOCALE[CURRENT_LOCALE()]}
        >
          <CreateOrgPageContainerLoadable />
        </RequireAuthentication>
      ),
    },
    {
      path: emailRateRecommendedCandidateRoute.fullPath,
      ...horizonRouteToObject(
        orgDashboardRateRecommendedCandidateHorizonRouteExports,
      ),
    },
    ...['/cms/*', '/staff/*'].map(
      (staffPath) =>
        ({
          path: staffPath,
          Component: () => {
            // Reload the page, going from main to the staff app
            window.location.reload();
            return null;
          },
        }) satisfies RouteObject,
    ),
  ] satisfies RouteObject[];
}
