import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardPayByCreditCardRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardPayByCreditCardContainerLoadable } from './OrgDashboardPayByCreditCardContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardPayByCreditCardRoute>,
) {
  await OrgDashboardPayByCreditCardContainerLoadable.load();
}

export default function OrgDashboardPayByCreditCardHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardPayByCreditCardContainerLoadable />;
}
