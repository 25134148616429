import { useCallback } from 'react';
import type { StoreApi, UseBoundStore } from 'zustand/index';

import { apiSetABExperimentBucket } from 'api/abExperiment/apiSetABExperimentBucket';
import type {
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from 'api/abExperiment/types/ApiABExperiment';
import {
  apiFetchUserEnvironment,
  apiGetFetchUserEnvironmentSsrApiData,
} from 'api/userEnvironment/apiFetchUserEnvironment';
import type {
  ApiEnvironment,
  ApiUser,
  ApiUserEnvironment,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { searchGetInitialLocation } from 'modules/search/helpers/searchGetInitialLocation';
import { updateSearchLocation } from 'modules/search/zustand-stores/searchStore';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';
import { assignAnalyticsAbExperiments } from 'utils/analytics/abExperiments/analyticsAbExperiments';
import { createZustandContextStore } from 'zustand-stores/utils/createZustandContextStore';

type UserEnvironmentContextState = {
  user: ApiUser | null;
  environment: ApiEnvironment | null;
};

/**
 * @deprecated see the legacy methods at the end of this file.
 *
 * Once those methods are removed, we can remove this variable as well
 */
let legacyLatestStore: UseBoundStore<StoreApi<UserEnvironmentContextState>>;

const {
  ZustandContextProvider,
  useZustandContextStore,
  useZustandContextSelector,
} = createZustandContextStore<UserEnvironmentContextState>({
  fallbackState: { user: null, environment: null },
  onCreate: (store) => {
    legacyLatestStore = store;
    store.subscribe((newState) => {
      if (newState.environment) {
        assignAnalyticsAbExperiments(newState.environment.abExperiments);
      }
    });
  },
});

export const UserEnvironmentContextProvider = ZustandContextProvider;

// Hooks

export function useUser(): { user: ApiUser | null } {
  const user = useZustandContextSelector((state) => state.user);
  return { user };
}

export function useEnvironment() {
  const environment = useZustandContextSelector((state) => state.environment);
  return { environment };
}

export function useUserEnvironmentActions() {
  const store = useZustandContextStore();

  const fetchUserEnvironment = useCallback(async () => {
    const { user, environment } = await apiFetchUserEnvironment();
    store.setState({ user, environment });

    // Read searchLocation from localstorage
    // @TODO replace fallback to searchLocation with guessedLocation
    updateSearchLocation(
      searchGetInitialLocation(environment.guessedLocation),
      false,
    );

    return { user, environment };
  }, [store]);

  const updateUserPartially = useCallback(
    (partialUser: Partial<ApiUser>) => {
      const currentState = store.getState();
      if (currentState.user) {
        store.setState({ user: { ...currentState.user, ...partialUser } });
      }
    },
    [store],
  );

  const updateEnvironmentPartially = useCallback(
    (partialEnvironment: Partial<ApiEnvironment>) => {
      const currentState = store.getState();
      if (currentState.environment) {
        store.setState({
          environment: { ...currentState.environment, ...partialEnvironment },
        });
      }
    },
    [store],
  );

  const setABExperimentBucket = useCallback(
    async <TExperimentId extends ApiABExperimentId>(
      experimentId: TExperimentId,
      bucket: ApiABExperimentBucketForId<TExperimentId>,
    ) => {
      const abExperiments = await apiSetABExperimentBucket(
        experimentId,
        bucket,
      );

      updateEnvironmentPartially({ abExperiments });
    },
    [updateEnvironmentPartially],
  );

  return {
    setUserEnvironment: store.setState,
    fetchUserEnvironment,
    updateUserPartially,
    updateEnvironmentPartially,
    setABExperimentBucket,
  };
}

/**
 * @deprecated
 *
 * Remove this method and use `useUser` and `useEnvironment` directly
 */
export function useUserEnvironment() {
  const { user } = useUser();
  const { environment } = useEnvironment();
  const store = useZustandContextStore();

  const isLoaded = Boolean(environment);

  return {
    user,
    environment,
    isUserLoaded: isLoaded,
    isEnvironmentLoaded: isLoaded,
    updateUserEnvironment: store.setState,
  };
}

// Temporary hack

/**
 * @deprecated
 *
 * This is a temporary hack
 *
 * We need to on context/hooks instead of global state
 */
export function legacyGetUserEnvironmentForZustandOrStaffRedux() {
  if (isRenderingHorizonServer()) return global.Idealist.horizonEnvironment;

  if (legacyLatestStore) return legacyLatestStore.getState();

  const userEnvironmentSsrApiData = apiGetFetchUserEnvironmentSsrApiData();
  return userEnvironmentSsrApiData as ApiUserEnvironment;
}

/**
 * @deprecated
 *
 * Remove this function once stores that depend on user environment get refactored
 */
export function legacyUpdateUserPartiallyInZustandStore(
  partialUser: Partial<ApiUser>,
) {
  const currentState = legacyLatestStore.getState();
  if (currentState.user) {
    legacyLatestStore.setState({
      user: { ...currentState.user, ...partialUser },
    });
  }
}

/**
 * @deprecated
 *
 * Remove this function once staff is migrated out of redux
 */
export async function legacyFetchUserEnvironmentForUsageInStaffRedux() {
  const { user, environment } = await apiFetchUserEnvironment();
  legacyLatestStore.setState({ user, environment });

  // Read searchLocation from localstorage
  // @TODO replace fallback to searchLocation with guessedLocation
  updateSearchLocation(
    searchGetInitialLocation(environment.guessedLocation),
    false,
  );

  return { user, environment };
}
