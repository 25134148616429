export const SEARCH_INTERNSHIP_REFINEMENT_TYPES = [
  'areasOfFocus',
  'functions',
  'locale',
  'locationType',
  'orgType',
  'payment',
  'radius',
  'recency',
] as const;

export type SearchInternshipRefinementType =
  (typeof SEARCH_INTERNSHIP_REFINEMENT_TYPES)[number];
