import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { exploreSalaryRoute } from 'modules/salaries/routing/salaries.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { SalaryReportLoadable } from './SalaryReportLoadable';

export const handle = {
  noAds: true,
  defaultSearchType: 'JOB',
  hideFooterDivider: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof exploreSalaryRoute>,
) {
  await SalaryReportLoadable.load();
}

export default function SalaryReportHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <SalaryReportLoadable />;
}
