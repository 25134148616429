import type { ChangeEvent, FocusEvent, KeyboardEvent, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

export function useAutocomplete(
  inputRef: RefObject<HTMLInputElement>,
  openOnFocus: boolean,
  shouldShowSuggestions: (inputValue: string) => boolean,
  onSuggestionSelected?: (suggestion: string) => void,
) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (openOnFocus && shouldShowSuggestions(e.target.value)) {
      setShowSuggestions(true);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputRef?.current) {
      if (!shouldShowSuggestions(e.target.value)) {
        setShowSuggestions(false);
      }
    }
  };

  const handleSuggestionClick = useCallback(
    (suggestion: string) => {
      if (inputRef.current) {
        // eslint-disable-next-line no-param-reassign
        inputRef.current.value = suggestion;

        const customEvent = new CustomEvent('change', {
          detail: { value: suggestion },
        });
        inputRef?.current.dispatchEvent(customEvent);

        inputRef.current.focus();

        if (onSuggestionSelected) {
          onSuggestionSelected(suggestion);
        }
      }

      setShowSuggestions(false);
    },
    [inputRef, onSuggestionSelected],
  );

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!showSuggestions || !suggestionsRef.current) return;

    const suggestionsCount = suggestionsRef.current.children.length;
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex((prev) => {
          if (prev === null) {
            return 0;
          }

          if (prev === suggestionsCount - 1) {
            return null;
          }

          return (prev + 1) % suggestionsCount;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex((prev) => {
          if (prev === null) {
            return suggestionsCount - 1;
          }

          if (prev === 0) {
            return null;
          }

          return (prev - 1 + suggestionsCount) % suggestionsCount;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedIndex !== null) {
          const suggestionElement =
            suggestionsRef.current.children[focusedIndex];
          const suggestionText = suggestionElement.textContent;
          if (suggestionText) {
            handleSuggestionClick(suggestionText);
          }
        }
        break;
      case 'Escape':
        setShowSuggestions(false);
        setFocusedIndex(null);
        if (inputRef.current) {
          inputRef.current.focus();
        }
        break;
    }
  };

  useEffect(() => {
    if (
      focusedIndex !== null &&
      suggestionsRef.current &&
      focusedIndex >= 0 &&
      focusedIndex < suggestionsRef.current.children.length
    ) {
      const focusedElement = suggestionsRef.current.children[
        focusedIndex
      ] as HTMLElement;
      focusedElement.focus();
    } else if (focusedIndex === null && inputRef.current && showSuggestions) {
      inputRef.current.focus();
    }
  }, [focusedIndex, inputRef, showSuggestions]);

  return {
    showSuggestions,
    setShowSuggestions,
    suggestionsRef,
    handleInputChange,
    handleInputFocus,
    handleInputKeyDown,
    handleSuggestionClick,
  };
}
