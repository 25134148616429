import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { searchEventsRoutePathByLocale } from 'modules/search/routing/search.route-paths';
import type { searchEventsRouteReference } from 'modules/search/routing/search.routes';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { SearchEventsPageLoadable } from './SearchEventsPageLoadable';
import { SearchEventsRefinements } from './components/SearchEventsRefinements';

export const handle = {
  searchRefinements: <SearchEventsRefinements />,
  urlByLocale: searchEventsRoutePathByLocale,
  darkBackground: true,
  defaultSearchType: 'EVENT',
  hideFooterDivider: true,
  searchFromPageType: 'SEARCH',
  showDesktopSearch: true,
  isSearchPage: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonLoaderFunctionArgs<typeof searchEventsRouteReference>,
) {
  await SearchEventsPageLoadable.load();
}

export default function SearchEventsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <SearchEventsPageLoadable />;
}
