import { useSearchParams } from 'react-router';

import type { ApiISODateString } from 'api/types/ApiTypedDate';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { hasDateRange } from 'modules/search/helpers/hasDateRange';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';

export function useSearchDateRangeSelectedValues(
  refinementType: SearchRefinementType,
) {
  const [searchParams] = useSearchParams();

  const searchFiltersFromState = useSearchFiltersByName();

  const searchFiltersFromUrl = {
    startsLT: searchParams.get('startsLT') as ApiISODateString | null,
    endsGT: searchParams.get('endsGT') as ApiISODateString | null,
  };

  if (
    refinementType !== 'actionDate' ||
    (!hasDateRange(searchFiltersFromState) &&
      !searchFiltersFromUrl.startsLT &&
      !searchFiltersFromUrl.endsGT)
  ) {
    return { startsLT: undefined, endsGT: undefined };
  }

  const hasDateRangeFromState =
    hasDateRange(searchFiltersFromState) &&
    Array.isArray(Object.keys(searchFiltersFromState)) &&
    Object.keys(searchFiltersFromState).length > 0 &&
    !(
      Object.keys(searchFiltersFromState).length === 1 &&
      Object.keys(searchFiltersFromState).includes('type')
    );

  if (hasDateRangeFromState) {
    const searchFacetSelectedValues = {
      endsGT: searchFiltersFromState.endsGT,
      startsLT: searchFiltersFromState.startsLT,
    };

    if (
      typeof searchFacetSelectedValues.endsGT !== 'undefined' ||
      typeof searchFacetSelectedValues.startsLT !== 'undefined'
    ) {
      return searchFacetSelectedValues;
    }
  }

  return searchFiltersFromUrl;
}
