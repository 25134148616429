import { styled } from 'styled-components';

import { Box } from 'components/Box/Box';
import { cssBreakpoints } from 'theme/theme';

export const BannerAdsBox = styled(Box)`
  @media screen and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }

  @media print {
    display: none;
  }
`;
