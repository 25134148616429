import { userDashboardPaymentMethodsAddCardRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserDashboardSavedItemsPageLoadable } from './UserDashboardSavedItemsPageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardPaymentMethodsAddCardRoute>,
) {
  await UserDashboardSavedItemsPageLoadable.load();
}

export default function UserDashboardSavedItemsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UserDashboardSavedItemsPageLoadable />;
}
