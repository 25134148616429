import type { PostOnIdealistDropdownData } from 'components/PostOnIdealistDropdown/types/PostOnIdealistDropdownData';

import { postOnIdealistDropdownDataEn } from './postOnIdealistDropdownData.en';
import { postOnIdealistDropdownDataEs } from './postOnIdealistDropdownData.es';
import { postOnIdealistDropdownDataPt } from './postOnIdealistDropdownData.pt';

export const postOnIdealistDropdownDataByLocale: Record<
  UserLocale,
  PostOnIdealistDropdownData
> = {
  en: postOnIdealistDropdownDataEn,
  es: postOnIdealistDropdownDataEs,
  pt: postOnIdealistDropdownDataPt,
};
