import { arrToObject, updateArrayItem } from './array';
import { gather } from './function';
import { isEmpty } from './poly';
import { isDefined } from './type';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

/*
  Filter object `obj` by function `fn`. The function should be of arity 2
  and operate on (key, value). E.g.,
  filterObject({1: 2, 3: 4, 4: 10}, (k, v) => isOdd(k)) -> {1: 2, 3: 4}
*/
export const filterObject = <S extends string | number | symbol, T>(
  obj: Readonly<Record<S, T>>,
  fn: (k: S, v: T) => boolean,
) => arrToObject(Object.entries(obj).filter(gather(fn)));

/*
  Removes keys from `obj` whose values are null or undefined
*/
export const filterNulls = <S>(
  obj: S | null | undefined,
  // @ts-expect-error TS(2345): Argument of type 'S | null | undefined' is not ass... Remove this comment to see the full error message
): NoUndefinedField<S> => filterObject(obj, (_, v) => isDefined(v));

/*
  Map object `obj` by function `fn`. The function should be of arity 2
  and operate on (key, value), returning an array of length 2.
  mapObject({1: 2, 3: 4, 4: 10}, (k, v) => [k + 1, v + 1]) ->
  {2: 3, 4: 5, 5: 11}
*/
export const mapObject = <S extends string | number | symbol, T, U, V = S>(
  obj: Readonly<Record<S, T>>,
  fn: (k: S, v: T) => [S | V, U | null | undefined],
) => arrToObject(Object.entries(obj).map(gather(fn)));

export function objectEmpty<T>(
  value: Record<string, T> | null | undefined,
): boolean {
  return !(value && typeof value === 'object' && Object.keys(value).length > 0);
}

export const removeKeys = <S extends string | number | symbol, T>(
  obj: Readonly<Record<S, T>>,
  keys: Array<S>,
) => filterObject(obj, (k) => !keys.includes(k));

/**
@deprecated type checking is incomplete. Use custom functions instead.
 Update an item in a nested object. E.g., updateIn({a: {b: 2}}, ['a', 'b'], x => x + 1) => {a: {b: 3}}
 If any levels do not exist, objects will be created
Does not modify the original object
*/
// @ts-expect-error TS(7023): 'updateIn' implicitly has return type 'any' becaus... Remove this comment to see the full error message
export const updateIn = <T>(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  accessor: ReadonlyArray<string>,
  operation: (arg0: T) => T,
) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-nested-ternary
  isEmpty(accessor)
    ? // @ts-expect-error TS(2345): Argument of type 'Record<string, any>' is not assi... Remove this comment to see the full error message
      operation(object) // TODO: Fix this the next time the file is edited.
    : // eslint-disable-next-line no-nested-ternary
      !object
      ? updateIn({}, accessor, operation)
      : Array.isArray(object) // handle 'objects' and 'arrays' with separate update operations
        ? // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updateArrayItem<Record<string, any>>(
            object,
            Number(accessor[0]),
            // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
            updateIn(object[accessor[0]], accessor.slice(1), operation),
          )
        : {
            ...object,
            [accessor[0]]: updateIn(
              object[accessor[0]],
              accessor.slice(1),
              operation,
            ),
          };

export const getter =
  <T>(attr: string) =>
  (x: Record<string, T> | null | undefined) =>
    x ? x[attr] : null;

export const selectKeys = <T>(object: Record<string, T>, keys: Array<string>) =>
  filterObject(object, (k) => keys.includes(k));
