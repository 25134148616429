import { styled } from 'styled-components';

import { colors } from 'theme/theme';

export const DividerHr = styled.hr`
  margin: 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${colors.selectionGrey};
`;
