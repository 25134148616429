import { ToastList } from 'components/Toast/List/ToastList';
import { Toast } from 'components/Toast/Toast';
import { dismissToast, useToasts } from 'zustand-stores/toastsStore';

export function ToastsContainer() {
  const { toasts } = useToasts();

  return (
    <ToastList>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          toastId={toast.id}
          headline={toast.headline}
          variant={toast.variant || 'default'}
          layout={toast.layout || 'compact'}
          icon={toast.icon}
          body={toast.body}
          dismissible={toast.dismissible}
          actions={toast.actions}
          onDismiss={() => dismissToast(toast.id)}
        />
      ))}
    </ToastList>
  );
}
