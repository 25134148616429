import { styled } from 'styled-components';

// Primarily used to offset icon spacing from normalization
export const InputIconContainer = styled.span`
  /* (24px viewbox size - 14.5px icon width) / 2 */
  margin-left: -4.75px;

  /* (24px viewbox size - 14.5px icon width - 1 svg offset) / 2 */
  margin-right: -4.75px;
`;
