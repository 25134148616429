import { styled } from 'styled-components';

import type { Spacing, breakpointValues } from 'theme/theme';
import { generateBreakpointValues } from 'utils/style';

export const StackHolder = styled.div<{
  $direction:
    | 'row'
    | 'column'
    | Partial<Record<keyof typeof breakpointValues, 'row' | 'column'>>;
  $spacing?: Spacing | Partial<Record<keyof typeof breakpointValues, Spacing>>;
}>`
  display: flex;
  ${({ $direction }) =>
    typeof $direction === 'string'
      ? `
    flex-direction: ${$direction};`
      : generateBreakpointValues('flex-direction', $direction)}
  align-items: stretch;
  justify-content: flex-start;

  ${({ $spacing }) =>
    typeof $spacing !== 'undefined' && typeof $spacing !== 'string'
      ? generateBreakpointValues('gap', $spacing)
      : `
    gap: ${$spacing || 0};`}
`;
