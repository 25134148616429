import type { SearchFacet } from 'modules/search/types/SearchFacet';

import { actionDateFacet } from './searchFacets/actionDateFacet';
import { localeFacet } from './searchFacets/localeFacet';
import { locationTypeFacet } from './searchFacets/locationTypeFacet';
import { recencyFacet } from './searchFacets/recencyFacet';
import { volopAreasOfFocusFacet } from './searchFacets/volopAreasOfFocusFacet';
import { volopKeywordsFacet } from './searchFacets/volopKeywordsFacet';
import { volopSourceFacet } from './searchFacets/volopSourceFacet';

export const searchFacetsVolop = [
  locationTypeFacet,
  volopAreasOfFocusFacet,
  volopSourceFacet,
  actionDateFacet,
  volopKeywordsFacet,
  localeFacet,
  recencyFacet,
] satisfies SearchFacet[];
