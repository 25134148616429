import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { UserDashboardApiSavedSearch } from './types/UserDashboardApiSavedSearch';

type ApiResponse = {
  savedSearch: UserDashboardApiSavedSearch;
};

function mapResponse(response: ApiResponse): UserDashboardApiSavedSearch {
  return response.savedSearch;
}

const endpoint = new FetchEndpoint({
  urlFactory: (savedSearchId: string) =>
    `/data/saved-searches/${savedSearchId}`,
  mapResponse,
});

// Exports

export const userDashboardApiFetchSavedSearch = endpoint.createFetchFn();
