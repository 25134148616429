import { styled } from 'styled-components';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { colors, fontWeights, h5Text, transition } from 'theme/theme';

export const AutosuggestWrapper = styled.div<{
  $hasError?: boolean;
  $height?: string | number;
  $borderSize?: number;
  $large?: boolean;
}>`
  position: relative;

  .react-autosuggest {
    &__input {
      appearance: none;
      margin: 0;
      padding: 11px 12px;
      font: inherit;
      font-size: 18px;
      color: ${colors.darkContentGrey};
      border-radius: 8px;
      border: solid 1px;
      border-color: ${(p) =>
        p.$hasError ? colors.alertRed : colors.elementGrey};
      height: ${({ $height }) => $height || '45px'};
      width: 100%;
      background-color: ${colors.white};
      transition: all ${transition};

      ::placeholder {
        color: ${colors.elementGrey};
      }

      &:focus {
        border-color: ${colors.brandBlue};
        box-shadow: 0 0 0 1px ${colors.brandBlue};
        outline: 0;
      }

      &:disabled {
        background-color: ${colors.inputGrey};
      }

      ${(props) =>
        props.$hasError &&
        `
        border-color: ${colors.alertRed};
        box-shadow: 0 0 0 1px ${colors.alertRed};
      `}
    }

    &__suggestions-container {
      display: none;

      &--open {
        display: block;
        margin-top: 4px;
        position: absolute;
        width: 100%;
        overflow-y: scroll;
        max-height: 400px;
        border: 1px solid;
        border-color: ${colors.elementGrey};
        background-color: #fff;
        font-size: 18px;
        line-height: 19px;
        border-radius: 8px;
        z-index: 2;
      }
    }

    &__suggestions-list {
      margin: 0;
      padding: 0 ${({ $borderSize = 0 }) => $borderSize}px;
      list-style-type: none;
    }

    &__suggestion {
      cursor: pointer;
      padding: ${(props) => (props.$large ? '16px 24px' : '10px 1em')};
      transition: all ${transition};
      ${({ $borderSize = 0 }) => `
        box-shadow: 0 -${$borderSize}px rgba(255,255,255,0) inset, 0 ${$borderSize}px rgba(255,255,255,0) inset;
      `}

      > .disabled {
        background-color: ${colors.calloutGrey};
        margin: -10px -1em;
        padding: 10px 1em;
      }

      &:not(:first-child) {
        border-top: 1px dashed ${colors.selectionGrey};
      }

      &--highlighted {
        background-color: ${colors.calloutGrey};
        ${({ $borderSize = 0 }) => `
          box-shadow: 0 -${$borderSize}px white inset, 0 ${$borderSize}px white inset;
        `}
      }
    }
  }
`;

export const AutocompleteHolder = styled.div`
  position: relative;
`;

export const AutocompleteSuggestionsHolder = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 0 20px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 98;
  border-radius: 8px;
`;

export const AutocompleteTitle = styled.label`
  ${h5Text};
  display: block;
  line-height: 1;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 17px;
`;

export const AutocompleteSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-left: -5px;
  overflow-y: auto;
  padding-bottom: 17px;
  gap: 9px;
`;

export const AutocompleteSuggestion = styled(ButtonOrLink)`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  color: ${colors.mediumContentGrey};
  font-weight: ${fontWeights.normal};
  text-align: left;
  border-radius: 8px;
  letter-spacing: 0.5px;
  line-height: 1.25;
  padding: 4px;

  &:hover,
  &:focus {
    color: ${colors.hoverBlue};
  }

  &:focus {
    background-color: ${colors.calloutGrey};
    outline: 0;
  }
`;
