import { redirect } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { getHorizonUserEnvironmentPromiseFromContext } from 'horizon/utils/getHorizonUserEnvironmentPromiseFromContext';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { userDashboardApiHorizonFetchListingApplication } from 'modules/userDashboard/api/listingApplication/userDashboardApiHorizonFetchListingApplication';
import { userDashboardApplicationSummaryRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ApplicationSummaryPageLoadable } from './ApplicationSummaryPageLoadable';

export const hypernovaClientOnly = true;

export const handle = {
  darkBackground: true,
  fallbackUrlByLocaleToCurrent: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params: { applicationId },
}: HorizonAppRouteLoaderArgs<typeof userDashboardApplicationSummaryRoute>) {
  if (isRenderingHorizonServer()) {
    const userEnvironment = await getHorizonUserEnvironmentPromiseFromContext({
      request,
      context,
    });

    if (!userEnvironment.user) return redirect('/login');
  }

  const [listingApplication] = await Promise.all([
    userDashboardApiHorizonFetchListingApplication({ request, context })(
      applicationId,
    ),
    ApplicationSummaryPageLoadable.load(),
  ]);

  return { listingApplication };
}

export default function UserDashboardApplicationSummaryHorizonRoute({
  loaderData: { listingApplication },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return (
    <RequireAuthentication>
      <ApplicationSummaryPageLoadable application={listingApplication} />
    </RequireAuthentication>
  );
}
