import { userDashboardProfileRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserDashboardProfilePageLoadable } from './UserDashboardProfilePageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardProfileRoute>,
) {
  await UserDashboardProfilePageLoadable.load();
}

export default function UserDashboardProfileHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UserDashboardProfilePageLoadable />;
}
