import { authLoginRoute } from 'modules/auth/routing/auth.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { LoginPageContainerLoadable } from './LoginPageContainerLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof authLoginRoute>,
) {
  await LoginPageContainerLoadable.load();
}

export default function AuthLoginHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <LoginPageContainerLoadable />;
}
