import type { ChangeEvent, ComponentProps } from 'react';
import { useCallback } from 'react';

import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import type { Input } from 'components/Input/Input';
import { VisuallyHidden } from 'components/VisuallyHidden/VisuallyHidden';
import type { SearchType } from 'modules/search/types/SearchType';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';

type Props = {
  id: string;
  placeholder?: string;
  initialQuery: string;
  onChange?: (value: string) => void;
  onClearQuery?: () => void;
  popularSearches?: string[];
  presentation: ComponentProps<typeof Input>['styleVariant'];
  qaId: string;
  selectedType?: SearchType;
};

export function UncontrolledSearchQueryInputContainer({
  id,
  initialQuery,
  onChange,
  onClearQuery,
  placeholder,
  popularSearches,
  presentation,
  qaId,
  selectedType,
}: Props) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const eventValue = event.target.value;
      const value = truncateToUTF8Bytes(eventValue, 512);

      if (value !== eventValue) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = value;
      }

      onChange?.(value);
    },
    [onChange],
  );

  const onSuggestionSelected = (suggestion: string) =>
    trackClicked('Popular Searches', {
      search_term: suggestion,
      selected_listing_type: selectedType,
    });

  return (
    <>
      <VisuallyHidden>
        <label htmlFor={id}>{placeholder}</label>
      </VisuallyHidden>
      <Autocomplete
        inputProps={{
          defaultValue: initialQuery,
          id,
          placeholder,
        }}
        title={getText('Popular Searches')}
        suggestions={popularSearches || []}
        shouldShowSuggestions={(inputValue) => inputValue.length === 0}
        onSuggestionSelected={onSuggestionSelected}
        openOnFocus
        qaId={qaId}
        onChange={handleChange}
        onClear={onClearQuery}
        presentation={presentation}
      />
    </>
  );
}
