import type { ApiJobId, ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { OrgDashboardApiJob } from './types/OrgDashboardApiJob';

type ApiResponse = {
  job: OrgDashboardApiJob;
};

function mapResponse(response: ApiResponse): OrgDashboardApiJob {
  return response.job;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId, jobId: ApiJobId) =>
    `/data/orgdashboard/${orgId}/jobs/${jobId}`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchJob = endpoint.createFetchFn();

export const orgDashboardApiHorizonFetchJob = endpoint.createHorizonFetchFn();

export const useOrgDashboardApiFetchJob = endpoint.createUseFetchHook();
