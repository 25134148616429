import type { RouteObject } from 'react-router';

import * as viewEventHorizonRouteExports from 'modules/listing/pages/events/view/ViewEvent.horizon-route';
import * as viewInternshipHorizonRouteExports from 'modules/listing/pages/internships/view/ViewInternship.horizon-route';
import * as viewJobHorizonRouteExports from 'modules/listing/pages/jobs/view/ViewJob.horizon-route';
import * as viewOrgHorizonRouteExports from 'modules/listing/pages/orgs/view/ViewOrg.horizon-route';
import * as viewVolopHorizonRouteExports from 'modules/listing/pages/volops/view/ViewVolop.horizon-route';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

import {
  viewEventRoutePaths,
  viewInternshipRoutePaths,
  viewJobRoutePaths,
  viewOrgRoutePaths,
  viewVolopRoutePaths,
} from './listing.route-paths';
import {
  viewEventReferenceRoute,
  viewInternshipReferenceRoute,
  viewJobReferenceRoute,
  viewOrgReferenceRoute,
  viewVolopReferenceRoute,
} from './listing.routes';

export const listingRouteObjects = [
  ...viewJobRoutePaths.map<RouteObject>((path) => ({
    path: viewJobReferenceRoute.fullPath.replace('/-lang/-segment', path),
    ...horizonRouteToObject(viewJobHorizonRouteExports),
  })),

  ...viewInternshipRoutePaths.map((path) => ({
    path: viewInternshipReferenceRoute.fullPath.replace(
      '/-lang/-segment',
      path,
    ),
    ...horizonRouteToObject(viewInternshipHorizonRouteExports),
  })),

  ...viewVolopRoutePaths.map((path) => ({
    path: viewVolopReferenceRoute.fullPath.replace('/-lang/-segment', path),
    ...horizonRouteToObject(viewVolopHorizonRouteExports),
  })),

  ...viewEventRoutePaths.map((path) => ({
    path: viewEventReferenceRoute.fullPath.replace('/-lang/-segment', path),
    ...horizonRouteToObject(viewEventHorizonRouteExports),
  })),

  ...viewOrgRoutePaths.map((path) => ({
    path: viewOrgReferenceRoute.fullPath.replace('/-lang/-segment', path),
    ...horizonRouteToObject(viewOrgHorizonRouteExports),
  })),
] satisfies RouteObject[];
