import type { RadiusType } from 'modules/search/helpers/radius';
import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import type { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchGetEventsRoutePath } from './searchGetEventsRoutePath';
import { searchGetInternshipsRoutePath } from './searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from './searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from './searchGetOrgsRoutePath';
import { searchGetVolopsRoutePath } from './searchGetVolopsRoutePath';

type Args = {
  lang: UserLocale;
  filtersByName: SearchFiltersByName;
  q?: string;
  radius?: RadiusType | string;
  sort?: SearchSortType;
  pageIndex?: number;
};

export function searchGetRoutePathSwitch({
  lang,
  filtersByName,
  q,
  radius,
  sort,
  pageIndex,
}: Args) {
  switch (filtersByName.type) {
    case 'JOB':
      return searchGetJobsRoutePath({
        lang,
        q,
        radius,
        filtersByName,
        sort,
        pageIndex,
      });

    case 'INTERNSHIP':
      return searchGetInternshipsRoutePath({
        lang,
        q,
        radius,
        filtersByName,
        sort,
        pageIndex,
      });

    case 'VOLOP':
      return searchGetVolopsRoutePath({
        lang,
        q,
        radius,
        filtersByName,
        sort,
        pageIndex,
      });

    case 'EVENT':
      return searchGetEventsRoutePath({
        lang,
        q,
        radius,
        filtersByName,
        sort,
        pageIndex,
      });

    case 'ORG':
      return searchGetOrgsRoutePath({
        lang,
        q,
        radius,
        filtersByName,
        sort,
        pageIndex,
      });
  }
}
