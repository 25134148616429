import { viewUserProfileRoute } from 'modules/userProfile/routing/userProfile.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ViewUserProfilePageLoadable } from './ViewUserProfilePageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof viewUserProfileRoute>,
) {
  await ViewUserProfilePageLoadable.load();
}

export default function ViewUserProfileHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <ViewUserProfilePageLoadable />;
}
