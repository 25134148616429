import { styled } from 'styled-components';

import { colors, cssBreakpoints } from 'theme/theme';

export const SearchFacetOptionsHolder = styled.div`
  & > button {
    width: 100%;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    & > button {
      width: max-content;
    }
  }
`;

export const SearchFacetOptionLabel = styled.label`
  font-size: 14px;
  line-height: 1.5;
  align-items: center;
  display: flex;
  justify-content: start;
`;

export const SearchFacetOptionInput = styled.input`
  appearance: none;
  height: 14px;
  width: 14px;
  border: 1px solid ${colors.elementGrey};
  border-radius: 3px;
  background: ${colors.white};
  padding: 3px;
  cursor: pointer;
  margin: 0;
  margin-right: 18px;

  &[type='radio'] {
    border-radius: 100%;
  }

  &:checked {
    appearance: revert;
  }
`;
