import { styled } from 'styled-components';

export const PageHeaderSearchDesktopInputsForm = styled.form<{
  $isTabbing?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
`;

export const PageHeaderSearchInputsDesktopFieldsHolder = styled.div`
  display: flex;
  flex: 10;
  align-items: center;
  gap: 12px;
`;

export const PageHeaderSearchInputsDesktopListingTypeSelectHolder = styled.div`
  max-width: 336px;
  flex: 1;
`;

export const PageHeaderSearchInputsDesktopQueryInputHolder = styled.div`
  flex: 2;
`;

export const PageHeaderSearchInputsDesktopLocationFooter = styled.footer`
  flex: 1;
  min-width: 106px;
  max-width: 162px;
`;
