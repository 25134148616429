import { useEffect, useMemo, useState } from 'react';

import { useUser } from 'contexts/UserEnvironmentContext';
import { useUnsignedPageVisitCounter } from 'hooks/usePageVisitCounter';
import { useToastPersistence } from 'hooks/useToastPersistence';
import { useUserHasPreviouslySignedIn } from 'hooks/useUserHasPreviouslySignedIn';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEventOnce } from 'utils/analytics/track/trackEventOnce';
import { trackDisplayToast } from 'utils/analytics/track/trackToast';
import { showModal } from 'zustand-stores/modalStore';
import {
  dismissToast,
  isDisplayingToast,
  isToastDisabledForDebug,
  showToast,
} from 'zustand-stores/toastsStore';

// Note(@paprikka): SignUpReminder and SignInReminder are almost identical,
// except for some minor differences in the text and the toast type and display
// conditions.  We could abstract this out into a common component, but it's not
// worth it right now since it's a new feature and the requirements might change
// based on usage.
const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;

function SignUpReminder() {
  const { hasDismissedToast, persistDismissedToastId, clearExpired } =
    useToastPersistence('sign-up-reminder');
  const isLoggedIn = Boolean(useUser().user);

  const { getVisitCount } = useUnsignedPageVisitCounter();
  const pageVisitCount = getVisitCount();
  const [hasCheckedDates, setHasCheckedDates] = useState(false);

  useEffect(() => {
    setHasCheckedDates(true);
    clearExpired(ONE_MONTH, 'unrecognised-user-toast');
  }, [clearExpired, hasCheckedDates]);

  const shouldDisplayToast = useMemo(() => {
    if (!hasCheckedDates) return false;
    if (isDisplayingToast('unrecognised-user-toast')) return false;
    if (pageVisitCount < 3) return false;
    if (hasDismissedToast('unrecognised-user-toast')) return false;

    return true;
  }, [hasCheckedDates, pageVisitCount, hasDismissedToast]);

  useEffect(() => {
    if (!shouldDisplayToast) return;

    trackDisplayToast({
      type: 'sign_up',
      current_page_url: window.location.href,
    });
    showToast({
      id: 'unrecognised-user-toast',
      headline: getText('Join Idealist for the best experience'),
      body: getText('Sign up to access email alerts, save searches, and more!'),
      dismissible: true,
      durationMs: 15_000,
      icon: 'success',
      variant: 'success',
      onDismiss: () => {
        persistDismissedToastId('unrecognised-user-toast');
        trackEventOnce('Dismissed: Action Toast', {
          type: 'sign_up',
          current_page_url: window.location.href,
        });
      },
      actions: [
        {
          label: getText('Sign Up'),
          onClick: () => {
            persistDismissedToastId('unrecognised-user-toast');
            dismissToast('unrecognised-user-toast');
            trackClicked('Action Toast', {
              type: 'sign_up',
              current_page_url: window.location.href,
            });
            showModal('SIGNUP', {});
          },
          type: 'button',
        },
      ],
    });
  }, [persistDismissedToastId, shouldDisplayToast]);

  useEffect(() => {
    if (!isLoggedIn) return;
    dismissToast('unrecognised-user-toast');
  }, [isLoggedIn]);

  return null;
}

function SignInReminder() {
  const { hasDismissedToast, persistDismissedToastId, clearExpired } =
    useToastPersistence('sign-in-reminder');
  const isLoggedIn = Boolean(useUser().user);

  const { getVisitCount } = useUnsignedPageVisitCounter();
  const pageVisitCount = getVisitCount();

  const [hasCheckedDates, setHasCheckedDates] = useState(false);

  useEffect(() => {
    setHasCheckedDates(true);
    clearExpired(ONE_MONTH, 'recognised-user-toast');
  }, [clearExpired, hasCheckedDates]);

  const shouldDisplayToast = useMemo(() => {
    if (!hasCheckedDates) return false;
    if (isDisplayingToast('recognised-user-toast')) return false;
    if (pageVisitCount < 3) return false;
    if (hasDismissedToast('recognised-user-toast')) return false;

    return true;
  }, [hasCheckedDates, pageVisitCount, hasDismissedToast]);

  useEffect(() => {
    if (!shouldDisplayToast) return;

    trackDisplayToast({
      type: 'log_in',
      current_page_url: window.location.href,
    });
    showToast({
      id: 'recognised-user-toast',
      headline: getText('Log in for the best experience'),
      body: getText('Sign in to access email alerts, save searches, and more!'),
      dismissible: true,
      durationMs: 15_000,
      variant: 'success',
      onDismiss: () => {
        persistDismissedToastId('recognised-user-toast');
        trackEventOnce('Dismissed: Action Toast', {
          type: 'log_in',
          current_page_url: window.location.href,
        });
      },
      actions: [
        {
          label: getText('Log In'),
          onClick: () => {
            persistDismissedToastId('recognised-user-toast');
            dismissToast('recognised-user-toast');
            trackClicked('Action Toast', {
              type: 'log_in',
              current_page_url: window.location.href,
            });
            showModal('LOGIN', {});
          },
          type: 'button',
        },
      ],
    });
  }, [persistDismissedToastId, shouldDisplayToast]);

  useEffect(() => {
    if (!isLoggedIn) return;
    dismissToast('recognised-user-toast');
  }, [isLoggedIn]);

  return null;
}

export function SignInOrUpReminderContainerClientSide() {
  const isLoggedIn = Boolean(useUser().user);
  const hasPreviouslySignedIn = useUserHasPreviouslySignedIn();
  if (isLoggedIn) return null;
  return hasPreviouslySignedIn ? <SignInReminder /> : <SignUpReminder />;
}

export function SignInOrUpReminderContainer() {
  if (isToastDisabledForDebug('unrecognised-user-toast')) return null;
  if (isToastDisabledForDebug('recognised-user-toast')) return null;

  return <SignInOrUpReminderContainerClientSide />;
}
