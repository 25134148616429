import { searchClient } from '@algolia/client-search';
import type {
  SearchParamsObject,
  SearchResponse,
} from '@algolia/client-search';
import invariant from 'invariant';
import queryString from 'query-string';

import { configValue } from 'config/appConfigUtils';
import { RichError } from 'utils/RichError';
import { postJson } from 'utils/http/postJson';
import { trackError } from 'utils/trackError';

import { AlgoliaGeneralError } from './AlgoliaGeneralError';
import {
  AlgoliaValidUntilError,
  isValidUntilError,
} from './AlgoliaValidUntilError';

type Args = {
  config: Readonly<{ appId: string; apiKey: string }>;
  queries: {
    analytics?: boolean;
    indexName: string;
    options: SearchParamsObject;
    query: string;
  }[];
  onApiKeyTimeout?: () => Promise<string>;
};

export async function algoliaSearchMulti<TResponse>({
  config: { appId, apiKey },
  queries,
  onApiKeyTimeout,
}: Args) {
  if (configValue('algolia', 'mock')) {
    const response = await postJson<SearchResponse<TResponse>>(
      `http://algoliamock:6577/${queries[0].indexName}/search`,
      {
        query: queries[0].query,
        hitsPerPage: queries[0].options.hitsPerPage,
        page: queries[0].options.page,
        facets: queries[0].options.facets,
        filters: queries[0].options.filters,
        attributesToHighlight: queries[0].options.attributesToHighlight,
      },
      {
        headers: {
          'x-algolia-api-key': apiKey,
        },
      },
    );

    return { results: [response] };
  }
  invariant(appId, 'Search app id must be configured');
  invariant(apiKey, 'Search api key must be configured');

  const requests = queries.map((q) => ({
    indexName: q.indexName,
    ...q.options,
    query: q.query ? q.query.trim() : '',
    getRankingInfo: true,
    clickAnalytics: true,
    analytics: q.analytics || true,
  }));

  let client = searchClient(appId, apiKey);

  let request = client.searchForHits<TResponse>({
    requests,
  });

  return request.catch((e) => {
    if (isValidUntilError(e)) {
      if (onApiKeyTimeout) {
        return onApiKeyTimeout().then((newApiKey) => {
          client = searchClient(appId, newApiKey);
          request = client.searchForHits({ requests });
          return request;
        });
      }

      throw new AlgoliaValidUntilError();
    }

    const requestInfo = {
      errorMessage: e.message,
      requestParams: queryString.stringify(requests),
      statusCode: e.statusCode,
      response: e.response,
    };

    trackError(
      new RichError('Algolia POST request error.', {
        requestInfo: JSON.stringify(requestInfo),
      }),
    );

    throw new AlgoliaGeneralError(
      `Algolia Error: ${JSON.stringify(requestInfo)}`,
    );
  });
}
