import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router';

import { useLatest } from 'hooks/useLatest';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import {
  useSearchFiltersByName,
  useSearchRadius,
} from 'modules/search/zustand-stores/searchStore';

export function useSearchRefinementsOverflow() {
  const overflowRefinementsRef = useRef<HTMLDivElement | null>(null);
  const [visibleRefinements, setVisibleRefinements] = useState<
    SearchRefinementType[]
  >([]);

  const [searchParams] = useSearchParams();
  const searchFiltersByName = useSearchFiltersByName();
  const searchRadius = useSearchRadius();

  const resizeTimeoutIdRef = useLatest<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateVisibleRefinements = () => {
      if (!overflowRefinementsRef.current) return;

      const filterNodes =
        overflowRefinementsRef.current.querySelectorAll('[data-facet-type]');
      const newVisibleRefinements: SearchRefinementType[] = [];

      filterNodes.forEach((node) => {
        node.setAttribute('data-hidden', 'true');
      });

      filterNodes.forEach((node) => {
        const refinementType = node.getAttribute(
          'data-facet-type',
        ) as SearchRefinementType;

        const filterAccordion = node.closest('details');

        if (!filterAccordion) return;

        if (
          !refinementType ||
          getComputedStyle(filterAccordion).display === 'none'
        )
          return;
        newVisibleRefinements.push(refinementType);
        node.setAttribute('data-hidden', 'false');
      });

      if (
        newVisibleRefinements.length !== visibleRefinements.length ||
        newVisibleRefinements.some(
          (filter, index) => filter !== visibleRefinements[index],
        )
      ) {
        setVisibleRefinements(newVisibleRefinements);
      }
    };

    updateVisibleRefinements();

    const observer = new MutationObserver(updateVisibleRefinements);
    if (overflowRefinementsRef.current) {
      observer.observe(overflowRefinementsRef.current, {
        attributes: true,
        childList: true,
        attributeFilter: ['style', 'checked'],
        subtree: true,
      });
    }

    window.addEventListener('resize', () => {
      if (resizeTimeoutIdRef.current) {
        clearTimeout(resizeTimeoutIdRef.current);
      }

      resizeTimeoutIdRef.current = setTimeout(
        () => updateVisibleRefinements(),
        20,
      );
    });

    return () => {
      observer.disconnect();
    };
  }, [resizeTimeoutIdRef, visibleRefinements]);

  const selectedRefinementValues = useMemo(() => {
    if (!overflowRefinementsRef.current) return 0;

    return visibleRefinements.reduce(
      (acc: number, refinementType: SearchRefinementType) => {
        // First check URL parameters
        const urlParams = searchParams.getAll(refinementType);
        if (urlParams.length > 0) {
          return acc + urlParams.length;
        }

        // Search Radius is a special case
        if (refinementType === 'radius' && searchRadius) {
          return acc + 1;
        }

        // Fall back to store values if URL params don't exist
        if (
          searchFiltersByName &&
          refinementType in searchFiltersByName &&
          Array.isArray(
            searchFiltersByName[refinementType as keyof SearchFiltersByName],
          )
        ) {
          return (
            acc +
            // @ts-expect-error Need to figure out why type refinement is failing here for `searchFiltersByName`
            searchFiltersByName[refinementType as keyof SearchFiltersByName]
              .length
          );
        }

        return acc;
      },
      0,
    );
  }, [visibleRefinements, searchParams, searchRadius, searchFiltersByName]);

  return {
    overflowRefinementsRef,
    selectedRefinementValues,
    visibleRefinements,
  };
}
