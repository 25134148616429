import type { ComponentType, ReactNode } from 'react';
import {
  type LoaderFunction,
  type ShouldRevalidateFunction,
} from 'react-router';

import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunction } from 'routing/types/HorizonLoaderFunction';
import type { HorizonLoaderFunctionData } from 'routing/types/HorizonLoaderFunctionData';

import { renderLoader } from './renderLoader/renderLoader';

export function horizonRouteToObject<
  TLoader extends HorizonLoaderFunction,
>(exportedValues: {
  id?: string;
  handle?: Record<string, unknown>;
  shouldRevalidate?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args: any,
  ) => boolean;
  loader?: TLoader;
  default: ComponentType<HorizonAppRouteComponentProps<TLoader>>;
  hypernovaClientOnly?: boolean;
}): {
  id?: string;
  handle?: Record<string, unknown>;
  shouldRevalidate?: ShouldRevalidateFunction;
  loader: LoaderFunction;
  element: ReactNode;
} {
  const {
    id,
    handle,
    shouldRevalidate,
    loader,
    default: Component,
    hypernovaClientOnly,
  } = exportedValues;

  return {
    id,
    handle,
    shouldRevalidate,
    ...renderLoader({
      clientOnly: hypernovaClientOnly,
      loader: (loader || (() => null)) as LoaderFunction,
      render: (loaderData, params) => (
        <Component
          loaderData={loaderData as HorizonLoaderFunctionData<TLoader>}
          params={params}
        />
      ),
    }),
  } as const;
}
