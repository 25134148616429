import type { SearchPagination, SearchResponse } from '@algolia/client-search';
import queryString from 'query-string';

import { configString } from 'config/appConfigUtils';
import { cmsApiGetFetchLandingPageSsrApiData } from 'modules/cms/api/cmsApiFetchPage';
import { searchAlgoliaOptionsLocationData } from 'modules/search/algolia/options/searchAlgoliaOptionsLocationData';
import { searchAlgoliaOptionsWithSearchLocation } from 'modules/search/algolia/options/searchAlgoliaOptionsWithSearchLocation';
import { searchAlgoliaOptionsSwitch } from 'modules/search/algolia/options/switch/searchAlgoliaOptionsSwitch';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { searchAlgoliaIndexSwitch } from 'modules/search/algolia/searchAlgoliaIndexSwitch';
import type { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { SEARCH_REFINEMENT_TYPES } from 'modules/search/constants/refinements/searchRefinementTypes';
import { extractLocationAnalyticsProperties } from 'modules/search/helpers/analytics/extractLocationAnalyticsProperties';
import { searchConvertFiltersByIdToFiltersByName } from 'modules/search/helpers/searchConvertFiltersByIdToFiltersByName';
import { isLocationEmpty } from 'modules/search/helpers/sidefilters';
import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import type { SearchFacetId } from 'modules/search/types/SearchFacet';
import type { SearchFacetCounts } from 'modules/search/types/SearchFacetCounts';
import type { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import type { SearchLocation } from 'modules/search/types/SearchLocation';
import type { SearchSortType } from 'modules/search/types/SearchSortType';
import type { SearchType } from 'modules/search/types/SearchType';
import { algoliaSearchMulti } from 'utils/algolia/algoliaSearchMulti';
import { algoliaBuildFilterString } from 'utils/algolia/helpers/algoliaBuildFilterString';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { SEARCH_RADIUSES } from 'utils/constants/general/searchRadiuses';
import { setItem } from 'utils/localStorage';
import { createSsrApiDataStore } from 'zustand-stores/utils/createSsrApiDataStore';

const queryParams =
  typeof window === 'object' ? queryString.parse(window.location.search) : {};

function parseRadius(radius: string) {
  const isValidRadius = SEARCH_RADIUSES[CURRENT_LOCALE()][radius];

  if (isValidRadius) return radius;
}

type SearchStoreState = {
  query: string;
  pageIndex: number;
  resultsByPage: Record<
    string,
    SearchResponse<SearchAlgoliaHit> & Required<SearchPagination>
  >;
  isSearching: boolean;
  sort: SearchSortType;
  facetCounts: SearchFacetCounts | undefined;
  filters: SearchFiltersById;
  filtersByName: SearchFiltersByName;
  searchLocation: SearchLocation | undefined;
  radius: string | undefined;
};

const initialState: SearchStoreState = {
  query: '',
  facetCounts: undefined,
  pageIndex: 0,
  resultsByPage: {},
  isSearching: false,
  sort: 'relevance',
  filters: { type: 'JOB' },
  filtersByName: { type: 'JOB' },
  searchLocation: undefined,
  radius:
    typeof queryParams.radius === 'string'
      ? parseRadius(queryParams.radius)
      : undefined,
};

const { store: searchStore, hook: useSearchStore } =
  createSsrApiDataStore<SearchStoreState>({
    getSsrState: () => {
      const ssrApiDataForSearchLandingPage =
        cmsApiGetFetchLandingPageSsrApiData();
      if (!ssrApiDataForSearchLandingPage) return;

      const { data: content, ssrLandingPageResults } =
        ssrApiDataForSearchLandingPage;

      const pageIndex = ssrLandingPageResults.page;

      return {
        query: content.searchText,
        pageIndex,
        facetCounts: undefined,
        resultsByPage: { [pageIndex]: ssrLandingPageResults },
        isSearching: false,
        sort: 'relevance',
        jobFamilyFacetData: undefined,
        filters: content.filters,
        filtersByName: searchConvertFiltersByIdToFiltersByName(content.filters),
        searchLocation: content.location.searchLocation,
        radius: content.location.radius
          ? parseRadius(content.location.radius.toString())
          : undefined,
      };
    },
    fallbackState: initialState,
  });

// Hooks & getters

export function getSearchFilterType(): SearchType {
  return searchStore.getState().filters.type;
}

export function useSearchFilterType(): SearchType {
  return useSearchStore((state) => state.filters.type);
}

export function getSearchCurrentPageResults():
  | (SearchResponse<SearchAlgoliaHit> & Required<SearchPagination>)
  | undefined {
  const { pageIndex, resultsByPage } = searchStore.getState();
  return resultsByPage[pageIndex];
}

export function useSearchResultsByPage(): Record<
  number,
  SearchResponse<SearchAlgoliaHit> & Required<SearchPagination>
> {
  return useSearchStore((state) => state.resultsByPage);
}

export function useSearchCurrentPageResults():
  | (SearchResponse<SearchAlgoliaHit> & Required<SearchPagination>)
  | undefined {
  return useSearchStore((state) => state.resultsByPage[state.pageIndex]);
}

export function getSearchQuery(): string {
  return searchStore.getState().query;
}

export function useSearchQuery(): string {
  return useSearchStore((state) => state.query);
}

export function getSearchLocation(): SearchLocation | undefined {
  return searchStore.getState().searchLocation;
}

export function useSearchLocation(): SearchLocation | undefined {
  return useSearchStore((state) => state.searchLocation);
}

export function useSearchLocationOrEmpty(): SearchLocation {
  return useSearchStore(
    (state) => state.searchLocation || EMPTY_SEARCH_LOCATION,
  );
}

export function getSearchFiltersById(): SearchFiltersById {
  return searchStore.getState().filters;
}

export function useSearchFiltersById(): SearchFiltersById {
  return useSearchStore((state) => state.filters);
}

export function getSearchFiltersByName() {
  return searchStore.getState().filtersByName;
}

export function useSearchFiltersByName() {
  return useSearchStore((state) => state.filtersByName);
}

export function getSearchSort(): SearchSortType {
  return searchStore.getState().sort;
}

export function useSearchSort(): SearchSortType {
  return useSearchStore((state) => state.sort);
}

export function useIsSearching(): boolean {
  return useSearchStore((state) => state.isSearching);
}

export function getSearchRadius(): string {
  const { radius } = searchStore.getState();
  return radius || '';
}

export function useSearchRadius(): string {
  return useSearchStore(({ radius }) => radius || '');
}

export function getSearchPageIndex(): number {
  return searchStore.getState().pageIndex;
}

export function useSearchPageIndex(): number {
  return useSearchStore((state) => state.pageIndex);
}

export function useSearchFacetCounts(): SearchFacetCounts | undefined {
  return useSearchStore((state) => state.facetCounts);
}

// Helpers

export function selectedOption(facetId: SearchFacetId) {
  const options = getSearchFiltersById()[facetId];
  return Array.isArray(options) ? options[0] : options;
}

// Actions

export function updateSearchLocation(
  searchLocation: SearchLocation,
  didUserChange: boolean,
) {
  if (didUserChange) {
    trackEvent(
      'Changed Search Location',
      extractLocationAnalyticsProperties(searchLocation),
    );
  }

  setItem('searchLocation', searchLocation);
  searchStore.setState({
    searchLocation: {
      ...EMPTY_SEARCH_LOCATION,
      ...searchLocation,
      hasGeo: !isLocationEmpty(searchLocation),
    },
  });
}

export function setSearchRadius(radius: string | undefined) {
  return searchStore.setState({ radius });
}

export function clearSearchLocation(didUserChange: boolean): void {
  if (didUserChange) {
    trackEvent('Cleared Search Location');
  }

  setItem('searchLocation', EMPTY_SEARCH_LOCATION);
  searchStore.setState({ searchLocation: EMPTY_SEARCH_LOCATION });
}

export function setSearchFilters(filtersById: SearchFiltersById) {
  searchStore.setState({ filters: filtersById });
}

export function setSearchFiltersByName(filtersByName: SearchFiltersByName) {
  searchStore.setState({ filtersByName });
}

export function setSearchQuery(query: string) {
  searchStore.setState({ query, pageIndex: 0 });
}

export function setSearchSort(sort: SearchSortType) {
  searchStore.setState({ sort, pageIndex: 0 });
}

export function setSearchPageIndex(pageIndex: number) {
  searchStore.setState({ pageIndex });
}

export function setSearchFacetCounts(facetCounts: SearchFacetCounts) {
  searchStore.setState({ facetCounts });
}

let lastSearchTimestamp: number | undefined;
export async function fetchSearchPageResults(
  pageIndex?: number,
  withFacetCounts: boolean = true,
) {
  const filtersByName = getSearchFiltersByName();
  const facets = filtersByName
    ? SEARCH_REFINEMENT_TYPES[filtersByName.type]
    : [];

  searchStore.setState({ isSearching: true });

  const timestamp = new Date().getTime();
  lastSearchTimestamp = timestamp;

  const searchLocation = getSearchLocation();
  const searchRadius = getSearchRadius();

  const facetCountIndexName = ['VOLOP', 'EVENT'].includes(filtersByName.type)
    ? 'volopsIndexName'
    : 'siteIndexName';

  const { locationOptions } =
    searchLocation?.hasGeo &&
    searchLocation?.latitude &&
    searchLocation?.longitude
      ? searchAlgoliaOptionsLocationData(searchRadius, searchLocation)
      : {};

  const { results } = await algoliaSearchMulti<SearchAlgoliaHit>({
    config: searchAlgoliaConfig(),
    queries: [
      {
        query: getSearchQuery(),
        indexName: searchAlgoliaIndexSwitch({
          filtersById: getSearchFiltersById(),
          sort: getSearchSort(),
        }),
        options: {
          ...searchAlgoliaOptionsWithSearchLocation({
            options: searchAlgoliaOptionsSwitch({
              filtersById: getSearchFiltersById(),
              searchLocation,
            }),
            searchLocation,
            radius: searchRadius,
          }),
          page:
            typeof pageIndex === 'undefined' ? getSearchPageIndex() : pageIndex,
        },
      },
      ...(withFacetCounts
        ? facets.map((facet) => ({
            query: getSearchQuery(),
            options: {
              facets: [facet],
              filters: algoliaBuildFilterString({
                ...filtersByName,
                [facet]: undefined,
              }),
              ...locationOptions,
            },
            indexName: configString('algolia', facetCountIndexName),
            analytics: false,
          }))
        : []),
    ],
  });

  const [searchResults, ...facetResults] = results || [];

  // Merge facet counts
  const facetCounts = facetResults.reduce(
    (acc: SearchFacetCounts, cur: SearchResponse<SearchAlgoliaHit>) => ({
      ...acc,
      ...cur.facets,
    }),
    {},
  );

  // Check if a different search ran afterwards
  if (lastSearchTimestamp === timestamp) {
    const { resultsByPage } = searchStore.getState();
    searchStore.setState({
      facetCounts,
      resultsByPage: {
        ...resultsByPage,
        [searchResults.page ?? 0]: searchResults,
      },
      isSearching: false,
    });
  }

  return searchResults;
}
