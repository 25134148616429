import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiHorizonFetchEvent } from 'modules/listing/api/event/listingApiHorizonFetchEvent';
import { UnpublishedEventPageLoadable } from 'modules/listing/pages/events/unpublished/UnpublishedEventPageLoadable';
import type { viewEventReferenceRoute } from 'modules/listing/routing/listing.routes';
import { SearchEventsRefinements } from 'modules/search/pages/Events/components/SearchEventsRefinements';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ViewEventPageLoadable } from './ViewEventPageLoadable';

export const handle = {
  searchRefinements: <SearchEventsRefinements />,
  defaultSearchType: 'EVENT',
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params: { eventIdDashSlug },
}: HorizonAppRouteLoaderArgs<typeof viewEventReferenceRoute>) {
  const [eventId] = eventIdDashSlug.split('-');

  try {
    const [event] = await Promise.all([
      listingApiHorizonFetchEvent({ request, context })(eventId),
      // It's possible this loadable won't be used if the event is unpublished
      // but in most cases optimistically loading it is best
      ViewEventPageLoadable.load(),
    ]);

    switch (event.type) {
      case 'EVENT':
        return {
          type: event.type,
          event,
          handle: {
            hideFooterDivider: 'hide-footer-divider-if-logged-out',
            searchFromPageType: 'LISTING',
            urlByLocale: event.url,
          } satisfies LayoutHandleProps,
        };
      case 'UNPUBLISHED_EVENT':
        await UnpublishedEventPageLoadable.load();
        return {
          type: event.type,
          unpublishedEvent: event,
        };
    }
  } catch {
    return null;
  }
}

export default function ViewEventHorizonRoute({
  loaderData,
}: HorizonAppRouteComponentProps<typeof loader>) {
  switch (loaderData?.type) {
    case undefined:
      return <NotFoundPageLoadable noLayout />;
    case 'EVENT':
      return <ViewEventPageLoadable event={loaderData.event} />;
    case 'UNPUBLISHED_EVENT':
      return (
        <UnpublishedEventPageLoadable
          unpublishedEvent={loaderData.unpublishedEvent}
        />
      );
  }
}
