import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiHorizonFetchOrg } from 'modules/listing/api/org/listingApiFetchOrg';
import { UnpublishedOrgPageLoadable } from 'modules/listing/pages/orgs/unpublished/UnpublishedOrgPageLoadable';
import type { viewOrgReferenceRoute } from 'modules/listing/routing/listing.routes';
import { SearchOrgRefinements } from 'modules/search/pages/Orgs/components/SearchOrgRefinements';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonAppRouteShouldRevalidateFunctionArgs } from 'routing/types/HorizonAppRouteShouldRevalidateFunctionArgs';

import { ViewOrgPageLoadable } from './ViewOrgPageLoadable';

export const handle = {
  searchRefinements: <SearchOrgRefinements />,
  defaultSearchType: 'ORG',
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export function shouldRevalidate({
  currentUrl,
  nextUrl,
}: HorizonAppRouteShouldRevalidateFunctionArgs<typeof viewOrgReferenceRoute>) {
  return currentUrl.pathname !== nextUrl.pathname;
}

export async function loader({
  request,
  context,
  params: { orgIdDashSlug },
}: HorizonAppRouteLoaderArgs<typeof viewOrgReferenceRoute>) {
  // It's possible this loadable won't be used if the job is unpublished
  // but in most cases optimistically loading it is best
  await ViewOrgPageLoadable.load();

  const [orgId] = orgIdDashSlug.split('-');

  const org = await listingApiHorizonFetchOrg({ request, context })(orgId);

  switch (org?.type) {
    case undefined:
      return null;
    case 'ORG':
      return {
        type: 'ORG',
        org,
        handle: {
          hideFooterDivider: 'hide-footer-divider-if-logged-out',
          searchFromPageType: 'LISTING',
          urlByLocale: org.url,
        } satisfies LayoutHandleProps,
      } as const;
    case 'UNPUBLISHED_ORG':
      return {
        type: 'UNPUBLISHED_ORG',
        unpublishedOrg: org,
        handle: {
          hideFooterDivider: 'hide-footer-divider-if-logged-out',
        } satisfies LayoutHandleProps,
      } as const;
  }
}

export default function ViewHorizonRoute({
  loaderData,
}: HorizonAppRouteComponentProps<typeof loader>) {
  switch (loaderData?.type) {
    case undefined:
      return <NotFoundPageLoadable noLayout />;
    case 'ORG':
      if (loaderData.org.isFrozen) {
        return <UnpublishedOrgPageLoadable unpublishedOrg={loaderData.org} />;
      }

      return (
        <ViewOrgPageLoadable
          org={loaderData.org}
          isPublished
          isPreview={false}
        />
      );
    case 'UNPUBLISHED_ORG':
      return (
        <UnpublishedOrgPageLoadable
          unpublishedOrg={loaderData.unpublishedOrg}
        />
      );
  }
}
