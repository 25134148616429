import type { ApiABExperiment } from 'api/abExperiment/types/ApiABExperiment';
import { updateMixpanelExperiments } from 'utils/analytics/helpers/mixpanel';

let experiments: ApiABExperiment[] = [];

export function getAnalyticsAbExperiments() {
  return experiments;
}

/**
 * Assigns multiple experiments - appends if not set yet, replaces if set
 */
export function assignAnalyticsAbExperiments(
  newExperiments: ApiABExperiment[],
) {
  const newExperimentIds = new Set(
    newExperiments.map((experiment) => experiment.id),
  );

  experiments = [
    ...experiments.filter((experiment) => !newExperimentIds.has(experiment.id)),
    ...newExperiments,
  ];

  // The mixpanel properties need to be updated proactively since the user might
  // click somewhere and trigger an event without us calling `trackEvent`
  updateMixpanelExperiments(experiments);
}
