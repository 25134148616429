import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchEvent } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchEvent';
import { orgDashboardPreviewEventRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardPreviewEventPageLoadable } from './OrgDashboardPreviewEventPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: false,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader({
  request,
  context,
  params: { orgId, eventId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardPreviewEventRoute>) {
  const [event] = await Promise.all([
    orgDashboardApiHorizonFetchEvent({ request, context })(orgId, eventId),
    OrgDashboardPreviewEventPageLoadable.load(),
  ]);

  return { event };
}

export default function OrgDashboardPreviewEventHorizonRoute({
  loaderData: { event },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return <OrgDashboardPreviewEventPageLoadable key={event.id} event={event} />;
}
