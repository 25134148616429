import type { SearchLocation } from 'modules/search/types/SearchLocation';

export function levelOptionTitles<TId extends string>(
  levels: Array<{ id: TId; name: string }>,
): Record<TId, string> {
  return levels.reduce(
    (acc, level) => ({ ...acc, [level.id]: [level.name] }),
    {},
  ) as Record<TId, string>;
}

export function hasLocation(s: SearchLocation | null | undefined): boolean {
  return Boolean(s?.hasGeo);
}

export function isLocationEmpty(s: SearchLocation | null | undefined) {
  if (!s) return true;

  const { hasGeo, ...rest } = s;

  return Object.values(rest).every((o) => o === null) && !hasGeo;
}
