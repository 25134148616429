import { create } from 'zustand/index';

import { trackEvent } from 'utils/analytics/track/trackEvent';

const appVersionStore = create<{
  currentVersionNumber: number;
  latestVersionNumber: number;
}>(() => ({
  currentVersionNumber: 1,
  latestVersionNumber: 1,
}));

const useAppVersionStore = appVersionStore; // The store can be used as a hook

// Actions

export function initializeAppVersionStore(versionNumber: number) {
  appVersionStore.setState({
    currentVersionNumber: versionNumber,
    latestVersionNumber: versionNumber,
  });
}

export function setLatestAppVersion(latestVersionNumber: number | undefined) {
  if (!latestVersionNumber) return;

  const state = appVersionStore.getState();

  if (state.latestVersionNumber === latestVersionNumber) return;

  trackEvent('New App Version Detected', {
    old_version: state.currentVersionNumber,
    new_version: latestVersionNumber,
  });

  appVersionStore.setState({ latestVersionNumber });
}

// Hooks

export function useVersionUpdateRequired() {
  const versionUpdateRequired = useAppVersionStore(
    (state) => state.currentVersionNumber !== state.latestVersionNumber,
  );

  return { versionUpdateRequired };
}
