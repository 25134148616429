import { styled } from 'styled-components';

export const ToastListWrapper = styled.div`
  --toast-list-offset: 20px;

  position: fixed;
  bottom: var(--toast-list-offset);
  left: var(--toast-list-offset);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 780px;
  max-width: calc(100vw - var(--toast-list-offset) * 2);
  justify-content: stretch;
  gap: 10px;
`;
