import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UnsubscribePageLoadable } from './UnsubscribePageLoadable';

export async function loader() {
  await UnsubscribePageLoadable.load();
}

export default function UnsubscribeHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UnsubscribePageLoadable />;
}
