import type { ApiOrgId, ApiVolopId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { OrgDashboardApiVolop } from './types/OrgDashboardApiVolop';

type ApiResponse = {
  volop: OrgDashboardApiVolop;
};

function mapResponse(response: ApiResponse): OrgDashboardApiVolop {
  return response.volop;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId, volopId: ApiVolopId) =>
    `/data/orgdashboard/${orgId}/volops/${volopId}`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchVolop = endpoint.createFetchFn();

export const orgDashboardApiHorizonFetchVolop = endpoint.createHorizonFetchFn();

export const useOrgDashboardApiFetchVolop = endpoint.createUseFetchHook();
