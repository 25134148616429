import { styled } from 'styled-components';

import { colors, fontWeights } from 'theme/theme';

export const Label = styled.label`
  font-size: 13px;
  font-weight: ${fontWeights.semiBold};
  color: ${colors.lightContentGrey};
  margin-bottom: 4px;
  align-items: center;
  display: flex;
`;
