export const SEARCH_EVENT_REFINEMENT_TYPES = [
  'areasOfFocus',
  'locationType',
  'locale',
  'radius',
  'source',
  'actionDate',
  'orgType',
  'recency',
] as const;

export type SearchEventRefinementType =
  (typeof SEARCH_EVENT_REFINEMENT_TYPES)[number];
