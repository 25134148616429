import { routeGroup } from 'routing/routes';

export const authLoginRoute = routeGroup.route('login');

export const authSignupRoute = routeGroup.route('signup', ['invite']);

export const authForgotPasswordRoute = routeGroup.route('forgot-password');

export const authUserInviteRoute = routeGroup.route('user-invites/:inviteId');

export const authResetPasswordRoute = routeGroup.route('reset-password/:token');

export const authMfaRoute = routeGroup.route('mfa/:token');
