import { apiHorizonFetchInvite } from 'api/invite/apiHorizonFetchInvite';
import { authSignupRoute } from 'modules/auth/routing/auth.routes';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { SignupPageLoadable } from './SignupPageLoadable';

export async function loader({
  request,
  context,
}: HorizonLoaderFunctionArgs<typeof authSignupRoute>) {
  const { searchParams } = new URL(request.url);

  const inviteId = searchParams.get('invite');
  const [invite] = await Promise.all([
    inviteId
      ? apiHorizonFetchInvite({ request, context })(inviteId)
      : Promise.resolve(null),
    SignupPageLoadable,
  ]);

  return { invite };
}

export default function AuthSignupHorizonRoute({
  loaderData: { invite },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return <SignupPageLoadable invite={invite} />;
}
