import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiHorizonFetchInternship } from 'modules/listing/api/internship/listingApiHorizonFetchInternship';
import { isListingExpired } from 'modules/listing/helpers/isListingExpired';
import { ExpiredInternshipPageLoadable } from 'modules/listing/pages/internships/expired/ExpiredInternshipPageLoadable';
import { UnpublishedInternshipPageLoadable } from 'modules/listing/pages/internships/unpublished/UnpublishedInternshipPageLoadable';
import type { viewInternshipReferenceRoute } from 'modules/listing/routing/listing.routes';
import { SearchInternshipRefinements } from 'modules/search/pages/Internships/components/SearchInternshipRefinements';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ViewInternshipPageLoadable } from './ViewInternshipPageLoadable';

export const handle = {
  searchRefinements: <SearchInternshipRefinements />,
  defaultSearchType: 'INTERNSHIP',
  showDesktopSearch: true,
  hideFooterDivider: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params: { internshipIdDashSlug },
}: HorizonAppRouteLoaderArgs<typeof viewInternshipReferenceRoute>) {
  const [internshipId] = internshipIdDashSlug.split('-');

  try {
    const [internship] = await Promise.all([
      listingApiHorizonFetchInternship({ request, context })(internshipId),
      // It's possible this loadable won't be used if the internship is unpublished
      // but in most cases optimistically loading it is best
      ViewInternshipPageLoadable.load(),
    ]);

    switch (internship.type) {
      case 'INTERNSHIP':
        return {
          type: isListingExpired(internship.expires)
            ? 'EXPIRED_INTERNSHIP'
            : internship.type,
          internship,
          handle: {
            hideFooterDivider: 'hide-footer-divider-if-logged-out',
            searchFromPageType: 'LISTING',
            urlByLocale: internship.url,
          } satisfies LayoutHandleProps,
        } as const;
      case 'UNPUBLISHED_INTERNSHIP':
        await UnpublishedInternshipPageLoadable.load();
        return {
          type: internship.type,
          unpublishedInternship: internship,
        } as const;
    }
  } catch {
    return null;
  }
}

export default function ViewInternshipHorizonRoute({
  loaderData,
}: HorizonAppRouteComponentProps<typeof loader>) {
  switch (loaderData?.type) {
    case undefined:
      return <NotFoundPageLoadable noLayout />;
    case 'INTERNSHIP':
      return <ViewInternshipPageLoadable internship={loaderData.internship} />;
    case 'EXPIRED_INTERNSHIP':
      return (
        <ExpiredInternshipPageLoadable internship={loaderData.internship} />
      );
    case 'UNPUBLISHED_INTERNSHIP':
      return (
        <UnpublishedInternshipPageLoadable
          unpublishedInternship={loaderData.unpublishedInternship}
        />
      );
  }
}
