import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardCartRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardCartContainerLoadable } from './OrgDashboardCartContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardCartRoute>,
) {
  await OrgDashboardCartContainerLoadable.load();
}

export default function OrgDashboardCartHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardCartContainerLoadable />;
}
