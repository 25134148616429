import type { ActionDateFacetValue } from 'modules/search/constants/searchFacets/actionDateFacet';
import type { SearchFacetId } from 'modules/search/types/SearchFacet';
import type { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { camelCaseToSnakeCase } from 'utils/string';

type Args = {
  filtersById: SearchFiltersById;
};

export function searchGetFiltersForAnalytics({ filtersById }: Args) {
  const result: Record<
    string,
    string | string[] | ActionDateFacetValue | number | undefined
  > = {};

  (Object.keys(filtersById) as (SearchFacetId | 'type')[]).forEach(
    (filterKey) => {
      const value = filtersById[filterKey];
      result[`filter_${camelCaseToSnakeCase(filterKey)}`] = value;
    },
  );

  return result;
}
