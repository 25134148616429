import type { ReactNode } from 'react';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';

import { TakeActionDropdownMenu } from './TakeActionDropdownMenu';

type Props = {
  locale: UserLocale;
  renderTrigger: (data: {
    triggerText: string;
    triggerHref: string;
  }) => ReactNode;
  trackClickedContext: string;
};

const triggerTextByLocale: Record<UserLocale, { text: string; href: string }> =
  {
    en: {
      text: 'Take Action',
      href: searchGetVolopsRoutePath({ lang: 'en' }),
    },
    es: {
      text: 'Actúa',
      href: searchGetVolopsRoutePath({ lang: 'es' }),
    },
    pt: {
      text: 'Conectar',
      href: searchGetVolopsRoutePath({ lang: 'pt' }),
    },
  };

export function TakeActionDropdown({
  locale,
  renderTrigger,
  trackClickedContext,
}: Props) {
  const { text, href } = triggerTextByLocale[locale];

  return (
    <Dropdown
      trigger={renderTrigger({ triggerText: text, triggerHref: href })}
      desktopTriggersOnHover
    >
      <TakeActionDropdownMenu
        locale={locale}
        trackClickedContext={trackClickedContext}
      />
    </Dropdown>
  );
}
