import type { SearchFromPageType } from 'modules/search/types/SearchFromPageType';
import {
  getSearchCurrentPageResults,
  getSearchFiltersById,
  getSearchLocation,
  getSearchPageIndex,
  getSearchQuery,
  getSearchRadius,
} from 'modules/search/zustand-stores/searchStore';

import { extractLocationAnalyticsProperties } from './extractLocationAnalyticsProperties';
import { searchGetFiltersForAnalytics } from './searchGetFiltersForAnalytics';

type Args = {
  searchFrom?: string;
  searchFromPageType?: SearchFromPageType;
};

export function searchGetTrackingData({
  searchFrom,
  searchFromPageType,
}: Args) {
  const searchLocation = getSearchLocation();

  if (searchLocation) {
    const results = getSearchCurrentPageResults() || {
      nbHits: 0,
      nbPages: 0,
    };

    const filters = getSearchFiltersById();

    return {
      current_page: getSearchPageIndex() + 1,
      geo_location_enabled: Boolean(searchLocation?.latitude),
      ...searchGetFiltersForAnalytics({ filtersById: filters }),
      search_query: getSearchQuery(),
      search_radius: getSearchRadius(),
      number_of_results: results.nbHits,
      number_of_pages: results.nbPages,
      search_from: searchFrom,
      search_from_page_type: searchFromPageType,
      ...extractLocationAnalyticsProperties(searchLocation),
    };
  }

  return {};
}
