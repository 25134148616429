import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardRepostJobRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardRepostJobPageContainerLoadable } from './OrgDashboardRepostJobPageContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardRepostJobRoute>,
) {
  await OrgDashboardRepostJobPageContainerLoadable.load();
}

export default function OrgDashboardRepostJobHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardRepostJobPageContainerLoadable />;
}
