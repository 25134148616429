import type { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { INTERNSHIP_FUNCTIONS } from 'utils/constants/general/internshipFunctions';

export const internshipKeywordsFacet = {
  id: 'internshipKeywordsFacet',
  name: 'functions',
  get title() {
    return getText('Functions');
  },
  type: 'array',
  options: Object.keys(INTERNSHIP_FUNCTIONS),
  optionTitles: INTERNSHIP_FUNCTIONS,
} as const satisfies SearchFacetData;
