import { localizedRouteGroup, routeGroup } from 'routing/routes';

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const viewJobReferenceRoute = routeGroup.route(
  '-lang/-segment/:jobIdDashSlug',
  ['noSuggestions'],
);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const viewInternshipReferenceRoute = routeGroup.route(
  '-lang/-segment/:internshipIdDashSlug',
);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const viewVolopReferenceRoute = routeGroup.route(
  '-lang/-segment/:volopIdDashSlug',
);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const viewEventReferenceRoute = routeGroup.route(
  '-lang/-segment/:eventIdDashSlug',
);

/**
 * This is not a real route - the route is defined using constants instead of :lang/:segment
 * so that they're more accurate and easier to understand
 */
export const viewOrgReferenceRoute = routeGroup.route(
  '-lang/-segment/:orgIdDashSlug',
);

export const importedListingRoute = localizedRouteGroup.route(
  'imported-action-opps/:source/:volopId',
);
