// Note that we're agnostic of the actual value of the localStorage item, we
import { useEffect } from 'react';

import { useUser } from 'contexts/UserEnvironmentContext';

const LS_KEY = 'idealist:has-previously-signed-in';
/*
  Note: this is different from RECOGNISED and UNRECOGNISED users. Users who sign
  out are considered UNRECOGNISED. This hook doesn't rely on cookies, so it
  persists across sign out/in.

  More details: 

  - https://idealist.slack.com/archives/CHJ9JK7TP/p1737399886677729?thread_ts=1734625890.870049&cid=CHJ9JK7TP
  - https://www.pivotaltracker.com/story/show/188551741
*/
export function useUserHasPreviouslySignedIn() {
  const { user } = useUser();
  useEffect(() => {
    if (!user) return;
    localStorage.setItem(LS_KEY, 'true');
  }, [user]);
  return Boolean(localStorage.getItem(LS_KEY) || false);
}
