import { tz } from '@date-fns/tz';
import {
  endOfDay,
  format,
  fromUnixTime,
  getUnixTime,
  parseISO,
  startOfDay,
  transpose,
} from 'date-fns';

import type { ApiISODateString } from 'api/types/ApiTypedDate';

export const serializeActionDate = (
  timezone: string,
  endsGT?: ApiISODateString,
  startsLT?: ApiISODateString,
) => {
  const serialized: { startsLT?: number; endsGT?: number } = {};

  if (endsGT) {
    serialized.endsGT = getUnixTime(
      transpose(startOfDay(parseISO(endsGT)), tz(timezone)),
    );
  }
  if (startsLT) {
    serialized.startsLT = getUnixTime(
      transpose(endOfDay(parseISO(startsLT)), tz(timezone)),
    );
  }

  return serialized;
};

export const deserializeActionDate = (endsGT?: number, startsLT?: number) => {
  const deserialized: {
    startsLT?: ApiISODateString;
    endsGT?: ApiISODateString;
  } = {};

  if (endsGT) {
    deserialized.endsGT = format(fromUnixTime(endsGT), 'yyyy-MM-dd');
  }
  if (startsLT) {
    deserialized.startsLT = format(fromUnixTime(startsLT), 'yyyy-MM-dd');
  }

  return deserialized;
};
