import { useCallback, useRef } from 'react';

import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';

export function useSearchRefinementDropdown(
  facetType: SearchRefinementType,
  hasSelectedValues: boolean,
) {
  const dropdownIsOpenRef = useRef(false);
  const hasOtherOpenDropdownsRef = useRef(false);

  const holderRef = useRef<HTMLDivElement>(null);

  const dropdownStatusRef = useRef(hasSelectedValues ? 'PINNED' : 'UNPINNED');

  const toggleDropdown = useCallback(
    (open: boolean, detailsEl: HTMLDetailsElement | null) => {
      dropdownIsOpenRef.current = open;

      if (!holderRef.current) {
        throw new Error(
          `Search filter dropdown for ${facetType} does not exist.`,
        );
      }

      const { dataset } = holderRef.current;

      const filterIsPinned = dataset.facetState === 'PINNED';
      const filterIsUnpinned = dataset.facetState === 'UNPINNED';
      const otherOpenDropdowns = Array.from(
        document.querySelectorAll(
          `[data-facet-state] details[open]:not(:has([data-facet-type=${facetType}])`,
        ),
      );

      hasOtherOpenDropdownsRef.current = otherOpenDropdowns.length > 0;

      if (!open) {
        document.documentElement.style.removeProperty(
          '--search-refinements-active-dropdown-height',
        );
        if (hasSelectedValues && filterIsUnpinned) {
          dataset.facetState = 'QUEUED';

          if (!hasOtherOpenDropdownsRef.current) {
            const filtersToPin = document.querySelectorAll<HTMLDivElement>(
              '[data-facet-state="QUEUED"]:has(input:checked:not([value=""])), [data-facet-state="QUEUED"]:has(input[name="startsLT"]:not([value=""]), input[name="endsGT"]:not([value=""]))',
            );

            filtersToPin.forEach((node) => {
              // eslint-disable-next-line no-param-reassign
              node.dataset.facetState = 'PINNED';
            });
          }
        } else if (!hasSelectedValues && filterIsPinned) {
          dataset.facetState = 'QUEUED';

          if (!hasOtherOpenDropdownsRef.current) {
            const filtersToUnpin = document.querySelectorAll<HTMLDivElement>(
              '[data-facet-state="QUEUED"]:not(:has(input:checked:not([value=""]))), [data-facet-state="QUEUED"]:not(:has(input[name="startsLT"]:not([value=""]), input[name="endsGT"]:not([value=""])))',
            );

            filtersToUnpin.forEach((node) => {
              // eslint-disable-next-line no-param-reassign
              node.dataset.facetState = 'UNPINNED';
            });
          }
        }
      } else {
        const menuNode = detailsEl ? detailsEl.querySelector('menu') : null;

        const menuHeight = menuNode
          ? menuNode.getBoundingClientRect().height
          : 0;

        document.documentElement.style.setProperty(
          '--search-refinements-active-dropdown-height',
          `${menuHeight}px`,
        );
      }
    },
    [facetType, hasSelectedValues],
  );

  return {
    holderRef,
    toggleDropdown,
    dropdownStatus: dropdownStatusRef.current,
  };
}
