import { type ReactNode, useEffect } from 'react';

import {
  useEnvironment,
  useUserEnvironmentActions,
} from 'contexts/UserEnvironmentContext';
import { configureTracking } from 'rendering/client/utils/configureTracking';
import { renderingWaitForInitUserEnvironmentToBeSet } from 'rendering/client/utils/renderingWaitForInitUserEnvironmentToBeSet';

import { RenderingClientLocationHooks } from './RenderingClientLocationHooks';

type Props = {
  children: ReactNode;
};

export function RenderingClientInnerWrapper({ children }: Props) {
  const { environment } = useEnvironment();
  const { setUserEnvironment } = useUserEnvironmentActions();

  useEffect(() => {
    if (environment) return;

    // Only get user data from initUserEnvironment if environment is not loaded from SSR
    renderingWaitForInitUserEnvironmentToBeSet().then((apiResponse) => {
      setUserEnvironment({
        user: apiResponse.user,
        environment: apiResponse.environment,
      });

      configureTracking({
        user: apiResponse.user,
        environment: apiResponse.environment,
      });
    });
  }, [environment, setUserEnvironment]);

  return (
    <>
      {children}
      <RenderingClientLocationHooks />
    </>
  );
}
