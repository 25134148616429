import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { SEARCH_REFINEMENT_TYPES } from 'modules/search/constants/refinements/searchRefinementTypes';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';
import type { ListingType } from 'types/ListingType';
import { trackClicked } from 'utils/analytics/track/trackClicked';

export function useSearchRefinements(listingType: ListingType) {
  const [searchParams] = useSearchParams();
  const navigate = useDeferredNavigate();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);
  const searchFiltersByName = useSearchFiltersByName();

  const hasActiveRefinements =
    SEARCH_REFINEMENT_TYPES[listingType].some((refinement) =>
      searchParams.has(refinement),
    ) ||
    (searchFiltersByName &&
      Object.values(searchFiltersByName).some((v) => {
        if (v === searchFiltersByName.type) return false;
        return Array.isArray(v) ? v.length > 0 : v;
      }));

  const clearAllRefinements = useCallback(() => {
    ([...searchParamsRef.current.keys()] as SearchRefinementType[]).forEach(
      (key) => {
        if (SEARCH_REFINEMENT_TYPES[listingType].includes(key)) {
          searchParamsRef.current.delete(key);
        }
      },
    );

    const newRoute = searchGetRoutePathSwitch({
      lang: CURRENT_LOCALE(),
      q: searchParamsRef.current.get('q') || '',
      filtersByName: { type: searchFiltersByName?.type || 'JOB' },
    });

    navigate(newRoute, { replace: true });

    document.querySelectorAll('[data-facet-state="PINNED"]').forEach((node) => {
      node.setAttribute('data-facet-state', 'UNPINNED');

      node.querySelectorAll('input[type="checkbox"]').forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = false;
        }
      });

      node.querySelectorAll('input[type="radio"]').forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = Boolean(
            option.value === '' && option.type === 'radio',
          );
        }
      });
    });

    document
      .querySelectorAll('#overflow-dropdown input[type="checkbox"]')
      .forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = false;
        }
      });

    document
      .querySelectorAll('#overflow-dropdown input[type="radio"]')
      .forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = Boolean(
            option.value === '' && option.type === 'radio',
          );
        }
      });
    trackClicked('Cleared All Search Filters');
  }, [listingType, navigate, searchFiltersByName?.type, searchParamsRef]);

  return {
    clearAllRefinements,
    hasActiveRefinements,
  };
}
