import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import type { CmsApiPagePost } from 'modules/cms/api/types/CmsApiPage';
import { getCmsApiPageDefaultSearchType } from 'modules/cms/helpers/getCmsApiPageDefaultSearchType';
import { getContentLocaleUrl } from 'modules/cms/helpers/getContentLocaleUrl';
import { getHideFooterDivider } from 'modules/cms/helpers/getHideFooterDivider';
import { getIsNoAdPage } from 'modules/cms/helpers/getIsNoAdPage';

type Args = {
  content: CmsApiPagePost;
};

export function getCmsPagePostLayoutHandleProps({
  content,
}: Args): LayoutHandleProps {
  const defaultSearchType = getCmsApiPageDefaultSearchType({ content });

  // banner ads should not be shown on some pages
  const noAds = getIsNoAdPage(content);

  const hideFooterDivider = getHideFooterDivider({ content });
  const localeUrl = getContentLocaleUrl({ content });

  return {
    noAds,
    defaultSearchType,
    hideFooterDivider,
    showDesktopSearch: true,
    urlByLocale: localeUrl,
    searchFromPageType: 'CMS',
  };
}
