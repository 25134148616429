import type { RefObject } from 'react';
import { useEffect } from 'react';

type Args = {
  detailsElRef: RefObject<HTMLDetailsElement>;
  triggerOnHover: boolean;
};

const COLLAPSE_ON_DROPDOWN_HOVER_ATTR = 'data-collapse-on-dropdown-hover';

export const collapseOnDropdownHoverProps = {
  [COLLAPSE_ON_DROPDOWN_HOVER_ATTR]: true,
} as const;

export function useDropdownTriggerOnHover({
  detailsElRef,
  triggerOnHover,
}: Args) {
  useEffect(() => {
    const detailsEl = detailsElRef.current;
    if (!triggerOnHover || !detailsEl) return;

    let leftTimeout: NodeJS.Timeout;

    const onEnter = () => {
      clearTimeout(leftTimeout);
      detailsEl.open = true;

      // Close any other dropdowns that are currently open and were triggered by hover
      // Ensure that only the dropdowns with the `data-collapse-on-dropdown-hover` attribute are considered
      document
        .querySelectorAll<HTMLDetailsElement>(
          `details[open][${COLLAPSE_ON_DROPDOWN_HOVER_ATTR}]`,
        )
        .forEach((el) => {
          if (el !== detailsEl) {
            // Wait just a little bit because when going from one dropdown to another,
            // closing the previous dropdown gives a flickering impression
            setTimeout(() => {
              // eslint-disable-next-line no-param-reassign
              el.open = false;
            }, 25);
          }
        });
    };

    const onLeave = () => {
      leftTimeout = setTimeout(() => {
        detailsEl.open = false;
      }, 200);
    };

    requestAnimationFrame(() => {
      detailsEl.addEventListener('mouseover', onEnter);
      detailsEl.addEventListener('mouseout', onLeave);
    });

    return () => {
      clearTimeout(leftTimeout);

      detailsEl.removeEventListener('mouseover', onEnter);
      detailsEl.removeEventListener('mouseout', onLeave);
    };
  }, [detailsElRef, triggerOnHover]);
}
