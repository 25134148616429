import { userDashboardEditEventRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserDashboardEditEventPageContainerLoadable } from './UserDashboardEditEventPageContainerLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardEditEventRoute>,
) {
  await UserDashboardEditEventPageContainerLoadable.load();
}

export default function UserDashboardEditEventHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <UserDashboardEditEventPageContainerLoadable />;
}
