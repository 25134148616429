import type { IconName } from 'components/Icon/IconName';

import type {
  TakeActionDropdownResourcesItemData,
  TakeActionDropdownSearchItemData,
} from './types/TakeActionDropdownData';

export const takeActionDropdownDataBySearchItemType: Record<
  TakeActionDropdownSearchItemData['type'],
  { iconName: IconName; qaId: string; trackClickedName: string }
> = {
  volop: {
    iconName: 'hand',
    qaId: 'nav-volop-link',
    trackClickedName: 'Volunteer',
  },
  event: {
    iconName: 'star-calendar',
    qaId: 'nav-event-link',
    trackClickedName: 'Attend an Event',
  },
  donations: {
    iconName: 'money',
    qaId: 'nav-donations-link',
    trackClickedName: 'Give Donations',
  },
  petitions: {
    iconName: 'group',
    qaId: 'nav-petitions-link',
    trackClickedName: 'Sign Petitions',
  },
};

export const takeActionDropdownDataByResourcesItemType: Record<
  TakeActionDropdownResourcesItemData['type'],
  { image: string; imageHover: string; qaId: string; trackClickedName: string }
> = {
  'idealist-movement': {
    image: 'illustrations/announce-illustration.svg',
    imageHover: 'illustrations/announce-illustration-hover.svg',
    qaId: 'nav-idealist-movement-link',
    trackClickedName: 'Idealist Movement',
  },
  'knowledge-base': {
    image: 'illustrations/salary-illustration.svg',
    imageHover: 'illustrations/salary-illustration-hover.svg',
    qaId: 'nav-knowledge-base-link',
    trackClickedName: 'Knowledge Base',
  },
  'recipes-for-action': {
    image: 'illustrations/star-illustration.svg',
    imageHover: 'illustrations/star-illustration-hover.svg',
    qaId: 'nav-recipes-for-action-link',
    trackClickedName: 'Recipes For Action',
  },
  blog: {
    image: 'illustrations/article-illustration.svg',
    imageHover: 'illustrations/article-illustration-hover.svg',
    qaId: 'nav-blog-link',
    trackClickedName: 'Blog',
  },
};
