import { redirect } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { getHorizonUserEnvironmentPromiseFromContext } from 'horizon/utils/getHorizonUserEnvironmentPromiseFromContext';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { userDashboardCreateEventRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';

import { UserDashboardCreateEventPageLoadable } from './UserDashboardCreateEventPageLoadable';

export const handle = { variant: 'base' } satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
}: HorizonAppRouteLoaderArgs<typeof userDashboardCreateEventRoute>) {
  if (isRenderingHorizonServer()) {
    const userEnvironment = await getHorizonUserEnvironmentPromiseFromContext({
      request,
      context,
    });

    if (!userEnvironment.user) return redirect('/login');
  }

  await UserDashboardCreateEventPageLoadable.load();
}

export default function UserDashboardCreateEventHorizonRoute() {
  return (
    <RequireAuthentication>
      <UserDashboardCreateEventPageLoadable />
    </RequireAuthentication>
  );
}
