import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import type { SearchVolopFiltersByName } from 'modules/search/types/FiltersByName/SearchVolopFiltersByName';

export type SearchRefinementsWithDateRange = SearchVolopFiltersByName;

export function hasDateRange(
  refinements: SearchFiltersByName,
): refinements is SearchRefinementsWithDateRange {
  return 'endsGT' in refinements && 'startsLT' in refinements;
}
