import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { useSearchAnalytics } from 'modules/search/hooks/useSearchAnalytics';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';

export function useSearchSelectFacetOption(filterType: SearchRefinementType) {
  const [searchParams] = useSearchParams();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);
  const searchFiltersByName = useSearchFiltersByName();

  const navigate = useDeferredNavigate();

  const { getSearchTrackingData } = useSearchAnalytics();

  const select = useCallback(
    (value: string) => {
      const filterSelected = searchParamsRef.current.has(filterType, value);

      searchParamsRef.current.delete(filterType);
      searchParamsRef.current.append(filterType, value);
      document.querySelectorAll(`*[name^=${filterType}]`).forEach((option) => {
        if (option instanceof HTMLInputElement) {
          // eslint-disable-next-line no-param-reassign
          option.checked = Boolean(option.value === value);
        }
      });

      // Toggling a filter should always reset results to the first page
      if (searchParamsRef.current.has('page')) {
        searchParamsRef.current.delete('page');
      }

      trackEvent('Changed Search Filter', {
        filter_name: `${filterType}Facet`,
        [filterSelected ? 'selected_value' : 'deselected_value']: value,
        ...getSearchTrackingData(),
      });

      const newRoute = searchGetRoutePathSwitch({
        lang: CURRENT_LOCALE(),
        q: searchParamsRef.current.get('q') || '',
        filtersByName: {
          ...searchFiltersByName,
          [filterType]: value !== '' ? value : undefined,
        },
      });

      navigate(newRoute, {
        replace: true,
      });
    },
    [
      filterType,
      getSearchTrackingData,
      navigate,
      searchFiltersByName,
      searchParamsRef,
    ],
  );

  return select;
}
