import { styled } from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const SearchRefinementsHeaderHolder = styled.div`
  align-self: center;
  flex: 0 0 84px;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    flex: 0;

    && > button {
      font-size: 14px;
    }
  }
`;
