import { redirect } from 'react-router';

import { RequireAuthentication } from 'containers/RequireAuthentication';
import { getHorizonUserEnvironmentPromiseFromContext } from 'horizon/utils/getHorizonUserEnvironmentPromiseFromContext';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { userDashboardApiHorizonFetchGeneralSettings } from 'modules/userDashboard/api/generalSettings/userDashboardApiFetchGeneralSettings';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { UserDashboardSubLayoutLoadable } from './UserDashboardSubLayoutLoadable';

export const handle = {
  darkBackground: true,
  fallbackUrlByLocaleToCurrent: true,
  searchFromPageType: 'DASHBOARD',
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
}: HorizonLoaderFunctionArgs<void>) {
  if (isRenderingHorizonServer()) {
    const userEnvironment = await getHorizonUserEnvironmentPromiseFromContext({
      request,
      context,
    });

    if (!userEnvironment.user) return redirect('/login');
  }

  const [generalSettings] = await Promise.all([
    userDashboardApiHorizonFetchGeneralSettings({ request, context })(),
    UserDashboardSubLayoutLoadable.load(),
  ]);

  return { generalSettings };
}

export function shouldRevalidate() {
  // Don't revalidate when search params change, as they won't influence this loader
  return false;
}

export default function UserDashboardSubLayoutHorizonRoute({
  loaderData: { generalSettings },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return (
    <RequireAuthentication>
      <UserDashboardSubLayoutLoadable
        initialGeneralSettings={generalSettings}
      />
    </RequireAuthentication>
  );
}
