import { userDashboardPaymentMethodsAddCardRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ApplicationHistoryPageContainerLoadable } from './ApplicationHistoryPageContainerLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardPaymentMethodsAddCardRoute>,
) {
  await ApplicationHistoryPageContainerLoadable.load();
}

export default function UserDashboardApplicationHistoryHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <ApplicationHistoryPageContainerLoadable />;
}
