import { useCallback } from 'react';
import type { NavigateFunction } from 'react-router';
import { useNavigate } from 'react-router';

import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';

/**
 * react-router has a undesired behavior of blocking the main thread by default
 * when navigating
 *
 * That causes our INP to be high (Web Vitals metric), which in turn hurts our SEO ranking.
 *
 * To avoid that, we need to defer the navigation and immediately show a visual feedback to the user,
 * indicating that something is happening.
 */
export function useDeferredNavigate() {
  const navigate = useNavigate();

  const deferredNavigate: NavigateFunction = useCallback(
    (...args) => {
      deferAndStartRouteLoadingAnimation(() => {
        // @ts-expect-error this is correct
        navigate(...args);
      });
    },
    [navigate],
  );

  return deferredNavigate;
}
