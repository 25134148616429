import { authResetPasswordRoute } from 'modules/auth/routing/auth.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ResetPasswordPageContainerLoadable } from './ResetPasswordPageContainerLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof authResetPasswordRoute>,
) {
  await ResetPasswordPageContainerLoadable.load();
}

export default function AuthResetPasswordHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <ResetPasswordPageContainerLoadable />;
}
