import { useSearchRefinementDropdown } from 'modules/search/components/Refinement/Dropdown/useSearchRefinementDropdown';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { useSearchDateRangeSelectedValues } from 'modules/search/hooks/dateRange/useSearchDateRangeSelectedValues';

export function useSearchDateRangeDropdown(facetType: SearchRefinementType) {
  const selectedDateRangeValues = useSearchDateRangeSelectedValues(facetType);

  const hasSelectedDateRangeValues = Boolean(
    selectedDateRangeValues.endsGT || selectedDateRangeValues.startsLT,
  );

  const { holderRef, toggleDropdown, dropdownStatus } =
    useSearchRefinementDropdown(facetType, hasSelectedDateRangeValues);

  return { holderRef, dropdownStatus, toggleDropdown };
}
