import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { LOCALE_CHECKBOX_OPTIONS } from 'utils/constants/general/locales';

import { SearchFacetCheckboxOptions } from './Options/SearchFacetCheckboxOptions';

type Props = {
  variant?: SearchRefinementVariant;
};
export function LocaleFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('locale');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="locale"
      icon="speech-bubble"
      label={
        <>
          {getText('Listing Language')}{' '}
          <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="locale"
        initialValue={selectedFacetValues}
        options={LOCALE_CHECKBOX_OPTIONS()}
      />
    </FacetVariant>
  );
}
