import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiHorizonFetchRsvps } from 'modules/listing/api/event/listingApiFetchRsvps';
import { orgDashboardApiHorizonFetchEvent } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchEvent';
import { orgDashboardViewEventRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardViewEventPageLoadable } from './OrgDashboardViewEventPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader({
  request,
  context,
  params: { orgId, eventId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardViewEventRoute>) {
  const [event, rsvps] = await Promise.all([
    orgDashboardApiHorizonFetchEvent({ request, context })(
      orgId,
      eventId,
    ).catch(() => null),
    listingApiHorizonFetchRsvps({ request, context })(eventId).catch(() => []),
    OrgDashboardViewEventPageLoadable.load(),
  ]);

  if (!event) await NotFoundPageLoadable.load();

  return { event, rsvps };
}

export default function OrgDashboardViewEventHorizonRoute({
  loaderData: { event, rsvps },
}: HorizonAppRouteComponentProps<typeof loader>) {
  if (!event) return <NotFoundPageLoadable noLayout />;

  return (
    <OrgDashboardViewEventPageLoadable
      key={event.id}
      event={event}
      rsvps={rsvps}
    />
  );
}
