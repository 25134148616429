export enum Locale {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}

export const LOCALES = {
  [Locale.EN]: 'English',
  [Locale.ES]: 'Español',
  [Locale.PT]: 'Português',
};

export const LOCALE_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(LOCALES).map(([key, value]) => ({
    value: key as keyof typeof LOCALES,
    label: value,
  }));

export const LOCALE_CHECKBOX_OPTIONS = () =>
  Object.entries(LOCALES).map(([key, value]) => ({
    name: key as keyof typeof LOCALES,
    label: value,
  }));

export const LOCALES_WITH_ALL = {
  ...LOCALES,
  all: 'All',
};

export const LOCALES_WITH_ALL_RADIO_OR_SELECT_OPTIONS = () =>
  Object.entries(LOCALES_WITH_ALL).map(([key, value]) => ({
    value: key as keyof typeof LOCALES_WITH_ALL,
    label: value,
  }));

export const LOCALES_WITH_ALL_CHECKBOX_OPTIONS = () =>
  Object.entries(LOCALES_WITH_ALL).map(([key, value]) => ({
    name: key as keyof typeof LOCALES_WITH_ALL,
    label: value,
  }));
