import type { TakeActionDropdownData } from 'components/TakeActionDropdown/types/TakeActionDropdownData';

import { takeActionDropdownDataEn } from './takeActionDropdownData.en';
import { takeActionDropdownDataEs } from './takeActionDropdownData.es';
import { takeActionDropdownDataPt } from './takeActionDropdownData.pt';

export const takeActionDropdownDataByLocale: Record<
  UserLocale,
  TakeActionDropdownData
> = {
  en: takeActionDropdownDataEn,
  es: takeActionDropdownDataEs,
  pt: takeActionDropdownDataPt,
};
