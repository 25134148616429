import { styled } from 'styled-components';

/*
   Component for visually hiding text that will still be picked up by screen readers.

   Source: https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
*/

export const visuallyHiddenCss = `
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const VisuallyHidden = styled.div`
  ${visuallyHiddenCss}
`;
