import type { MouseEvent } from 'react';
import { styled } from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';
import { H4 } from 'components/Text/H4';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import type { SearchType } from 'modules/search/types/SearchType';
import { bodyTextBase, colors } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import {
  PopularSearchesBody,
  PopularSearchesContainer,
} from './PopularSearches.styles';

const LinkWithIcon = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;

const StyledRouterLink = styled(StyledVersionedRouterLink)`
  color: ${colors.lightContentGrey};
  ${bodyTextBase}
`;

type Props = {
  onSearch: (event: MouseEvent<HTMLElement>, query: string) => void;
  popularSearches: string[];
  selectedType: SearchType;
};

export function PopularSearches({
  popularSearches,
  selectedType,
  onSearch,
}: Props) {
  return (
    <PopularSearchesContainer>
      <PopularSearchesBody>
        <H4 lineHeight={1} mb={0}>
          {getText('Popular searches')}
        </H4>
        {popularSearches.map((ps: string) => (
          <LinkWithIcon key={`${selectedType}-${ps}`}>
            <Icon name="search" colorName="lightContentGrey" size={18} />
            <StyledRouterLink
              data-qa-id="mobile-header-search-link"
              to={searchGetRoutePathSwitch({
                lang: CURRENT_LOCALE(),
                q: ps,
                filtersByName: { type: selectedType },
              })}
              state={{ searchFrom: 'Mobile Search Link' }}
              onClick={(event) => {
                trackClicked('Popular Searches', {
                  search_term: ps,
                  selected_listing_type: selectedType,
                });
                onSearch(event, ps);
              }}
            >
              {ps}
            </StyledRouterLink>
          </LinkWithIcon>
        ))}
      </PopularSearchesBody>
    </PopularSearchesContainer>
  );
}
