import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchVolop } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchVolop';
import { orgDashboardPreviewVolopRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardPreviewVolopPageLoadable } from './OrgDashboardPreviewVolopPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: false,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader({
  request,
  context,
  params: { orgId, volopId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardPreviewVolopRoute>) {
  const [volop] = await Promise.all([
    orgDashboardApiHorizonFetchVolop({ request, context })(orgId, volopId),
    OrgDashboardPreviewVolopPageLoadable.load(),
  ]);

  return { volop };
}

export default function OrgDashboardPreviewVolopHorizonRoute({
  loaderData: { volop },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return <OrgDashboardPreviewVolopPageLoadable key={volop.id} volop={volop} />;
}
