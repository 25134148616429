import type { FindAJobDropdownData } from 'components/FindAJobDropdown/types/FindAJobDropdownData';
import { searchGetInternshipsRoutePath } from 'modules/search/routing/helpers/searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from 'modules/search/routing/helpers/searchGetOrgsRoutePath';

export const findAJobDropdownDataEn: FindAJobDropdownData = {
  search: {
    title: 'Search',
    items: [
      {
        type: 'job',
        title: 'Jobs',
        href: searchGetJobsRoutePath({ lang: 'en' }),
      },
      {
        type: 'internship',
        title: 'Internships',
        href: searchGetInternshipsRoutePath({ lang: 'en' }),
      },
      {
        type: 'org',
        title: 'Organizations',
        href: searchGetOrgsRoutePath({ lang: 'en' }),
      },
    ],
  },
  resources: {
    title: 'Resources',
    items: [
      {
        type: 'salary-explorer',
        title: 'Nonprofit Salary Explorer',
        description:
          'Crowdsourced compensation data in the nonprofit sector. Insights for job seekers and employers.',
        href: '/nonprofit-salary-explorer',
      },
      {
        type: 'career-advice',
        title: 'Career Advice',
        description:
          'Informative and practical resources to help guide you throughout your social-impact career.',
        href: '/en/careers',
      },
    ],
  },
};
