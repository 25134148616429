import { useSearchParams } from 'react-router';

import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';

export function useSearchFacetSelectedValues(
  refinementType: SearchRefinementType,
) {
  const [searchParams] = useSearchParams();
  const searchFiltersFromUrl = searchParams.getAll(refinementType);
  const searchFiltersFromState = useSearchFiltersByName();

  const hasSearchFiltersFromState =
    searchFiltersFromState &&
    Array.isArray(Object.keys(searchFiltersFromState)) &&
    Object.keys(searchFiltersFromState).length > 0 &&
    !(
      Object.keys(searchFiltersFromState).length === 1 &&
      Object.keys(searchFiltersFromState).includes('type')
    );

  if (hasSearchFiltersFromState) {
    // @TODO: make this more type-safe
    const searchFacetSelectedValues =
      searchFiltersFromState[refinementType as keyof SearchFiltersByName] || [];

    return Array.isArray(searchFacetSelectedValues)
      ? searchFacetSelectedValues
      : [searchFacetSelectedValues];
  }

  return searchFiltersFromUrl;
}
