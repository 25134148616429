import type { ApiEventId } from 'api/types/ApiTypedId';
import type { ApiRsvp } from 'modules/listing/api/event/types/ApiRsvpResponse';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  rsvps: ApiRsvp[];
};

function mapResponse(response: ApiResponse) {
  return response.rsvps;
}

const endpoint = new FetchEndpoint({
  urlFactory: (eventId: ApiEventId) => `/data/website/events/${eventId}/rsvps`,
  mapResponse,
});

// Exports

export const listingApiFetchRsvps = endpoint.createFetchFn();

export const listingApiHorizonFetchRsvps = endpoint.createHorizonFetchFn();

export const useListingApiFetchRsvps = endpoint.createUseFetchHook();
