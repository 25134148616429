import { redirect } from 'react-router';

import type { ApiOrgId } from 'api/types/ApiTypedId';
import { RequireAuthentication } from 'containers/RequireAuthentication';
import { getHorizonUserEnvironmentPromiseFromContext } from 'horizon/utils/getHorizonUserEnvironmentPromiseFromContext';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchOrg } from 'modules/orgDashboard/api/orgDashboardApiFetchOrg';
import { orgDashboardRouteGroup } from 'modules/orgDashboard/routing/orgDashboard.routes';
import { isRenderingHorizonServer } from 'rendering/state/renderingState';
import type { AppRouteGroupLoaderArgs } from 'routing/classes/types/AppRouteGroupLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonAppRouteGroupShouldRevalidateFunctionArgs } from 'routing/types/HorizonAppRouteGroupShouldRevalidateFunctionArgs';

import { OrgDashboardOrgSubLayoutLoadable } from './OrgDashboardOrgSubLayoutLoadable';

export function shouldRevalidate({
  currentParams,
  nextParams,
}: HorizonAppRouteGroupShouldRevalidateFunctionArgs<
  typeof orgDashboardRouteGroup
>) {
  return currentParams.orgId !== nextParams.orgId;
}

export const handle = {
  searchFromPageType: 'DASHBOARD',
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params,
}: AppRouteGroupLoaderArgs<typeof orgDashboardRouteGroup>) {
  if (isRenderingHorizonServer()) {
    const userEnvironment = await getHorizonUserEnvironmentPromiseFromContext({
      request,
      context,
    });

    if (!userEnvironment.user) return redirect('/login');
  }

  const orgId = params.orgId as ApiOrgId;

  const [{ org }] = await Promise.all([
    orgDashboardApiHorizonFetchOrg({ request, context })(orgId),
    OrgDashboardOrgSubLayoutLoadable.load(),
  ]);
  return { org };
}

export default function OrgDashboardOrgSubLayoutHorizonRoute({
  loaderData: { org },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return (
    <RequireAuthentication>
      <OrgDashboardOrgSubLayoutLoadable key={org.id} initialOrg={org} />
    </RequireAuthentication>
  );
}
