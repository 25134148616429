import { salariesApiFetchJobSynonym } from 'modules/salaries/api/salariesApiFetchJobSynonym';
import { searchJobsRoutePathByLang } from 'modules/search/routing/search.route-paths';
import type { searchJobsRouteReference } from 'modules/search/routing/search.routes';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonAppRouteShouldRevalidateFunctionArgs } from 'routing/types/HorizonAppRouteShouldRevalidateFunctionArgs';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { SearchJobsPageLoadable } from './SearchJobsPageLoadable';
import { SearchJobsRefinements } from './components/SearchJobsRefinements';

export const handle = {
  searchRefinements: <SearchJobsRefinements />,
  urlByLocale: searchJobsRoutePathByLang,
  defaultSearchType: 'JOB',
  hideFooterDivider: true,
  searchFromPageType: 'SEARCH',
  showDesktopSearch: true,
  isSearchPage: true,
};

export async function loader({
  request,
}: HorizonLoaderFunctionArgs<typeof searchJobsRouteReference>) {
  try {
    const url = new URL(request.url);

    const synonymId = url.searchParams.get('synonymId');

    const [synonym] = await Promise.all([
      synonymId ? salariesApiFetchJobSynonym(synonymId) : Promise.resolve(),
    ]);

    return { synonym };
  } catch {
    // @TODO: Should we log and/or throw an error?
    return { synonym: null };
  }
}

export default function SearchJobsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <SearchJobsPageLoadable />;
}

export function shouldRevalidate({
  currentUrl,
  defaultShouldRevalidate,
  nextUrl,
}: HorizonAppRouteShouldRevalidateFunctionArgs<
  typeof searchJobsRouteReference
>) {
  if (currentUrl.searchParams.size === nextUrl.searchParams.size) {
    return false;
  }

  return defaultShouldRevalidate;
}
