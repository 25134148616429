import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetCheckboxOptions } from 'modules/search/components/Facet/Options/SearchFacetCheckboxOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { INTERNSHIP_FUNCTIONS } from 'utils/constants/general/internshipFunctions';

type Props = {
  variant?: SearchRefinementVariant;
};

export function InternshipKeywordsFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('functions');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="functions"
      icon="alternate-shapes"
      label={
        <>
          {getText('Functions')}{' '}
          <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="functions"
        initialValue={selectedFacetValues}
        options={Object.entries(INTERNSHIP_FUNCTIONS).map(([name, label]) => ({
          name,
          label,
        }))}
        searchable
      />
    </FacetVariant>
  );
}
