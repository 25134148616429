import { styled } from 'styled-components';

import { getStaticImageSrc } from 'utils/getImageSrc';
import { retinaBackgroundImage } from 'utils/style';

export const SpinnerHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const SpinnerImage = styled.div`
  ${() =>
    retinaBackgroundImage(getStaticImageSrc('loading.png'), {
      include3x: false,
    })};
  background-size: contain;
  width: 50px;
  height: 50px;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
