import type { RouteObject } from 'react-router';

import * as orgDashboardAdminsLinksHorizonRouteExports from 'modules/orgDashboard/pages/admins/links/OrgDashboardAdminsLinks.horizon-route';
import * as orgDashboardBuyCreditsLinksHorizonRouteExports from 'modules/orgDashboard/pages/credits/buyLinks/OrgDashboardBuyCreditsLinks.horizon-route';
import * as orgDashboardCreditsLinksHorizonRouteExports from 'modules/orgDashboard/pages/credits/links/OrgDashboardCreditsLinks.horizon-route';
import * as orgDashboardGradFairsLinksHorizonRouteExports from 'modules/orgDashboard/pages/gradFairs/links/OrgDashboardGradFairsLinks.horizon-route';
import * as orgDashboardIntegrateAtsLinksHorizonRoute from 'modules/orgDashboard/pages/integrations/atsLinks/OrgDashboardIntegrateAtsLinks.horizon-route';
import * as orgDashboardInvoicesLinksHorizonRouteExports from 'modules/orgDashboard/pages/invoices/links/OrgDashboardInvoicesLinks.horizon-route';
import * as orgDashboardEditOrgLinksHorizonRouteExports from 'modules/orgDashboard/pages/orgs/editLinks/OrgDashboardEditOrgLinks.horizon-route';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

/**
 * A set of routes that use OrgDashboardOrgSelectionSubLayout
 *
 * These routes are separate in their own file to facilitate maintenance, and
 * they're used on `main.route-objects.tsx`
 */
export const orgSelectRouteObjects = [
  {
    path: '/buy-credits',
    ...horizonRouteToObject(orgDashboardBuyCreditsLinksHorizonRouteExports),
  },
  {
    path: '/credits',
    ...horizonRouteToObject(orgDashboardCreditsLinksHorizonRouteExports),
  },
  {
    path: '/integrate-ats',
    ...horizonRouteToObject(orgDashboardIntegrateAtsLinksHorizonRoute),
  },
  {
    path: '/admins',
    ...horizonRouteToObject(orgDashboardAdminsLinksHorizonRouteExports),
  },
  {
    path: '/edit-org',
    ...horizonRouteToObject(orgDashboardEditOrgLinksHorizonRouteExports),
  },
  {
    path: '/gradfairs',
    ...horizonRouteToObject(orgDashboardGradFairsLinksHorizonRouteExports),
  },
  {
    path: '/invoices',
    ...horizonRouteToObject(orgDashboardInvoicesLinksHorizonRouteExports),
  },
] satisfies RouteObject[];
