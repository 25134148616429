import { useEffect } from 'react';
import { create } from 'zustand';

import { configString } from 'config/appConfigUtils';

const loadCssBundleStore = create<{
  bundles: Record<string, 'loaded' | 'errored'>;
}>(() => ({ bundles: {} }));

const useLoadCssBundleStore = loadCssBundleStore; // The store can be used as a hook

const bundlesLoading: string[] = [];

// Actions

function setCssBundleStatus(name: string, status: 'loaded' | 'errored') {
  const { bundles } = loadCssBundleStore.getState();
  loadCssBundleStore.setState({ bundles: { ...bundles, [name]: status } });
}

function loadCssBundle(name: string) {
  const styleEl = document.createElement('link');
  styleEl.href = `${configString('idealist', 'staticJsPath')}../${name}.css`;
  styleEl.type = 'text/css';
  styleEl.rel = 'stylesheet';
  styleEl.onload = () => setCssBundleStatus(name, 'loaded');
  styleEl.onerror = () => setCssBundleStatus(name, 'errored');

  document.head.appendChild(styleEl);
}

// Hooks

// This store can be removed after migrating to horizon
export function useLoadBundleCssOnce(name: string): {
  cssLoadingStatus: 'loading' | 'loaded' | 'errored';
} {
  const storeStatus = useLoadCssBundleStore((state) =>
    name ? state.bundles[name] : undefined,
  );

  useEffect(() => {
    if (global.Idealist?.horizonEnvironment) return;

    if (typeof storeStatus === 'undefined' && !bundlesLoading.includes(name)) {
      bundlesLoading.push(name);
      loadCssBundle(name);
    }
  }, [name, storeStatus]);

  if (global.Idealist?.horizonEnvironment)
    return { cssLoadingStatus: 'loaded' };

  return { cssLoadingStatus: storeStatus || 'loading' };
}
