import type { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { EVENT_SOURCES } from 'utils/constants/general/eventSources';

export const eventSourceFacet = {
  id: 'eventSourceFacet',
  name: 'source',
  get title() {
    return getText('Source');
  },
  type: 'array',
  options: ['IDEALIST', 'NYCPARKS', 'CITIZENCONNECT'],
  optionTitles: EVENT_SOURCES,
} as const satisfies SearchFacetData;
