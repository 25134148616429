import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Button } from 'components/Button/Button';
import type {
  ToastAction,
  ToastDismissedEventDetail,
  ToastIconName,
  ToastId,
  ToastVariant,
} from 'types/ToastData';

import { ToastIcon } from './Icon/ToastIcon';
import { isToastIconName } from './Icon/isToastIconName';
import { toastIcons } from './Icon/toastIcons';
import {
  DismissButton,
  ToastActions,
  ToastActionsAndContent,
  ToastBody,
  ToastContent,
  ToastHeadline,
  ToastIconWrapper,
  ToastLayout,
  ToastWrapper,
  animationDurationMs,
} from './Toast.styled';
import { ToastFeedbackButton } from './ToastFeedbackButton';
import { useToastOnSwipeDown } from './useToastOnSwipeDown';

type Props = {
  toastId: ToastId;
  headline: string;
  variant: ToastVariant;
  layout: 'vertical' | 'compact';
  // TODO: Fix this from eslint v9 update
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  icon?: ToastIconName | ReactNode;
  body?: string | ReactNode;
  dismissible?: boolean;
  actions?: ToastAction[];
  onDismiss: () => void;
};

export function Toast({
  toastId,
  variant,
  headline,
  body,
  dismissible,
  layout,
  icon,
  actions,
  onDismiss,
}: Props) {
  const isMobile = 'ontouchstart' in window;

  const [isDismissing, setIsDismissing] = useState(false);
  const handleDismiss = useCallback(async () => {
    setIsDismissing(true);
    await new Promise((resolve) => {
      window.setTimeout(resolve, animationDurationMs);
    });
    onDismiss();
  }, [onDismiss]);

  useEffect(() => {
    const handleToastDismissed = (event: Event) => {
      const customEvent = event as CustomEvent<ToastDismissedEventDetail>;
      if (customEvent.detail.id !== toastId) return;
      handleDismiss();
    };
    window.addEventListener('idealist:toast-dismissed', handleToastDismissed);
    return () => {
      window.removeEventListener(
        'idealist:toast-dismissed',
        handleToastDismissed,
      );
    };
  }, [handleDismiss, toastId]);

  const { handlers } = useToastOnSwipeDown({ onSwipeDown: handleDismiss });
  const onSwipeHandlers = isMobile ? handlers : {};

  return (
    <ToastWrapper
      data-qa-id={`toast-${toastId || ''}`}
      $variant={variant}
      $isDismissing={isDismissing}
      $isMobile={isMobile}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...onSwipeHandlers}
    >
      <ToastLayout>
        {icon && (
          <ToastIconWrapper>
            {typeof icon === 'string' && isToastIconName(icon) ? (
              <ToastIcon
                name={icon}
                width={toastIcons[icon].width}
                height={toastIcons[icon].height}
              />
            ) : (
              icon
            )}
          </ToastIconWrapper>
        )}

        <ToastActionsAndContent $layout={layout}>
          <ToastContent>
            <ToastHeadline data-qa-id="toast-headline">
              {headline}
            </ToastHeadline>

            {body && <ToastBody data-qa-id="toast-body">{body}</ToastBody>}
          </ToastContent>

          <ToastActions data-qa-id="toast-actions">
            {actions?.map((action) =>
              action.type === 'button' ? (
                <Button
                  type="button"
                  key={action.label}
                  data-qa-id={`toast-action-${action.label.toLowerCase().replace(/\s+/g, '-')}`}
                  onClick={action.onClick}
                >
                  {action.label}
                </Button>
              ) : (
                <ToastFeedbackButton
                  key={action.id}
                  data-qa-id={`toast-feedback-${action.id}`}
                  onFeedback={action.onFeedback}
                  positiveLabel={action.positiveLabel}
                  negativeLabel={action.negativeLabel}
                />
              ),
            )}
          </ToastActions>
        </ToastActionsAndContent>
      </ToastLayout>

      {dismissible && (
        <DismissButton
          data-qa-id="toast-dismiss-button"
          onClick={handleDismiss}
        />
      )}
    </ToastWrapper>
  );
}
