import { styled } from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import type { IconName } from 'components/Icon/IconName';
import type { Color } from 'theme/theme';

const paddingSize = '12px';

const Container = styled.div<{ $paddingLeft: boolean; $paddingRight: boolean }>`
  line-height: 0;

  ${({ $paddingLeft }) => ($paddingLeft ? `padding-left: ${paddingSize};` : '')}
  ${({ $paddingRight }) =>
    $paddingRight ? `padding-right: ${paddingSize};` : ''}
`;

type Props = {
  iconName: IconName;
  overrideIconColor?: Color;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  size?: number;
};

export function InputIconAffix({
  iconName,
  overrideIconColor,
  noPaddingLeft,
  noPaddingRight,
  size = 18,
}: Props) {
  return (
    <Container $paddingLeft={!noPaddingLeft} $paddingRight={!noPaddingRight}>
      <Icon name={iconName} size={size} colorName={overrideIconColor} />
    </Container>
  );
}
