import type { PropsWithChildren } from 'react';

import { Stack } from 'components/Stack/Stack';
import { useSearchRefinements } from 'modules/search/hooks/refinements/useSearchRefinements';
import { spacing } from 'theme/theme';
import type { ListingType } from 'types/ListingType';

import { SearchRefinementsHeader } from './Header/SearchRefinementsHeader';
import { SearchRefinementsOverflowDropdown } from './OverflowDropdown/SearchRefinementsOverflowDropdown';
import {
  SearchRefinementsBody,
  SearchRefinementsHolder,
} from './SearchRefinements.styled';

type Props = { listingType: ListingType };

export function SearchRefinements({
  children,
  listingType,
}: PropsWithChildren<Props>) {
  const { hasActiveRefinements, clearAllRefinements } =
    useSearchRefinements(listingType);

  return (
    <SearchRefinementsHolder
      data-qa-id="search-refinements"
      $locale={CURRENT_LOCALE()}
      role="search"
      onSubmit={(e) => e.preventDefault()}
    >
      <Stack direction="row" spacing={spacing[24]}>
        <SearchRefinementsHeader
          hasActiveRefinements={hasActiveRefinements}
          onClickClearAll={clearAllRefinements}
        />
        <SearchRefinementsBody className="search-refinements-body">
          {children}
          <SearchRefinementsOverflowDropdown>
            {children}
          </SearchRefinementsOverflowDropdown>
        </SearchRefinementsBody>
      </Stack>
    </SearchRefinementsHolder>
  );
}
