import type { ParsedQuery } from 'query-string';
import queryString from 'query-string';

import type {
  ApiEnvironment,
  ApiUser,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { assignAnalyticsAbExperiments } from 'utils/analytics/abExperiments/analyticsAbExperiments';
import { getUserType } from 'utils/analytics/helpers/getUserType';
import {
  registerMixpanelProperties,
  registerMixpanelPropertiesOnce,
  unregisterMixpanelProperty,
} from 'utils/analytics/helpers/mixpanel';
import { windowDataLayerPush } from 'utils/analytics/helpers/windowDataLayerPush';
import { trackLogin } from 'utils/analytics/track/trackLogin';
import { trackLogout } from 'utils/analytics/track/trackLogout';
import { trackSignUp } from 'utils/analytics/track/trackSignUp';
import { createCookie, getCookieValue } from 'utils/cookies';
import { filterNulls } from 'utils/functional/object';

type AnalyticsQueryParam =
  | 'utm_source'
  | 'utm_medium'
  | 'utm_campaign'
  | 'utm_content'
  | 'utm_term'
  | 'sf_campaign_id';

export function configureAnalyticsUserData({
  environment,
  user,
}: {
  environment: ApiEnvironment;
  user: ApiUser | null;
}) {
  assignAnalyticsAbExperiments(environment.abExperiments);

  if (getCookieValue('justSignedUp')) {
    const method = getCookieValue('justSignedUp');
    trackSignUp(user, method || '');
  }

  if (getCookieValue('justLoggedIn')) {
    const method = getCookieValue('justLoggedIn');
    trackLogin(user, method || '');
  }

  if (getCookieValue('justLoggedOut')) {
    trackLogout();
  }

  if (!user) {
    unregisterMixpanelProperty('user_id');
    registerMixpanelProperties({
      user_type: getUserType(user),
    });
  }

  const params = queryString.parse(
    window.location.search,
  ) as ParsedQuery<AnalyticsQueryParam>;

  const analyticsData = filterNulls({
    locale: CURRENT_LOCALE(),
    user_id: user?.id,
    email_id: params.email_id,
    org_count: user ? user.orgs.length : null,
    user_type: getUserType(user),
  });

  const extraParams = filterNulls({
    utm_source: params.utm_source,
    utm_medium: params.utm_medium,
    utm_campaign: params.utm_campaign,
    utm_content: params.utm_content,
    utm_term: params.utm_term,
    sf_campaign_id: params.sf_campaign_id,
  });

  const extraParamsCookie = getCookieValue('extraParams');
  // These stored "extra" params are currently only used with the
  // donate form, so we want to avoid adding them elsewhere
  // like window.dataLayer.
  const storedExtraParams: Partial<Record<AnalyticsQueryParam, string>> =
    extraParamsCookie ? JSON.parse(extraParamsCookie) : {};

  const extraParamsToStore = filterNulls({
    utm_source: params.utm_source || storedExtraParams.utm_source,
    utm_medium: params.utm_medium || storedExtraParams.utm_medium,
    utm_campaign: params.utm_campaign || storedExtraParams.utm_campaign,
    utm_content: params.utm_content || storedExtraParams.utm_content,
    utm_term: params.utm_term || storedExtraParams.utm_term,
    sf_campaign_id: params.sf_campaign_id || storedExtraParams.sf_campaign_id,
  });

  createCookie('extraParams', JSON.stringify(extraParamsToStore), 7);

  windowDataLayerPush({
    event: 'Full Page Load',
    ...analyticsData,
    ...extraParams,
    logged_in_status: user ? 'Logged In' : 'Not Logged In',
  });

  registerMixpanelPropertiesOnce(analyticsData);
}
