import type { AppConfig } from 'config/types/AppConfig';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { ApiUserEnvironment } from './types/ApiUserEnvironment';

type ApiResponse = ApiUserEnvironment & {
  config: AppConfig;
};

function mapResponse(response: ApiResponse) {
  return response;
}

const endpoint = new FetchEndpoint({
  urlFactory: () => '/data/environment',
  mapResponse,
});

// Exports

export const apiGetFetchUserEnvironmentSsrApiData =
  endpoint.createSsrApiDataFn();

export const apiFetchUserEnvironment = endpoint.createFetchFn();

export const apiHorizonFetchUserEnvironment = endpoint.createHorizonFetchFn();
