import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetCheckboxOptions } from 'modules/search/components/Facet/Options/SearchFacetCheckboxOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { AREAS_OF_FOCUS_CHECKBOX_OPTIONS } from 'utils/constants/general/areasOfFocus';

type Props = {
  variant?: SearchRefinementVariant;
};

export function OrgAreasOfFocusFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('areasOfFocus');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="areasOfFocus"
      icon="globe"
      label={
        <>
          {getText('Cause Area')}{' '}
          <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="areasOfFocus"
        initialValue={selectedFacetValues}
        options={AREAS_OF_FOCUS_CHECKBOX_OPTIONS()}
        searchable
      />
    </FacetVariant>
  );
}
