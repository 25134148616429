import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { TemporaryStorageState } from 'hooks/useTemporaryStorage';
import { useTemporaryStorageHandler } from 'hooks/useTemporaryStorageHandler';
import type { Action } from 'utils/temporaryStorage';

type TemporaryStorageContextType = {
  temporaryStorage: TemporaryStorageState | null;
  saveTemporaryStorage: null | ((actions: Action[]) => Promise<string>);
  loaded: boolean;
};

export const TemporaryStorageContext =
  createContext<TemporaryStorageContextType>({
    temporaryStorage: null,
    saveTemporaryStorage: null,
    loaded: false,
  });

export function TemporaryStorageContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { temporaryStorage, saveTemporaryStorage, loaded } =
    useTemporaryStorageHandler();

  return (
    <TemporaryStorageContext.Provider
      value={{ temporaryStorage, saveTemporaryStorage, loaded }}
    >
      {children}
    </TemporaryStorageContext.Provider>
  );
}

export const useTemporaryStorageContext = () =>
  useContext(TemporaryStorageContext);
