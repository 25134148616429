import { loadableReady } from '@loadable/component';
import type { ComponentType } from 'react';
import type { ErrorInfo } from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client';
import type { RouteObject } from 'react-router';
import { RouterProvider, createBrowserRouter } from 'react-router';

import { apiGetFetchUserEnvironmentSsrApiData } from 'api/userEnvironment/apiFetchUserEnvironment';
import { UserEnvironmentContextProvider } from 'contexts/UserEnvironmentContext';
import { RenderingClientInnerWrapper } from 'rendering/client/components/RenderingClientInnerWrapper';
import { renderingDeserializeHypernovaPayload } from 'rendering/hypernova/client/renderingDeserializeHypernovaPayload';
import { setSsrApiData } from 'rendering/state/ssrApiData';
import { routeObjectsWithRootErrorBoundary } from 'routing/utils/routeObjectsWithRootErrorBoundary';
import { log } from 'utils/logging';

import { configureTracking } from './utils/configureTracking';
import { renderingBootstrapClient } from './utils/renderingBootstrapClient';

type Args = {
  Component: ComponentType<Record<never, never>>;
  routeObjects: RouteObject[];
};

export async function renderSsrClient({ Component, routeObjects }: Args) {
  const payload = renderingDeserializeHypernovaPayload();
  if (!payload) return;

  const {
    node,
    data: { config, ssrApiData },
  } = payload;

  setSsrApiData(ssrApiData);

  // Bootstrap analytics & miscellaneous
  renderingBootstrapClient(config);

  // Setup @loadable
  await loadableReady();

  // Setup react-router & Idealist wrappers
  const userEnvironmentSsrApiData = apiGetFetchUserEnvironmentSsrApiData();

  if (userEnvironmentSsrApiData) {
    configureTracking({
      user: userEnvironmentSsrApiData.user,
      environment: userEnvironmentSsrApiData.environment,
    });
  }

  const wrappedRoutes = [
    {
      element: (
        <UserEnvironmentContextProvider
          initialState={{
            user: userEnvironmentSsrApiData?.user || null,
            environment: userEnvironmentSsrApiData?.environment || null,
          }}
        >
          <RenderingClientInnerWrapper>
            <Component />
          </RenderingClientInnerWrapper>
        </UserEnvironmentContextProvider>
      ),
      children: routeObjectsWithRootErrorBoundary(routeObjects),
    },
  ];

  const router = createBrowserRouter(wrappedRoutes);

  // Hydrate
  hydrateRoot(node, <RouterProvider router={router} />, {
    onRecoverableError: (error: unknown, errorInfo: ErrorInfo) => {
      window.Idealist.hydrationErrors = window.Idealist.hydrationErrors ?? [];
      window.Idealist.hydrationErrors.push({
        error,
        errorInfo,
      });
      if (config.idealist.onHydrationError === 'log-info') {
        log.info(error);
        log.info(errorInfo);
      } else if (config.idealist.onHydrationError === 'log-error') {
        log.error(error);
      } else if (config.idealist.onHydrationError === 'throw') {
        throw error;
      }
    },
  });
}
