import { type ReactNode, createContext, useContext, useRef } from 'react';
import { type StoreApi, type UseBoundStore, create } from 'zustand';

type Args<TState> = {
  fallbackState: TState;
  onCreate?: (store: UseBoundStore<StoreApi<TState>>) => void;
};

export function createZustandContextStore<TState>({
  fallbackState,
  onCreate,
}: Args<TState>) {
  const ZustandStoreContext = createContext<UseBoundStore<StoreApi<TState>>>(
    create(() => fallbackState),
  );

  function ZustandContextProvider({
    initialState,
    children,
  }: {
    initialState: TState;
    children: ReactNode;
  }) {
    const storeRef = useRef<UseBoundStore<StoreApi<TState>>>();

    if (!storeRef.current) {
      storeRef.current = create(() => initialState);
      onCreate?.(storeRef.current);
    }

    return (
      <ZustandStoreContext.Provider value={storeRef.current}>
        {children}
      </ZustandStoreContext.Provider>
    );
  }

  function useZustandContextStore() {
    return useContext(ZustandStoreContext);
  }

  function useZustandContextSelector<TSelectorState>(
    selector: (state: TState) => TSelectorState,
  ) {
    const useStore = useContext(ZustandStoreContext);
    return useStore(selector);
  }

  return {
    ZustandContextProvider,
    useZustandContextStore,
    useZustandContextSelector,
  };
}
