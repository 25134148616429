import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { createSearchParams, useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { trackClicked } from 'utils/analytics/track/trackClicked';

export function useSearchDateRangeReset(refinementType: SearchRefinementType) {
  const navigate = useDeferredNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);

  const resetFacet = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      trackClicked('Reset Search Filter', { filter_type: refinementType });

      searchParamsRef.current.delete('startsLT');
      searchParamsRef.current.delete('endsGT');

      const newSearchParams = createSearchParams(searchParamsRef.current);

      navigate(`?${newSearchParams}`, { replace: true });

      const filterRef = e.currentTarget.closest('[data-facet-type]');

      if (filterRef !== null) {
        filterRef
          .querySelectorAll('input[type="startsLT"], input[type="endsGT"]')
          .forEach((input) => {
            if (input instanceof HTMLInputElement) {
              // eslint-disable-next-line no-param-reassign
              input.value = '';
            }
          });
      }
    },
    [navigate, refinementType, searchParamsRef],
  );

  return resetFacet;
}
