import type { ApiOrgId } from 'api/types/ApiTypedId';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchPaymentSummary } from 'modules/orgDashboard/api/payment/orgDashboardApiFetchPaymentSummary';
import { orgDashboardPaymentSummaryRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardPaymentSummaryPageLoadable } from './OrgDashboardPaymentSummaryPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params,
}: HorizonAppRouteLoaderArgs<typeof orgDashboardPaymentSummaryRoute>) {
  const [report] = await Promise.all([
    orgDashboardApiHorizonFetchPaymentSummary({ request, context })(
      params.orgId as ApiOrgId,
    ),
    OrgDashboardPaymentSummaryPageLoadable.load(),
  ]);

  return { report };
}

export default function OrgDashboardPaymentSummaryHorizonRoute({
  loaderData: { report },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return <OrgDashboardPaymentSummaryPageLoadable report={report} />;
}
