import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardManageAdminsRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardManageAdminsPageContainerLoadable } from './OrgDashboardManageAdminsPageContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardManageAdminsRoute>,
) {
  await OrgDashboardManageAdminsPageContainerLoadable.load();
}

export default function OrgDashboardManageAdminsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardManageAdminsPageContainerLoadable />;
}
