function localStorageAvailable(): boolean {
  return typeof window === 'object' && typeof Storage === 'function';
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setItem(name: string, value: any, json = true) {
  if (!localStorageAvailable()) return undefined;

  try {
    window.localStorage.setItem(name, json ? JSON.stringify(value) : value);
  } catch {
    // swallowing the "this operation is insecure" error from Safari w/ cookies disabled
    return undefined;
  }

  return true;
}

export function getItem(name: string) {
  if (!localStorageAvailable()) return undefined;
  let item;

  try {
    item = window.localStorage.getItem(name);
  } catch {
    // swallowing the "this operation is insecure" error from Safari w/ cookies disabled
    return undefined;
  }

  try {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    return JSON.parse(item);
  } catch {
    return item;
  }
}

export function removeItem(name: string): boolean | null | undefined {
  if (!localStorageAvailable()) return undefined;

  try {
    window.localStorage.removeItem(name);
  } catch {
    // swallowing the "this operation is insecure" error from Safari w/ cookies disabled
    return undefined;
  }

  return true;
}
