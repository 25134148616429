import { create } from 'zustand';

import type { CmsApiSubsiteId } from 'modules/cms/api/types/CmsApiTypedId';

/**
 * Subscribed subsites are not stored in our database.
 *
 * For keeping track of which ones the user has subscribed to before reloading the
 * browser, we keep them stored here
 */
const cmsSubscribedSubsitesStore = create<{
  subscribedSubsiteIds: CmsApiSubsiteId[];
}>(() => ({ subscribedSubsiteIds: [] }));

const useCmsSubscribedSubsitesStore = cmsSubscribedSubsitesStore; // The store can be used as a hook

// Actions

export function cmsSubscribedSubsitesStorePush(subsiteId: CmsApiSubsiteId) {
  cmsSubscribedSubsitesStore.setState((state) => ({
    subscribedSubsiteIds: [...state.subscribedSubsiteIds, subsiteId],
  }));
}

// Hook

export function useCmsSubscribedSubsitesIncludes(subsiteId: CmsApiSubsiteId) {
  const isSubscribed = useCmsSubscribedSubsitesStore((state) =>
    state.subscribedSubsiteIds.includes(subsiteId),
  );

  return { isSubscribed };
}
