import { postJson } from 'utils/http/postJson';

import type {
  ApiSavedSearchUnsaved,
  UserDashboardApiSavedSearch,
} from './types/UserDashboardApiSavedSearch';

export async function userDashboardApiCreateSavedSearch({
  text,
  filters,
  location,
  index,
  radius,
}: ApiSavedSearchUnsaved): Promise<UserDashboardApiSavedSearch> {
  return postJson<{ savedSearch: UserDashboardApiSavedSearch }>(
    '/data/saved-searches',
    {
      text,
      filters,
      radius,
      location,
      index,
    },
  ).then(({ savedSearch }) => savedSearch);
}
