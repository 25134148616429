import type { ForwardedRef, PropsWithChildren } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import type { Spacing } from 'theme/theme';
import { spacing } from 'theme/theme';

import { ScrollAreaHolder, ScrollAreaViewport } from './ScrollArea.styled';

export type ScrollAreaHandle = {
  holderElement: HTMLDivElement | null;
  viewportElement: HTMLDivElement | null;
};

type Props = {
  direction: 'vertical' | 'horizontal';
  height?: string;
  hideScrollBar?: boolean;
  qaId?: string;
  width?: string;
  scrollShadowSize?: Spacing;
  showScrollShadowEnd?: boolean;
  showScrollShadowStart?: boolean;
};

// TODO: Fix this from eslint v9 update
// eslint-disable-next-line react/display-name
export const ScrollArea = forwardRef(
  (
    {
      children,
      direction,
      height = 'inherit',
      hideScrollBar = false,
      qaId,
      width = 'inherit',
      scrollShadowSize = spacing[30],
      showScrollShadowEnd,
      showScrollShadowStart,
    }: PropsWithChildren<Props>,
    ref: ForwardedRef<ScrollAreaHandle>,
  ) => {
    const holderRef = useRef<HTMLDivElement>(null);
    const viewportRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      holderElement: holderRef.current,
      viewportElement: viewportRef.current,
    }));

    return (
      <ScrollAreaHolder ref={holderRef} $direction={direction}>
        <ScrollAreaViewport
          ref={viewportRef}
          data-qa-id={qaId}
          $direction={direction}
          $height={height}
          $hideScrollBar={hideScrollBar}
          $width={width}
          $scrollShadowSize={scrollShadowSize}
          $showScrollShadowStart={showScrollShadowStart}
          $showScrollShadowEnd={showScrollShadowEnd}
        >
          {children}
        </ScrollAreaViewport>
      </ScrollAreaHolder>
    );
  },
);
