import { userDashboardNotificationPreferencesRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { NotificationPreferencesPageLoadable } from './NotificationPreferencesPageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<
    typeof userDashboardNotificationPreferencesRoute
  >,
) {
  await NotificationPreferencesPageLoadable.load();
}

export default function UserDashboardNotificationPreferencesHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <NotificationPreferencesPageLoadable />;
}
