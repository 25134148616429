/* eslint-disable no-underscore-dangle */

if (typeof window !== 'undefined' && window.__webpack_public_path__) {
  // Note: this is a webpack-specific notation and should not be touched.
  // @ts-expect-error TS(2304): Cannot find name '__webpack_public_path__'.
  // eslint-disable-next-line prefer-destructuring
  __webpack_public_path__ = window.__webpack_public_path__;
}

// We must treat this file as an export because isolateModules is set
export {};
/* eslint-enable no-underscore-dangle */
