import type { FindAJobDropdownData } from 'components/FindAJobDropdown/types/FindAJobDropdownData';
import { searchGetInternshipsRoutePath } from 'modules/search/routing/helpers/searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from 'modules/search/routing/helpers/searchGetOrgsRoutePath';

export const findAJobDropdownDataPt: FindAJobDropdownData = {
  search: {
    title: 'Busca',
    items: [
      {
        type: 'job',
        title: 'Empregos',
        href: searchGetJobsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'internship',
        title: 'Estágios',
        href: searchGetInternshipsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'org',
        title: 'Organizações',
        href: searchGetOrgsRoutePath({ lang: 'pt' }),
      },
    ],
  },
  resources: {
    title: 'Recursos',
    items: [
      {
        type: 'career-advice',
        title: 'Blog Carreira de Impacto',
        description:
          'Inspirações e dicas para guiar sua jornada profissional no setor social.',
        href: '/pt/carreira-impacto',
      },
    ],
  },
};
