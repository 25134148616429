import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { create } from 'zustand';

import { useUser } from 'contexts/UserEnvironmentContext';
import { getItem } from 'utils/localStorage';

const STORAGE_KEY = 'idealist:unsigned-page-visit-count';

// Persist between unmounts
let lastPathname = '';

type PageVisitStore = {
  count: number;
  setCount: (count: number) => void;
};

const usePageVisitStore = create<PageVisitStore>((set) => ({
  count: parseInt(getItem(STORAGE_KEY) || '0', 10),
  setCount: (count: number) => {
    localStorage.setItem(STORAGE_KEY, count.toString());
    set({ count });
  },
}));

/* 
  Return the number of page impressions for unsigned users.
*/
export function useUnsignedPageVisitCounter() {
  const location = useLocation();
  const { count, setCount } = usePageVisitStore();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      setCount(0);
      return;
    }

    if (lastPathname !== location.pathname) {
      setCount(count + 1);
      lastPathname = location.pathname;
    }
  }, [location.pathname, count, setCount, user?.id, user]);

  const resetVisitCount = useCallback(() => {
    setCount(0);
  }, [setCount]);

  const getVisitCount = useCallback(() => count, [count]);

  return {
    getVisitCount,
    resetVisitCount,
  };
}
