import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';

import { algoliaQueryStringArrayFilter } from './algoliaQueryStringArrayFilter';
import { algoliaQueryStringFilter } from './algoliaQueryStringFilter';

export function algoliaBuildFilterString(filters: SearchFiltersByName): string {
  const validFilters = Object.entries(filters).filter(([_, value]) =>
    Array.isArray(value) ? value.length > 0 : !['', undefined].includes(value),
  );

  const filterStrings = validFilters.map(([filter, value]) => {
    if (Array.isArray(value)) {
      return algoliaQueryStringArrayFilter(filter, value);
    }
    return algoliaQueryStringFilter(filter, value);
  });

  // @NOTE - Remove this when `actionType` is not longer on the back-end
  // This is used to filter PETITION and DONATION which are `actionType`
  // records we do not want included in our search results
  if (filters.type === 'VOLOP') {
    filterStrings.push(algoliaQueryStringFilter('actionType', 'VOLOP'));
  }

  return filterStrings.join(' AND ');
}
