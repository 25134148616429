import { LocalNotificationStatus } from 'types/LocalNotification/LocalNotificationStatus';
import type { LocalNotificationType } from 'types/LocalNotification/LocalNotificationType';
import { getLocalNotification } from 'zustand-stores/localNotificationsStore';

export function isNotificationDismissed(
  notificationType: LocalNotificationType,
) {
  const localNotification = getLocalNotification(notificationType);
  return localNotification?.status === LocalNotificationStatus.DISMISSED;
}
