import type { PropsWithChildren, ReactNode } from 'react';
import { Children, Fragment, cloneElement, isValidElement } from 'react';

import type { Spacing, breakpointValues } from 'theme/theme';

import { StackHolder } from './Stack.styled';

type Props = {
  divider?: ReactNode;
  direction:
    | 'row'
    | 'column'
    | Partial<Record<keyof typeof breakpointValues, 'row' | 'column'>>;
  spacing?: Spacing | Partial<Record<keyof typeof breakpointValues, Spacing>>;
  'data-qa-id'?: string;
};

export function Stack({
  children,
  divider,
  direction,
  spacing,
  'data-qa-id': qaId,
}: PropsWithChildren<Props>) {
  const childrenArray = Children.toArray(children);

  return (
    <StackHolder data-qa-id={qaId} $direction={direction} $spacing={spacing}>
      {childrenArray.reduce<ReactNode[]>((acc, child, index) => {
        const childKey =
          isValidElement(child) && child.key ? child.key : `child-${index}`;

        if (index > 0 && divider) {
          const dividerKey = `divider-${childKey}`;
          const dividerElement = isValidElement(divider) ? (
            cloneElement(divider, { key: dividerKey })
          ) : (
            <Fragment key={dividerKey}>{divider}</Fragment>
          );

          acc.push(dividerElement);
        }

        acc.push(child);

        return acc;
      }, [])}
    </StackHolder>
  );
}
