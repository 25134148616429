import { useMemo } from 'react';

import { useAbExperimentBucket } from 'hooks/abExperiment/useAbExperimentBucket';
import { LocaleFacet } from 'modules/search/components/Facet/LocaleFacet';
import { OrgAreasOfFocusFacet } from 'modules/search/components/Facet/OrgAreasOfFocusFacet';
import { OrgTypeFacet } from 'modules/search/components/Facet/OrgTypeFacet';
import { RadiusFacet } from 'modules/search/components/Facet/RadiusFacet';
import { SearchRefinements } from 'modules/search/components/Refinements/SearchRefinements';

export function SearchOrgRefinements() {
  const [bucketValue] = useAbExperimentBucket('NEW_SEARCH_2025_03');

  const refinementsByLocale = useMemo(
    () => ({
      en: [OrgAreasOfFocusFacet, OrgTypeFacet, LocaleFacet, RadiusFacet],
      es: [LocaleFacet, OrgAreasOfFocusFacet, OrgTypeFacet, RadiusFacet],
      pt: [OrgAreasOfFocusFacet, LocaleFacet, OrgTypeFacet, RadiusFacet],
    }),
    [],
  );

  return bucketValue === 'ENABLED' ? (
    <SearchRefinements listingType="JOB">
      {refinementsByLocale[CURRENT_LOCALE()].map((Component) => (
        <Component key={Component.name} />
      ))}
    </SearchRefinements>
  ) : null;
}
