import { css, styled } from 'styled-components';

import type { DropdownMenuPlacement } from 'components/Dropdown/types/DropdownMenuPlacement';
import { colors, cssBreakpoints, shadows } from 'theme/theme';

import {
  dropdownMenuOffsetX,
  dropdownMenuOffsetY,
  dropdownMenuWidth,
} from './dropdownMenuCssVariables';

const menuContainerPlacementStyles: Record<
  DropdownMenuPlacement,
  ReturnType<typeof css>
> = {
  'top-left': css`
    bottom: 100%;
    right: 0;
    margin-left: var(${dropdownMenuOffsetX});
    margin-bottom: var(${dropdownMenuOffsetY});
  `,
  'top-right': css`
    bottom: 100%;
    margin-left: var(${dropdownMenuOffsetX});
    margin-bottom: var(${dropdownMenuOffsetY});
    left: 0;
  `,
  'bottom-left': css`
    top: 100%;
    margin-left: var(${dropdownMenuOffsetX});
    margin-top: var(${dropdownMenuOffsetY});
    right: 0;
  `,
  'bottom-right': css`
    margin-left: var(${dropdownMenuOffsetX});
    margin-top: var(${dropdownMenuOffsetY});
    top: 100%;
    left: 0;
  `,
};

export const DropdownMenuHolder = styled.menu<{
  $placement: DropdownMenuPlacement;
  $mobilePlacement: DropdownMenuPlacement;
}>`
  position: absolute;
  border: 1px solid ${colors.calloutGrey};
  box-shadow: ${shadows.toolbar};
  background: ${colors.white};
  border-radius: 8px 0 8px 8px;
  margin: 0;
  padding: 0;
  z-index: 99;
  width: var(${dropdownMenuWidth}, max-content);

  @media all and (max-width: ${cssBreakpoints.mdUp}) {
    ${(props) => menuContainerPlacementStyles[props.$mobilePlacement]};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    ${(props) => menuContainerPlacementStyles[props.$placement]};
  }
`;
