import queryString from 'query-string';

import { filterNulls, objectEmpty } from 'utils/functional/object';

function trimTrailingSlash(s: string) {
  return s.replace(/\/$/, '');
}

export function pathWithParams(
  path: string | null | undefined,
  params: Readonly<Record<string, string | null | undefined>>,
) {
  if (!path) return '';
  if (objectEmpty(params)) return path;
  let splitPath;
  let hash = '';

  if (path.includes('#')) {
    splitPath = path.split('#');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line prefer-destructuring
    hash = splitPath[1];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    path = splitPath[0];
  }

  splitPath = path.split('?');
  const trimmedPath = trimTrailingSlash(splitPath[0]);
  const [, search] = splitPath;
  return `${trimmedPath}?${queryString.stringify({
    ...filterNulls(params),
    ...queryString.parse(search),
  })}${hash ? `#${hash}` : ''}`;
}
