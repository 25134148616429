import { Box } from 'components/Box/Box';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { colors, fontWeights } from 'theme/theme';

import type { LocationSuggestion } from './LocationSuggestion';

type Props = {
  suggestion: LocationSuggestion;
};

export function LocationInputSuggestion({ suggestion }: Props) {
  return (
    <Box className="idlst-google-suggestion" display="flex" alignItems="center">
      <Box mr="5px">
        <Icon
          name={suggestion.isRemoteOption ? 'house' : 'location'}
          size={18}
        />
      </Box>

      <Box display="flex" alignItems="baseline" overflow="hidden">
        <Text
          styleAs="bodyMicro"
          fontWeight={fontWeights.semiBold}
          mr="5px"
          $whiteSpace="nowrap"
        >
          {suggestion.structured_formatting.main_text}
        </Text>
        <Text
          styleAs="bodyMicro"
          color={colors.lightContentGrey}
          $whiteSpace="nowrap"
        >
          {suggestion.structured_formatting.secondary_text}
        </Text>
      </Box>
    </Box>
  );
}
