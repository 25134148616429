import type {
  ApiABExperimentBucketForId,
  ApiABExperimentId,
} from 'api/abExperiment/types/ApiABExperiment';
import { configValue } from 'config/appConfigUtils';
import type { AppConfigABExperiment } from 'config/types/AppConfig';
import { useEnvironment } from 'contexts/UserEnvironmentContext';
import { log } from 'utils/logging';

import { getABExperimentAssignmentBucketId } from './getABExperimentAssignmentBucketId';

const bucketIdsAssignedOnTheFrontend: {
  [key in ApiABExperimentId]: ApiABExperimentBucketForId<key>;
} = {};

/**
 * Hook to get bucket for experimentId if environment is ready. The response
 * is typed such that it can be processed by `useABExperimentBucket` properly.
 *
 * This hook is not meant to be consumed outside `useABExperimentBucket`
 *
 * @internal Meant to be used inside `useABExperimentBucket`
 */
export function useInternalABExperimentBucketAssignedValue<
  TExperimentId extends ApiABExperimentId,
>(
  experimentId: TExperimentId,
):
  | { type: 'environment'; bucketId: ApiABExperimentBucketForId<TExperimentId> }
  | {
      type: 'assigned-on-frontend';
      bucketId: ApiABExperimentBucketForId<TExperimentId>;
    }
  | undefined {
  const { environment } = useEnvironment();
  if (!environment) return;

  // Attempt to get the experiment from the environment
  const environmentExperiment = environment.abExperiments.find(
    (experiment) => experiment.id === experimentId,
  );

  if (environmentExperiment)
    return { type: 'environment', bucketId: environmentExperiment.bucket };

  // If not in the environment, assign a new bucket
  const abExperimentsConfig = configValue(
    'idealist',
    'abExperiments',
  ) as unknown as AppConfigABExperiment[];

  const experimentConfig = abExperimentsConfig.find(
    (experiment) => experiment.id === experimentId,
  );

  if (!experimentConfig) {
    log.error(
      `Unable to find experiment config for experimentId "${experimentId}"`,
    );

    return;
  }

  const bucketId =
    bucketIdsAssignedOnTheFrontend[experimentId] ||
    getABExperimentAssignmentBucketId({
      buckets: experimentConfig.buckets,
    });

  bucketIdsAssignedOnTheFrontend[experimentId] = bucketId;
  return { type: 'assigned-on-frontend', bucketId };
}
