import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetCheckboxOptions } from 'modules/search/components/Facet/Options/SearchFacetCheckboxOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { LOCATION_TYPE_CHECKBOX_OPTIONS } from 'utils/constants/general/locationType';

type Props = {
  variant?: SearchRefinementVariant;
};

export function LocationTypeFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('locationType');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="locationType"
      icon="desk"
      label={
        <>
          {getText('Remote')} <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="locationType"
        initialValue={selectedFacetValues}
        options={LOCATION_TYPE_CHECKBOX_OPTIONS()}
      />
    </FacetVariant>
  );
}
