import { useUnsignedPageVisitCounter } from 'hooks/usePageVisitCounter';
import { useQueuedTrackers } from 'hooks/useQueuedTrackers';
import { useRouterHashScroll } from 'hooks/useRouterHashScroll';
import { useShowUserModals } from 'hooks/useShowUserModals';
import { useTrackNavigationState } from 'hooks/useTrackNavigationState';
import { useSetClientHydrated } from 'rendering/client/useSetClientHydrated';

import { useOverrideABExperimentsWithUrlParams } from './rendering/client/useOverrideABExperimentsWithUrlParams';
import { useUpdateSearchLocationFromLocalStorage } from './rendering/client/useUpdateSearchLocationFromLocalStorage';

/*
 * Wrap app hooks to prevent them from re-rendering the whole tree
 */
export function AppHooks() {
  useRouterHashScroll();
  useQueuedTrackers();
  useTrackNavigationState();
  useShowUserModals();
  useUnsignedPageVisitCounter();

  useSetClientHydrated();
  useUpdateSearchLocationFromLocalStorage();
  useOverrideABExperimentsWithUrlParams();

  return null;
}
