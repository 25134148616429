import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchJob } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchJob';
import { orgDashboardApiHorizonFetchListingApplications } from 'modules/orgDashboard/api/listingApplication/orgDashboardApiFetchListingApplications';
import { orgDashboardApiHorizonFetchRecommendedCandidates } from 'modules/orgDashboard/api/suggestedApplicants/orgDashboardApiFetchRecommendedCandidates';
import { orgDashboardViewJobRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonAppRouteShouldRevalidateFunctionArgs } from 'routing/types/HorizonAppRouteShouldRevalidateFunctionArgs';

import { OrgDashboardViewJobPageLoadable } from './OrgDashboardViewJobPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export function shouldRevalidate({
  currentParams,
  nextParams,
}: HorizonAppRouteShouldRevalidateFunctionArgs<
  typeof orgDashboardViewJobRoute
>) {
  return currentParams.jobId !== nextParams.jobId;
}

export async function loader({
  request,
  context,
  params: { orgId, jobId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardViewJobRoute>) {
  const [job, listingApplications, recommendedCandidates] = await Promise.all([
    orgDashboardApiHorizonFetchJob({ request, context })(orgId, jobId).catch(
      () => null,
    ),
    orgDashboardApiHorizonFetchListingApplications({ request, context })(
      orgId,
      { type: 'JOB', id: jobId },
    ),
    orgDashboardApiHorizonFetchRecommendedCandidates({ request, context })(
      orgId,
      jobId,
    ).catch(() => null),
    OrgDashboardViewJobPageLoadable.load(),
  ]);

  if (!job) await NotFoundPageLoadable.load();

  return { job, listingApplications, recommendedCandidates };
}

export default function OrgDashboardViewJobHorizonRoute({
  loaderData: { job, listingApplications, recommendedCandidates },
}: HorizonAppRouteComponentProps<typeof loader>) {
  if (!job) return <NotFoundPageLoadable noLayout />;

  return (
    <OrgDashboardViewJobPageLoadable
      key={job.id}
      job={job}
      listingApplications={listingApplications}
      recommendedCandidates={recommendedCandidates || []}
    />
  );
}
