import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { useUserEnvironment } from 'contexts/UserEnvironmentContext';
import { useRouteIsCmsApiPage } from 'modules/cms/hooks/useRouteIsCmsApiPage';
import { exploreSalaryRoute } from 'modules/salaries/routing/salaries.routes';
import { userDashboardApiEnableProfileFeatures } from 'modules/userDashboard/api//userDashboardApiEnableProfileFeatures';
import { userDashboardApiSavePostingIntention } from 'modules/userDashboard/api/userDashboardApiSaveIntakeIntents';
import { deleteCookie, getCookieValue } from 'utils/cookies';
import { onHomepage } from 'utils/intakeintents';
import { hideModal, showModal, useModal } from 'zustand-stores/modalStore';
import { usePreSignupComment } from 'zustand-stores/preSignupCommentStore';
import { usePreSignupFollow } from 'zustand-stores/preSignupFollowStore';
import { usePreSignupReaction } from 'zustand-stores/preSignupReactionStore';
import { usePreSignupRsvp } from 'zustand-stores/preSignupRsvpStore';

// Only users created after this date should see
// the intake intents modal
const INTAKE_INTENTS_START_DATE = new Date('2023-08-15');

// Routes that should not have user modals appear
const DISABLE_USER_MODAL_ROUTES = [exploreSalaryRoute.fullPath] as string[];

export function useShowUserModals() {
  const { user, isUserLoaded } = useUserEnvironment();

  const location = useLocation();

  const { modal } = useModal();
  const currentModalType = modal?.type;

  const {
    created,
    email,
    emailVerified,
    firstName,
    intakeIntentsDatetimeSet,
    lastName,
    orgs,
    showNotifPrefModal,
    hasIdealistProfileFeature,
    hasPublishedIdealistProfile,
  } = user || {};

  const profileWizardModalAlreadyShownRef = useRef(false);
  const routeCmsApiPage = useRouteIsCmsApiPage();
  const { preSignupReaction } = usePreSignupReaction();
  const { hasPreSignupComment } = usePreSignupComment();
  const { preSignupFollowUserId } = usePreSignupFollow();
  const { preSignupRsvpAnswer, preSignupRsvpEventId } = usePreSignupRsvp();

  const verify = useCallback(() => {
    const justSignedUpModalCookie = getCookieValue('justSignedUpModal');
    const userDismissedNotificationPreferencesCookie = getCookieValue(
      'userDismissedNotificationPreferencesDialog',
    );

    const isAccountSettingsPage =
      location.pathname === '/dashboard/account-settings';
    const isLoginOrSignupPage = ['signup', '/login'].includes(
      location.pathname,
    );
    const isIOSMobileApp = window.navigator.userAgent.includes(
      'Idealist Mobile App',
    );
    const noModalPresent = !currentModalType;
    const informationModalPresent =
      currentModalType === 'INFORMATION_REQUIRED' && noModalPresent;

    // INFORMATION_REQUIRED expected for case of Facebook user not allowing
    // their email address and then has just filled one in.
    const emailNeedsVerification =
      Boolean(email) &&
      !emailVerified &&
      !justSignedUpModalCookie &&
      (noModalPresent || informationModalPresent);

    const finishUserProfile =
      !hasPublishedIdealistProfile &&
      ((preSignupReaction &&
        routeCmsApiPage &&
        preSignupReaction.postId === routeCmsApiPage.id) ||
        hasPreSignupComment ||
        preSignupFollowUserId ||
        (preSignupRsvpAnswer && preSignupRsvpEventId));

    const informationRequired =
      (!firstName || !lastName || !email) &&
      !isAccountSettingsPage &&
      noModalPresent;

    const showSignupModal =
      isIOSMobileApp &&
      !isLoginOrSignupPage &&
      noModalPresent &&
      !user &&
      isUserLoaded;

    const showIntakeIntentModal =
      created &&
      new Date(created) > INTAKE_INTENTS_START_DATE &&
      !intakeIntentsDatetimeSet &&
      noModalPresent &&
      onHomepage(location.pathname);

    const shouldFinishOAuth = justSignedUpModalCookie && noModalPresent;

    const showUpdateNotificationPreferencesModal =
      showNotifPrefModal &&
      noModalPresent &&
      !userDismissedNotificationPreferencesCookie;

    if (showSignupModal) {
      showModal('SIGNUP', { uncloseable: true });
      return;
    }

    if (emailNeedsVerification) {
      showModal('VERIFY_EMAIL', {});
      return;
    }

    // If verification email persists in another browser tab after
    // verification, close it
    // @NOTE: Polling occurs in VerifyEmailModal
    if (currentModalType === 'VERIFY_EMAIL' && emailVerified) {
      hideModal();
      return;
    }

    if (
      finishUserProfile &&
      emailVerified &&
      currentModalType !== 'CREATE_USER_PROFILE'
    ) {
      if (!hasIdealistProfileFeature) {
        userDashboardApiEnableProfileFeatures().then(() => {
          showModal('CREATE_USER_PROFILE', {});
        });
      } else {
        showModal('CREATE_USER_PROFILE', {});
      }
      return;
    }

    if (informationRequired) {
      showModal('INFORMATION_REQUIRED', {});
      return;
    }

    if (shouldFinishOAuth) {
      if (
        ['Google', 'Facebook', '"Apple ID"'].includes(justSignedUpModalCookie)
      ) {
        showModal('FINISH_OAUTH_SIGNUP', {
          onClose: () => deleteCookie('justSignedUpModal'),
        });
      } else {
        deleteCookie('justSignedUpModal');
      }

      return;
    }

    if (showUpdateNotificationPreferencesModal) {
      showModal('NOTIFICATION_PREFERENCES', {});
      return;
    }

    if (showIntakeIntentModal) {
      // If an org has signed up via post a listing,
      // automatically save their posting intention
      // once they have created an organization
      if (orgs && orgs.length > 0) {
        userDashboardApiSavePostingIntention({
          intakeIntents: ['POST_JOB_OR_OTHER_OPP'],
        });
      } else {
        showModal('INTAKE_INTENTS', {});
        return;
      }
    }

    if (
      noModalPresent &&
      user?.hasIdealistProfileFeature &&
      !user.hasPublishedIdealistProfile &&
      onHomepage(location.pathname) &&
      user.showIdealistProfileOnboarding !== false &&
      !profileWizardModalAlreadyShownRef.current
    ) {
      profileWizardModalAlreadyShownRef.current = true;
      showModal('PROFILE_WIZARD', {});
    }
  }, [
    location.pathname,
    currentModalType,
    email,
    emailVerified,
    hasPublishedIdealistProfile,
    preSignupReaction,
    routeCmsApiPage,
    hasPreSignupComment,
    preSignupFollowUserId,
    preSignupRsvpAnswer,
    preSignupRsvpEventId,
    firstName,
    lastName,
    user,
    isUserLoaded,
    created,
    intakeIntentsDatetimeSet,
    showNotifPrefModal,
    hasIdealistProfileFeature,
    orgs,
  ]);

  useEffect(() => {
    const isIOSMobileApp = window.navigator.userAgent.includes(
      'Idealist Mobile App',
    );

    if (
      (!user && !isIOSMobileApp) ||
      DISABLE_USER_MODAL_ROUTES.includes(location.pathname)
    )
      return;

    verify();
  }, [verify, user, location.pathname]);
}
