import { Button } from 'components/Button/Button';
import { useUserEnvironment } from 'contexts/UserEnvironmentContext';
import { scrollToTop } from 'utils/scroll';

export function SearchButton() {
  const { isEnvironmentLoaded } = useUserEnvironment();

  return (
    <Button
      type="submit"
      variant="primary"
      icon={{ type: 'normalized', name: 'search' }}
      fullWidth
      data-qa-id="search-button"
      data-ready={isEnvironmentLoaded}
      onClick={scrollToTop}
    >
      {getText('Search')}
    </Button>
  );
}
