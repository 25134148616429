import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardPostAListingRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardPostAListingPageContainerLoadable } from './OrgDashboardPostAListingPageContainerLoadable';

export const handle = {
  variant: 'base',
  orgDashboardSubLayoutVariant: 'base',
  darkBackground: true,
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardPostAListingRoute>,
) {
  await OrgDashboardPostAListingPageContainerLoadable.load();
}

export default function OrgDashboardPostAListingHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardPostAListingPageContainerLoadable />;
}
