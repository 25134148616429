import { type CSSProperties, useMemo } from 'react';

import { DividerHr } from './Divider.styled';

type Props = {
  dashed?: boolean;
  marginTop: number | string;
  marginBottom: number | string;
  opaque?: boolean;
};

export function Divider({ dashed, marginTop, marginBottom, opaque }: Props) {
  const style = useMemo<CSSProperties>(
    () => ({
      borderBottomStyle: dashed ? 'dashed' : undefined,
      marginTop,
      marginBottom,
      opacity: opaque ? 0.2 : undefined,
    }),
    [dashed, marginTop, marginBottom, opaque],
  );

  return <DividerHr style={style} />;
}
