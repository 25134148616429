import type { ApiEventId, ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { OrgDashboardApiEvent } from './types/OrgDashboardApiEvent';

type ApiResponse = {
  event: OrgDashboardApiEvent;
};

function mapResponse(response: ApiResponse): OrgDashboardApiEvent {
  return response.event;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId, eventId: ApiEventId) =>
    `/data/orgdashboard/${orgId}/events/${eventId}`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchEvent = endpoint.createFetchFn();

export const orgDashboardApiHorizonFetchEvent = endpoint.createHorizonFetchFn();

export const useOrgDashboardApiFetchEvent = endpoint.createUseFetchHook();
