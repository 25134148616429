import type { ReactNode } from 'react';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { useUser } from 'contexts/UserEnvironmentContext';
import { SIGN_UP_TO_POST_URL_FOR_LOCALE } from 'routing/route-constants';
import { postAListingRoute } from 'routing/routes';

import { PostOnIdealistDropdownMenu } from './PostOnIdealistDropdownMenu';

type Props = {
  locale: UserLocale;
  renderTrigger: (data: {
    triggerText: string;
    triggerHref: string;
  }) => ReactNode;
  trackClickedContext: string;
};

const triggerTextByLocale: Record<UserLocale, { text: string; href: string }> =
  {
    en: {
      text: 'Post on Idealist',
      href: postAListingRoute.with({}),
    },
    es: {
      text: 'Publica',
      href: postAListingRoute.with({}),
    },
    pt: {
      text: 'Anunciar',
      href: postAListingRoute.with({}),
    },
  };

const triggerTextByLocaleUnauth: Record<
  UserLocale,
  { text: string; href: string }
> = {
  en: {
    text: 'Post on Idealist',
    href: SIGN_UP_TO_POST_URL_FOR_LOCALE.en,
  },
  es: {
    text: 'Publica',
    href: SIGN_UP_TO_POST_URL_FOR_LOCALE.es,
  },
  pt: {
    text: 'Anunciar',
    href: SIGN_UP_TO_POST_URL_FOR_LOCALE.pt,
  },
};

export function PostOnIdealistDropdown({
  locale,
  renderTrigger,
  trackClickedContext,
}: Props) {
  const { user } = useUser();

  const { text, href } = user
    ? triggerTextByLocale[locale]
    : triggerTextByLocaleUnauth[locale];

  return (
    <Dropdown
      trigger={renderTrigger({ triggerText: text, triggerHref: href })}
      desktopTriggersOnHover
    >
      <PostOnIdealistDropdownMenu
        locale={locale}
        trackClickedContext={trackClickedContext}
      />
    </Dropdown>
  );
}
