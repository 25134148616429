import { useMemo } from 'react';
import type {
  CSSProperties,
  HTMLAttributeAnchorTarget,
  MouseEvent,
  ReactNode,
} from 'react';
import { Link, NavLink, useLocation } from 'react-router';

import { useVersionedRouterLinkClick } from './useVersionedRouterLinkClick';

type Props = {
  children?: ReactNode;
  id?: string;
  className?: string;
  navLink?: boolean;
  to: string;
  state?: Record<string, unknown>;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
  target?: HTMLAttributeAnchorTarget;
  title?: string;
  style?: CSSProperties;
  'data-qa-id'?: string;
};

export function VersionedRouterLink({
  children,
  id,
  className,
  navLink,
  to,
  onClick,
  state,
  tabIndex,
  target,
  title,
  style,
  'data-qa-id': qaId,
}: Props) {
  const location = useLocation();

  // Multi-router routing: ensure routing to external routes is handled by the browser
  const isExternalRoute = useMemo(
    () =>
      (!location.pathname.startsWith('/cms') && to.startsWith('/cms')) ||
      (!location.pathname.startsWith('/staff') && to.startsWith('/staff')) ||
      (location.pathname.startsWith('/cms') && !to.startsWith('/cms')) ||
      (location.pathname.startsWith('/staff') && !to.startsWith('/staff')),
    [location, to],
  );

  const { normalizedTo, deferredAppLinkClick } = useVersionedRouterLinkClick({
    to,
    state,
    onClick,
    target,
    reloadDocument: isExternalRoute,
  });

  const Component = navLink ? NavLink : Link;

  return (
    <Component
      id={id}
      className={className}
      to={normalizedTo}
      onClick={deferredAppLinkClick}
      state={state}
      reloadDocument={isExternalRoute}
      target={target}
      tabIndex={tabIndex}
      title={title}
      data-qa-id={qaId}
      style={style}
    >
      {children}
    </Component>
  );
}
