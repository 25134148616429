import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { listingApiHorizonFetchVolop } from 'modules/listing/api/volop/listingApiHorizonFetchVolop';
import { isListingExpired } from 'modules/listing/helpers/isListingExpired';
import { ExpiredVolopPageLoadable } from 'modules/listing/pages/volops/expired/ExpiredVolopPageLoadable';
import { UnpublishedVolopPageLoadable } from 'modules/listing/pages/volops/unpublished/UnpublishedVolopPageLoadable';
import type { viewVolopReferenceRoute } from 'modules/listing/routing/listing.routes';
import { SearchVolopsRefinements } from 'modules/search/pages/Volops/components/SearchVolopsRefinements';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ViewVolopPageLoadable } from './ViewVolopPageLoadable';

export const handle = {
  searchRefinements: <SearchVolopsRefinements />,
  defaultSearchType: 'VOLOP',
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

export async function loader({
  request,
  context,
  params: { volopIdDashSlug },
}: HorizonAppRouteLoaderArgs<typeof viewVolopReferenceRoute>) {
  const [volopId] = volopIdDashSlug.split('-');

  try {
    const [volop] = await Promise.all([
      listingApiHorizonFetchVolop({ request, context })(volopId),
      // It's possible this loadable won't be used if the volop is unpublished
      // but in most cases optimistically loading it is best
      ViewVolopPageLoadable.load(),
    ]);

    switch (volop.type) {
      case 'VOLOP':
        return {
          type: isListingExpired(volop.expires) ? 'EXPIRED_VOLOP' : 'VOLOP',
          volop,
          handle: {
            hideFooterDivider: 'hide-footer-divider-if-logged-out',
            searchFromPageType: 'LISTING',
            urlByLocale: volop.url,
          } satisfies LayoutHandleProps,
        } as const;
      case 'UNPUBLISHED_VOLOP':
        await UnpublishedVolopPageLoadable.load();
        return {
          type: volop.type,
          unpublishedVolop: volop,
        } as const;
    }
  } catch {
    return null;
  }
}

export default function ViewVolopHorizonRoute({
  loaderData,
}: HorizonAppRouteComponentProps<typeof loader>) {
  switch (loaderData?.type) {
    case undefined:
      return <NotFoundPageLoadable noLayout />;
    case 'VOLOP':
      return <ViewVolopPageLoadable volop={loaderData.volop} />;
    case 'EXPIRED_VOLOP':
      return <ExpiredVolopPageLoadable volop={loaderData.volop} />;
    case 'UNPUBLISHED_VOLOP':
      return (
        <UnpublishedVolopPageLoadable
          unpublishedVolop={loaderData.unpublishedVolop}
        />
      );
  }
}
