import type { CSSProperties } from 'react';
import { styled } from 'styled-components';

/**
 * Using a styled-components prop here would create a bunch of classes, one
 * for each icon name.
 *
 * By using CSS variables we have a single className
 */
export const maskImageIconSrcUrlVar = '--src' as keyof CSSProperties;

export const MaskImageIconDiv = styled.div`
  background: currentcolor;
  display: inline-flex;
  flex-shrink: 0;
  pointer-events: none;
  vertical-align: middle;
  -webkit-mask-image: var(${maskImageIconSrcUrlVar});
  mask-image: var(${maskImageIconSrcUrlVar});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
`;
