import type { StoreApi } from 'zustand';
import { create, useStore } from 'zustand';

import { onSsrApiDataSet } from 'rendering/state/ssrApiData';

type Args<TState> = {
  getSsrState: () => TState | undefined;
  fallbackState: TState;
};

export function createSsrApiDataStore<TState>({
  getSsrState,
  fallbackState,
}: Args<TState>): {
  store: StoreApi<TState>;
  hook: <TSelectorData>(
    selector: (state: TState) => TSelectorData,
  ) => TSelectorData;
} {
  const store = create<TState>(() => fallbackState);

  const useThisStore = <TSelectorData>(
    selector: (state: TState) => TSelectorData,
  ) =>
    // This is a workaround for a bug in zustand
    //
    // When using data from `ssrApiData` we can't use initial state like a regular store
    // This is causing the state to be stale during server side rendering
    useStore(store, () => selector(store.getState()));

  onSsrApiDataSet(() => {
    const ssrState = getSsrState();
    if (ssrState) store.setState(ssrState);
  });

  return { store, hook: useThisStore };
}
