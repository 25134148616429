import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetCheckboxOptions } from 'modules/search/components/Facet/Options/SearchFacetCheckboxOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { ORG_TYPE_CHECKBOX_OPTIONS } from 'utils/constants/general/orgTypes';

type Props = {
  variant?: SearchRefinementVariant;
};
export function OrgTypeFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('orgType');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="orgType"
      icon="building"
      label={
        <>
          {getText('Org Type')}{' '}
          <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="orgType"
        initialValue={selectedFacetValues}
        options={ORG_TYPE_CHECKBOX_OPTIONS()}
      />
    </FacetVariant>
  );
}
