import { NotFoundContent } from 'components/NotFoundContent/NotFoundContent';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';

export const handle = {
  variant: 'base',
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export default function OrgDashboardNotFoundHorizonRoute() {
  return <NotFoundContent textOnly />;
}
