import type { ComponentProps, RefObject } from 'react';
import { styled } from 'styled-components';

import { linkStyles } from 'theme/theme';

import { UnstyledNativeLink } from './UnstyledNativeLink';

type Props = Omit<
  ComponentProps<typeof UnstyledNativeLink> & {
    ref?: RefObject<HTMLAnchorElement>;
  },
  'anchorRef'
>;

function UnstyledNativeLinkWithRef({ ref, ...props }: Props) {
  return (
    <UnstyledNativeLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      anchorRef={ref}
    />
  );
}

export const StyledNativeLinkWithRef = styled(UnstyledNativeLinkWithRef)`
  ${linkStyles}
`;
