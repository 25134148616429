import { useMemo } from 'react';
import { useSearchParams } from 'react-router';

import { useLatest } from 'hooks/useLatest';

export function useSearchFacetSelectedValue(filterType: string) {
  const [searchParams] = useSearchParams();

  const selectedValue = useMemo(
    () => searchParams.get(filterType) || '',
    [filterType, searchParams],
  );

  const selectedValueRef = useLatest(selectedValue);

  return selectedValueRef.current;
}
