import { useCallback, useEffect, useState } from 'react';

import type { ToastId } from 'types/ToastData';

type ToastDismissalData = {
  [toastId: ToastId]: {
    dismissedAt: number;
  };
};

const emptyToastDismissalData: ToastDismissalData = {};

const isClient = typeof window !== 'undefined';

export const useToastPersistence = (namespace: string) => {
  const LS_KEY = `idealist:action-toast-persistence:${namespace}`;

  const getDismissedToasts = useCallback(() => {
    if (!isClient) return emptyToastDismissalData;

    const dismissedToastsJson = localStorage.getItem(LS_KEY);
    try {
      return dismissedToastsJson
        ? JSON.parse(dismissedToastsJson)
        : emptyToastDismissalData;
    } catch {
      localStorage.removeItem(LS_KEY);
      return emptyToastDismissalData;
    }
  }, [LS_KEY]);

  const [dismissedToasts, setDismissedToasts] =
    useState<ToastDismissalData>(getDismissedToasts);

  const clear = useCallback(() => {
    if (!isClient) return;
    localStorage.removeItem(LS_KEY);
    setDismissedToasts(emptyToastDismissalData);
  }, [LS_KEY]);

  const persistDismissedToastId = useCallback(
    (toastId: ToastId) => {
      if (!isClient) return;
      const updatedToasts = {
        ...dismissedToasts,
        [toastId]: {
          dismissedAt: Date.now(),
        },
      };
      setDismissedToasts(updatedToasts);
      localStorage.setItem(LS_KEY, JSON.stringify(updatedToasts));
    },
    [dismissedToasts, LS_KEY],
  );

  // Export debug API for testing purposes
  useEffect(() => {
    if (!isClient) return;
    Object.assign(window, {
      Idealist: {
        ...window.Idealist,
        debugActionToastPersistence: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...((window.Idealist as any)?.debugActionToastPersistence ?? {}),
          [namespace]: {
            dismissedToasts,
            persistDismissedToastId,
            getDismissedToasts,
            clear,
          },
        },
      },
    });
  }, [
    clear,
    dismissedToasts,
    persistDismissedToastId,
    namespace,
    getDismissedToasts,
  ]);

  const hasDismissedToast = useCallback(
    (toastId: ToastId) => toastId in dismissedToasts,
    [dismissedToasts],
  );

  const getDismissal = useCallback(
    (toastId: ToastId) => dismissedToasts[toastId],
    [dismissedToasts],
  );

  const clearExpired = useCallback(
    (maxAgeMs: number, toastId: ToastId) => {
      if (!isClient) return;

      const dismissal = getDismissal(toastId);
      if (!dismissal) return;

      const age = Date.now() - dismissal.dismissedAt;
      if (age > maxAgeMs) clear();
    },
    [clear, getDismissal],
  );

  return {
    hasDismissedToast,
    getDismissal,
    clear,
    persistDismissedToastId,
    clearExpired,
  };
};
