import type { ApiInviteId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { ApiInvite } from './types/ApiInvite';

type ApiResponse = {
  invite: ApiInvite;
};

function mapResponse(response: ApiResponse): ApiInvite {
  return response.invite;
}

const endpoint = new FetchEndpoint({
  urlFactory: (inviteId: ApiInviteId) => `/data/website/invites/${inviteId}`,
  mapResponse,
});

// Exports

export const apiHorizonFetchInvite = endpoint.createHorizonFetchFn();
