import { createGlobalStyle, css } from 'styled-components';

import {
  formSubmittingClassName,
  formSubmittingDisplayBlockIfSubmittingVar,
  formSubmittingOneIfSubmittingVar,
} from 'components/Form/formSubmittingConstants';
import {
  colors,
  cssBreakpoints,
  fontSizesByType,
  fontWeights,
  fonts,
  utilityClasses,
} from 'theme/theme';

import {
  routeLoadingClassName,
  routeLoadingDisplayNoneUnlessLoadingVar,
  routeLoadingOneIfLoadingElseZeroVar,
} from './routeLoading';

const cssVariables = css`
  /* stylelint-disable custom-property-pattern */
  --transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  /*
   * Colors
   */
  --color-black: #000;
  --color-white: #fff;

  /* primary */
  --color-brandBlue: #0d73d9;
  --color-brandGreen: #68bd53;
  --color-brandYellow: #f7c702;
  --color-skyBlue: #5dc2f1;
  --color-secondarySkyBlue: #1d91e9;
  --color-softBlue: #ebf6ff;
  --color-backgroundBlue: #f8fcff;
  --color-dustyBlue: #bdcddc;

  /* secondary */
  --color-lightYellow: #fff6d1;
  --color-lightRed: #ffedef;
  --color-lightSage: #dbfdd3;
  --color-lightPurple: #f5edff;
  --color-selectionBlue: #cff0ff;

  /* grey matter */
  --color-backgroundGrey: #fbfbfb;
  --color-inputGrey: #f8f6f6;
  --color-calloutGrey: #f1f1f1;
  --color-selectionGrey: #e6e6e6;
  --color-elementGrey: #c4c8cc;
  --color-lightContentGrey: #717375;
  --color-mediumContentGrey: #484848;
  --color-darkContentGrey: #242424;

  /* alerts and actions */
  --color-accessibleYellow: #ce8903;
  --color-hoverBlue: #0042c1;
  --color-decorativePlum: #645694;
  --color-brightPlum: #9b489b;
  --color-alertRed: #d0021b;
  --color-alertRedHover: #b40016;
  --color-natureGreen: #2d7b1a;
  --color-tagLemonGreen: #7de963;
  --color-lemon: #f7fc26;
  --color-decorativePoppy: #ef444d;
  --color-decorativeStraw: #f9d86d;
  /* stylelint-enable custom-property-pattern */
`;

export const ThemeGlobalStyle = createGlobalStyle`
  :root {
    ${cssVariables};

    font-family: ${fonts.sans};
    color: ${colors.mediumContentGrey};

    font-size: ${fontSizesByType.body[0]}px;

    @media all and (min-width: ${cssBreakpoints.smUp}) {
      font-size: ${fontSizesByType.body[2]}px;
    }

    /* stylelint-disable at-rule-descriptor-no-unknown */
    @page {
      margin-bottom: 0;
      size: auto;
      box-decoration-break: clone;
    }
  }

  * {
    box-sizing: border-box;
    font-family: ${fonts.sans};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    box-sizing: border-box;
    ${routeLoadingOneIfLoadingElseZeroVar}: 0;

    &.${routeLoadingClassName} {
      ${routeLoadingOneIfLoadingElseZeroVar}: 1;
      ${routeLoadingDisplayNoneUnlessLoadingVar}: block;
    }
  }

  svg {
    box-sizing: content-box;
    pointer-events: none;
  }

  strong {
    font-weight: ${fontWeights.semiBold};
  }

  button {
    padding: 0;
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
  }

  a {
    color: #0d73d9;
    
    &:hover {
      color: #0042c1;
    }
  }
  
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  /* Utility classes */
  ${utilityClasses.map(
    (c) => css`
      .idlst-${c.name.replace(/[aeiou-]/g, '')} {
        ${c.styles}
      }
    `,
  )}
  
  /* Form submission class */
  .${formSubmittingClassName} {
    ${formSubmittingOneIfSubmittingVar}: 1;
    ${formSubmittingDisplayBlockIfSubmittingVar}: block;
  }
`;
