import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { useLatest } from './useLatest';

type Props<T extends HTMLElement> = {
  elementRef: RefObject<T | null>;
  onClickOutside: (event: TouchEvent | MouseEvent) => void;
};

export function useOnClickOutside<T extends HTMLElement>({
  elementRef,
  onClickOutside,
}: Props<T>) {
  const isTouchRef = useRef(false);

  const onClickOutsideRef = useLatest(onClickOutside);

  const handle = useCallback(
    (event: TouchEvent | MouseEvent) => {
      const container = elementRef.current;

      if (
        container &&
        event.target instanceof Node &&
        !container.contains(event.target)
      ) {
        onClickOutsideRef.current(event);
      }
    },
    [elementRef, onClickOutsideRef],
  );

  const handleTouch = useCallback(
    (event: TouchEvent) => {
      isTouchRef.current = true;
      handle(event);
    },
    [handle],
  );

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (isTouchRef.current) return;
      handle(event);
    },
    [handle],
  );

  useEffect(() => {
    document.addEventListener('touchend', handleTouch);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('touchend', handleTouch);
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick, handleTouch]);
}
