import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ImportedListingPageContainerLoadable } from './ImportedListingPageContainerLoadable';

export async function loader() {
  await ImportedListingPageContainerLoadable.load();
}

export default function ImportedListingHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <ImportedListingPageContainerLoadable />;
}
