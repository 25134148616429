import type { SearchParamsObject } from '@algolia/client-search';

import type { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import type { SearchLocation } from 'modules/search/types/SearchLocation';

import { searchAlgoliaEventsOptions } from './searchAlgoliaEventsOptions';
import { searchAlgoliaInternshipsOptions } from './searchAlgoliaInternshipsOptions';
import { searchAlgoliaJobsOptions } from './searchAlgoliaJobsOptions';
import { searchAlgoliaOrgsOptions } from './searchAlgoliaOrgsOptions';
import { searchAlgoliaVolopsOptions } from './searchAlgoliaVolopsOptions';

export function searchAlgoliaOptionsSwitch({
  filtersById,
  searchLocation,
}: {
  filtersById: SearchFiltersById;
  searchLocation: SearchLocation | undefined;
}): SearchParamsObject {
  switch (filtersById.type) {
    case 'JOB':
      return searchAlgoliaJobsOptions({ filtersById, searchLocation });
    case 'VOLOP':
      return searchAlgoliaVolopsOptions({ filtersById, searchLocation });
    case 'EVENT':
      return searchAlgoliaEventsOptions({ filtersById, searchLocation });
    case 'INTERNSHIP':
      return searchAlgoliaInternshipsOptions({ filtersById, searchLocation });
    case 'ORG':
      return searchAlgoliaOrgsOptions({ filtersById, searchLocation });
  }
}
