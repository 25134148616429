import type { ReactNode } from 'react';
import { useEffect } from 'react';
import {
  type Params,
  useLoaderData,
  useParams,
  useRevalidator,
} from 'react-router';

import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { useIsFirstRenderRef } from 'hooks/useIsFirstRenderRef';

import { renderClientOnlyLoaderToken } from './renderClientOnlyLoaderToken';

type Props<TData> = {
  render: (data: TData, params: Params<string>) => ReactNode;
};

export function RenderLoaderComponent<TData>({ render }: Props<TData>) {
  const loaderData = useLoaderData() as TData;
  const params = useParams();

  const revalidator = useRevalidator();

  const shouldRevalidate = loaderData === renderClientOnlyLoaderToken;
  const { isFirstRenderRef } = useIsFirstRenderRef();

  useEffect(() => {
    if (shouldRevalidate && isFirstRenderRef.current) revalidator.revalidate();
  }, [isFirstRenderRef, revalidator, shouldRevalidate]);

  if (shouldRevalidate) return <LoadingSection />;

  return render(loaderData, params);
}
