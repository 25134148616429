import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchVolop } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchVolop';
import { orgDashboardApiHorizonFetchListingApplications } from 'modules/orgDashboard/api/listingApplication/orgDashboardApiFetchListingApplications';
import { orgDashboardViewVolopRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardViewVolopPageLoadable } from './OrgDashboardViewVolopPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader({
  request,
  context,
  params: { orgId, volopId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardViewVolopRoute>) {
  const [volop, listingApplications] = await Promise.all([
    orgDashboardApiHorizonFetchVolop({ request, context })(
      orgId,
      volopId,
    ).catch(() => null),
    orgDashboardApiHorizonFetchListingApplications({ request, context })(
      orgId,
      { type: 'VOLOP', id: volopId },
    ),
    OrgDashboardViewVolopPageLoadable.load(),
  ]);

  if (!volop) await NotFoundPageLoadable.load();

  return { volop, listingApplications };
}

export default function OrgDashboardViewVolopHorizonRoute({
  loaderData: { volop, listingApplications },
}: HorizonAppRouteComponentProps<typeof loader>) {
  if (!volop) return <NotFoundPageLoadable noLayout />;

  return (
    <OrgDashboardViewVolopPageLoadable
      key={volop.id}
      volop={volop}
      listingApplications={listingApplications}
    />
  );
}
