export function legacyButtonSize(props: {
  $size?: 'tiny' | 'small' | 'medium' | 'large';
}) {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (props.$size) {
    case 'tiny':
      return {
        fontSize: '12px',
        padding: '0 4px',
      };

    case 'small':
      return {
        fontSize: '14px',
        padding: '0.4em 1.2em',
      };

    case 'medium':
      return {
        fontSize: '16px',
        padding: '8px 16px',
      };

    case 'large':
      return {
        fontSize: '18px',
        padding: '12px 24px',
      };

    default:
      return {
        fontSize: '16px',
        padding: '8px 16px',
      };
  }
}
