import { css, styled } from 'styled-components';

import { LegacyCloseButton } from 'components/LegacyButton/LegacyCloseButton';
import { colors, cssBreakpoints } from 'theme/theme';

import type { TooltipPosition } from './TooltipPosition';
import type { TooltipSize } from './TooltipSize';
import type { TooltipVariant } from './TooltipVariant';
import { arrowPositionStyles } from './arrowPositionStyles';
import { generateKeyframes } from './generateKeyframes';
import { placementStyles } from './placementStyles';
import { variantStyles } from './variantStyles';

export const TargetContainer = styled.div`
  position: relative;
`;

export const TooltipContainer = styled.div<{
  $animate: boolean;
  $placement: TooltipPosition;
  $variant: TooltipVariant;
  $targetWidth: number;
  $targetHeight: number;
  $targetTop: number;
  $targetLeft: number;
  $tooltipWidth?: number;
  $size: TooltipSize;
  $stage: string;
  $withArrow: boolean;
}>`
  position: absolute;
  left: ${({ $targetLeft }) => $targetLeft}px;
  top: ${({ $targetTop }) => $targetTop}px;
  border-radius: 4px;
  filter: drop-shadow(0 0 6px rgb(0 0 0 / 20%));
  font-size: 14px;
  max-width: ${(props) => (props.$tooltipWidth ? '80vw' : '250px')};
  text-align: left;
  width: ${(props) =>
    props.$tooltipWidth ? `${props.$tooltipWidth}px` : 'max-content'};
  z-index: 3;

  &::after {
    content: '';
    background: transparent;
    display: block;
    height: ${(props) => (props.$size === 'large' ? '17px' : '10px')};
    position: absolute;
    width: 100%;
  }

  ${(props) =>
    props.$withArrow
      ? `
  &::before {
    content: '';
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    height: ${props.$size === 'large' ? '17px' : '10px'};
    width: 100%;
    z-index: -1;
  }`
      : undefined}

  ${(props) => {
    if (props.$animate) {
      const { enterAnimation, exitAnimation } = generateKeyframes(
        props.$placement,
        props.$targetWidth,
        props.$targetHeight,
        props.$size,
      );

      if (props.$stage === 'enter') {
        return css`
          animation: ${enterAnimation} 0.4s forwards;
        `;
      }

      if (props.$stage === 'exit') {
        return css`
          animation: ${exitAnimation} 0.3s forwards;
        `;
      }
    }

    const { toTransform, additionalStyles } = placementStyles(
      props.$placement,
      props.$targetWidth,
      props.$targetHeight,
      props.$size,
    );

    return css`
      transform: ${toTransform};
      ${additionalStyles};
    `;
  }}

  ${(props) => variantStyles(props.$variant, props.$placement)}

  ${(props) => {
    if (!props.$withArrow) return undefined;

    return arrowPositionStyles(
      props.$placement,
      props.$targetWidth,
      props.$size,
    );
  }}

  @media (min-width: ${cssBreakpoints.mdUp}) {
    max-width: ${(props) =>
      props.$tooltipWidth ? `${props.$tooltipWidth}px` : '300px'};
  }
`;

export const TooltipWrapper = styled.div<{ $size: TooltipSize }>`
  border-radius: 4px;
  padding: ${(props) => (props.$size === 'large' ? '25px 20px' : '2px 8px')};
`;

export const TooltipCloseButton = styled(LegacyCloseButton)`
  position: absolute;
  color: ${colors.lightContentGrey};
  padding: 0;
  right: 5px;
  top: 5px;

  &:hover {
    color: ${colors.brandBlue};

    &:disabled {
      &:hover {
        color: ${colors.lightContentGrey};
      }
    }
  }
`;
