import type { UseFetchFnMeta } from 'hooks/useFetchFn';
import { useFetchUrl } from 'hooks/useFetchUrl';
import type { HorizonAppLoaderContext } from 'horizon/types/horizon-app-loader-context';
import {
  isRenderingHorizonServer,
  isRenderingServer,
} from 'rendering/state/renderingState';
import { getSsrApiDataForUrl } from 'rendering/state/ssrApiData';
import { getJson } from 'utils/http/getJson';
import { log } from 'utils/logging';

type FetchEndpointUrlFactory<TArgs extends unknown[], TUrl extends string> = (
  ...args: TArgs
) => TUrl | undefined;

type FetchEndpointResponseMapper<TMappedResponse, TResponse> = (
  response: TResponse,
) => TMappedResponse;

export class FetchEndpoint<
  TArgs extends unknown[],
  TUrl extends string,
  TMappedResponse,
  TResponse,
> {
  urlFactory: FetchEndpointUrlFactory<TArgs, TUrl>;

  mapResponse: FetchEndpointResponseMapper<TMappedResponse, TResponse>;

  constructor(args: {
    urlFactory: FetchEndpointUrlFactory<TArgs, TUrl>;
    mapResponse: FetchEndpointResponseMapper<TMappedResponse, TResponse>;
  }) {
    this.urlFactory = args.urlFactory;
    this.mapResponse = args.mapResponse;
  }

  url(...args: TArgs): TUrl | undefined {
    return this.urlFactory(...args);
  }

  createSsrApiDataFn() {
    return (...args: TArgs): TMappedResponse | undefined => {
      const ssrApiData = getSsrApiDataForUrl<TResponse>(
        this.urlFactory(...args),
      );

      if (ssrApiData) return this.mapResponse(ssrApiData);
    };
  }

  createFetchFn() {
    return async (...args: TArgs): Promise<TMappedResponse> => {
      const url = this.urlFactory(...args);
      if (!url) throw new Error('Cannot fetch with undefined URL');

      // This is a horizon server request
      if (isRenderingHorizonServer()) {
        const message =
          'Attempting to fetch with `createFetchFn` during Horizon SSR, use `createHorizonFetchFn` instead.';

        // Besides throwing, also log so it's easier to see what's happening
        // Sometimes we try/catch these fetch calls to account for 404
        log.error(message);
        throw new Error(message);
      }

      // This is a server request
      if (isRenderingServer()) {
        const data = getSsrApiDataForUrl(url);
        if (!data)
          throw new Error(`Data for ${url} is not available during SSR`);

        return this.mapResponse(data as TResponse);
      }

      return this.mapResponse(await getJson<TResponse>(url));
    };
  }

  createHorizonFetchFn() {
    return (requestInfo: {
      request: Request;
      context: HorizonAppLoaderContext;
    }) => {
      // Compatibility with hypernova setup
      if (
        !isRenderingServer() ||
        process.env.IDEALIST_HORIZON_ENABLED !== 'true'
      ) {
        return this.createFetchFn();
      }

      return async (...args: TArgs): Promise<TMappedResponse> => {
        const { request, context } = requestInfo;
        const url = this.urlFactory(...args);

        const cookieHeader = request.headers.get('Cookie');

        const xForwardedForHeader =
          request.headers.get('X-Forwarded-For') || '';

        const previousForwardedFor = xForwardedForHeader
          .split(',')
          .map((ip) => ip.trim());

        const response = await fetch(
          `${process.env.IDEALIST_HORIZON_SERVER}/${url}`,
          {
            headers: {
              Cookie: cookieHeader || '',
              'X-Forwarded-For': [
                context.clientIp,
                ...previousForwardedFor,
              ].join(', '),
            },
          },
        );

        return this.mapResponse(await response.json());
      };
    };
  }

  createUseFetchHook() {
    return (
      ...args: TArgs
    ): [TMappedResponse | undefined, UseFetchFnMeta<TMappedResponse>] => {
      const url = this.url(...args);

      return useFetchUrl({ url, mapResponse: this.mapResponse });
    };
  }
}
