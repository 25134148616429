import type { PropsWithChildren } from 'react';
import { styled } from 'styled-components';

import { colors } from 'theme/theme';

// In order to avoid datepicker events from influencing events further up in the tree,
// like `onClickOutside`, stop events from bubbling past its container.
// Ideally, `react-datepicker` would handle this behavior for us.
// It's also not entirely clear how to pass props to `popperContainer`, otherwise
// this would be a simple `styled.div` inside `DatePicker.styled.ts`
export const DatePickerPopperContainer = styled(
  ({ children }: PropsWithChildren) => (
    // TODO: Fix this from eslint v9 update
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  ),
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  .react-datepicker-popper {
    width: max-content;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .react-datepicker__day--selected {
    background-color: ${colors.brandBlue} !important;
  }
`;
