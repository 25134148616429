import type {
  ComponentType,
  ElementType,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
} from 'react';
import { createElement } from 'react';
import type { LinkProps } from 'react-router';
import { styled } from 'styled-components';
import {
  type HeightProps,
  type MarginProps,
  type MaxHeightProps,
  type MaxWidthProps,
  type MinHeightProps,
  type MinWidthProps,
  type PaddingProps,
  type WidthProps,
  border,
  borderRadius,
  color,
  display,
  fontSize,
  fontWeight,
  height,
  minHeight,
  minWidth,
  size,
  space,
  width,
} from 'styled-system';

import { useTabContext } from 'containers/TabContext';

export type LegacyButtonProps = {
  ref?: RefObject<HTMLButtonElement>;
  as?: ComponentType | ElementType;
  children?: ReactNode;
  component?: ComponentType | ElementType;
  className?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  color?: string;
  bg?: string;
  fontSize?: string;
  fontWeight?: string | number;
  borderRadius?: string | number;
  border?: string;
  borderLeft?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  href?: string;
  target?: LinkProps['target'];
  rel?: LinkProps['rel'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onKeyUp?: KeyboardEventHandler<HTMLButtonElement>;
  textAlign?: string;
  title?: string;
  tabIndex?: number;
  name?: string;
  value?: string;
  disabled?: boolean;
} & MarginProps &
  PaddingProps &
  WidthProps &
  MinWidthProps &
  MaxWidthProps &
  HeightProps &
  MinHeightProps &
  MaxHeightProps;

const StyledButton = styled(
  ({
    ref,
    as,
    component,
    type = 'button',
    m: _m,
    mt: _mt,
    mr: _mr,
    mb: _mb,
    ml: _ml,
    mx: _mx,
    my: _my,
    p: _p,
    pt: _pt,
    pr: _pr,
    pb: _pb,
    pl: _pl,
    px: _px,
    py: _py,
    display: _display,
    alignItems: _alignItems,
    justifyContent: _justifyContent,
    width: _width,
    height: _height,
    minWidth: _minWidth,
    minHeight: _minHeight,
    color: _color,
    bg: _bg,
    fontSize: _fontSize,
    fontWeight: _fontWeight,
    borderRadius: _borderRadius,
    border: _border,
    disabled,
    ...otherProps
  }: LegacyButtonProps) => {
    const props = { ...otherProps, disabled: Boolean(disabled) };

    if (as) {
      return createElement(as, props);
    }

    if (component) {
      return createElement(component, props);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <button {...props} type={type} ref={ref} />;
  },
)<{ $transparent: boolean; $isTabbing: boolean }>`
  padding: 0;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;

  &[disabled] {
    cursor: auto;
  }
  ${(props) =>
    !props.$isTabbing &&
    `
    outline: 0;
  `}
  ${size} ${display} ${space} ${width} ${height} ${minWidth} ${minHeight} ${fontSize} ${fontWeight} ${borderRadius} ${color} ${border}
`;

export function LegacyButton(props: LegacyButtonProps) {
  const { ref, ...otherProps } = props;

  const isTabbing = useTabContext();

  return (
    <StyledButton
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      ref={ref}
      $isTabbing={isTabbing}
    />
  );
}
