import { useEffect, useRef } from 'react';

import { useEnvironment } from 'contexts/UserEnvironmentContext';
import { searchGetInitialLocation } from 'modules/search/helpers/searchGetInitialLocation';
import {
  getSearchLocation,
  updateSearchLocation,
} from 'modules/search/zustand-stores/searchStore';

export function useUpdateSearchLocationFromLocalStorage() {
  const { environment } = useEnvironment();
  const searchLocation = getSearchLocation();

  const triggeredRef = useRef(false);

  useEffect(() => {
    if (!environment || triggeredRef.current) return;
    triggeredRef.current = true;

    if (!searchLocation) {
      updateSearchLocation(
        searchGetInitialLocation(environment.guessedLocation),
        false,
      );
    }
  }, [environment, searchLocation]);
}
