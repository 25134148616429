export const SEARCH_RADIUSES: Record<UserLocale, Record<string, string>> = {
  en: {
    '': 'Default Radius',
    '8000': '5 miles',
    '16000': '10 miles',
    '24000': '15 miles',
    '40000': '25 miles',
    '100000': '60 miles',
    '160000': '100 miles',
    state: 'Entire State',
    country: 'Entire Country',
  },
  es: {
    '': 'Radio por Defecto',
    '5000': '5 Km',
    '10000': '10 Km',
    '15000': '15 Km',
    '25000': '25 Km',
    '60000': '60 Km',
    '100000': '100 Km',
    state: 'Todo el Estado',
    country: 'Todo el País',
  },
  pt: {
    '': 'Automático',
    '5000': '5 Km',
    '10000': '10 Km',
    '15000': '15 Km',
    '25000': '25 Km',
    '60000': '60 Km',
    '100000': '100 Km',
    state: 'Todo o Estado',
    country: 'País Inteiro',
  },
};

export const SEARCH_RADIUS_RADIO_OR_SELECT_OPTIONS: () => Record<
  UserLocale,
  Array<{ label: string; value: string }>
> = () =>
  (Object.keys(SEARCH_RADIUSES) as UserLocale[]).reduce(
    (acc, locale: UserLocale) => ({
      ...acc,
      [locale]: Object.entries(SEARCH_RADIUSES[locale]).map(
        ([value, label]) => ({
          value,
          label,
        }),
      ),
    }),
    {} as Record<UserLocale, Array<{ label: string; value: string }>>,
  );
