import { styled } from 'styled-components';

import {
  detailsStateClosedClass,
  detailsStateOpenClass,
} from 'components/Details/detailsClasses';
import {
  detailsOneWhenClosedZeroWhenOpenVar,
  detailsZeroWhenClosedOneWhenOpenVar,
} from 'components/Details/detailsCssVariables';
import { cssBreakpoints, transition } from 'theme/theme';

import { accordionWidth } from './accordionCssVars';

export const AccordionHolder = styled.div`
  width: var(${accordionWidth});
`;

export const AccordionDetails = styled.details`
  ${detailsOneWhenClosedZeroWhenOpenVar}: 1;
  ${detailsZeroWhenClosedOneWhenOpenVar}: 0;

  &[open] {
    ${detailsOneWhenClosedZeroWhenOpenVar}: 0;
    ${detailsZeroWhenClosedOneWhenOpenVar}: 1;
  }

  &.${detailsStateOpenClass} {
    > div:last-of-type {
      transition: ${transition};
      transition-property: transform;
    }
  }

  &.${detailsStateClosedClass} {
    > div:last-of-type {
      visibility: hidden;

      @media all and (max-width: ${cssBreakpoints.mdDown}) {
        transform: translateY(-10%);
      }

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        transform: translateY(-10px);
      }
    }
  }
`;
