import { styled } from 'styled-components';

import { colors, gutterWidth } from 'theme/theme';

export const CenteredContent = styled.div`
  margin: auto;
  max-width: 528px;
  width: 100%;
  padding: 40px ${gutterWidth / 2}px;
  color: ${colors.mediumContentGrey};
  line-height: 1.5;
`;
