import { userDashboardViewEmploymentOnboardingRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';

import { UserDashboardEmploymentProfileOnboardingPageLoadable } from './UserDashboardEmploymentProfileOnboardingPageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<
    typeof userDashboardViewEmploymentOnboardingRoute
  >,
) {
  await UserDashboardEmploymentProfileOnboardingPageLoadable.load();
}

export default function UserDashboardEmploymentProfileOnboardingHorizonRoute() {
  return <UserDashboardEmploymentProfileOnboardingPageLoadable />;
}
