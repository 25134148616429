import type { RadiusType } from 'modules/search/helpers/radius';
import { searchInternshipsRoutePathByLocale } from 'modules/search/routing/search.route-paths';
import { searchInternshipsRouteReference } from 'modules/search/routing/search.routes';
import type { SearchInternshipFiltersByName } from 'modules/search/types/FiltersByName/SearchInternshipFiltersByName';
import type { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchGetQueryParamsFromFiltersByName } from './searchGetQueryParamsFromFiltersByName';

type Args = {
  lang: UserLocale;
  q?: string;
  radius?: RadiusType | string | null;
  filtersByName?: Omit<SearchInternshipFiltersByName, 'type'>;
  sort?: SearchSortType;
  pageIndex?: number;
};

export function searchGetInternshipsRoutePath({
  lang,
  filtersByName,
  q,
  radius,
  sort,
  pageIndex,
}: Args) {
  const queryParams = searchGetQueryParamsFromFiltersByName(filtersByName);
  if (q) queryParams.q = q;
  if (radius) queryParams.radius = radius;
  if (sort && sort !== 'relevance') queryParams.sort = sort;
  if (pageIndex) queryParams.page = pageIndex + 1;

  return searchInternshipsRouteReference
    .with(queryParams)
    .replace('/-lang/-segment', searchInternshipsRoutePathByLocale[lang]);
}
