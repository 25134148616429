import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetCheckboxOptions } from 'modules/search/components/Facet/Options/SearchFacetCheckboxOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { EVENT_SOURCES } from 'utils/constants/general/eventSources';

type Props = {
  variant?: SearchRefinementVariant;
};

export function EventSourceFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('source');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="source"
      icon="sign-path"
      label={
        <>
          {getText('Source')} <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="source"
        initialValue={selectedFacetValues}
        searchable
        options={Object.entries(EVENT_SOURCES).map(([name, label]) => ({
          name,
          label,
        }))}
      />
    </FacetVariant>
  );
}
