import { AppRouteGroup } from 'routing/classes/AppRouteGroup';

export const routeGroup = new AppRouteGroup('');

export const localizedRouteGroup = new AppRouteGroup('/:lang');

export const homeRoute = localizedRouteGroup.rootRoute();

// Post a listing

export const postAListingRoute = routeGroup.route('post-a-listing', [
  'listingType',
]);

export const createJobRoute = routeGroup.route('jobs/create');

export const createInternshipRoute = routeGroup.route('internships/create');

// Invoice

export const viewInvoiceRoute = routeGroup.route('invoices/:invoiceId');

export const viewDonationReceiptRoute = routeGroup.route(
  'donation-receipts/:invoiceId',
);

// Email

export const emailRateRecommendedCandidateRoute = routeGroup.route(
  'email/rate-recommended-candidate/:applicantId/:rating', // rating: good | bad
);
