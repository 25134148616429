import type { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { LOCALES } from 'utils/constants/general/locales';

export const localeFacet = {
  id: 'localeFacet',
  name: 'locale',
  get title() {
    return getText('Listing Language');
  },
  type: 'array',
  options: Object.keys(LOCALES),
  optionTitles: LOCALES,
} as const satisfies SearchFacetData;
