import type { CSSProperties, ReactNode } from 'react';

import type { DropdownMenuPlacement } from 'components/Dropdown/types/DropdownMenuPlacement';
import type { Spacing } from 'theme/theme';

import { DropdownMenuHolder } from './DropdownMenu.styled';
import {
  dropdownMenuOffsetX,
  dropdownMenuOffsetY,
  dropdownMenuWidth,
} from './dropdownMenuCssVariables';

type Props = {
  offsetX?: Spacing;
  offsetY?: Spacing;
  width?: string;
  children: ReactNode;
  qaId?: string;
  placement?: DropdownMenuPlacement;
  mobilePlacement?: DropdownMenuPlacement;
};

export function DropdownMenu({
  offsetX,
  offsetY,
  width,
  children,
  qaId,
  placement = 'bottom-left',
  mobilePlacement = 'bottom-left',
}: Props) {
  return (
    <DropdownMenuHolder
      style={
        {
          [dropdownMenuWidth]: width,
          [dropdownMenuOffsetX]: offsetX,
          [dropdownMenuOffsetY]: offsetY,
        } as CSSProperties
      }
      data-qa-id={qaId}
      $placement={placement}
      $mobilePlacement={mobilePlacement}
    >
      {children}
    </DropdownMenuHolder>
  );
}
