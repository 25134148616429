import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';

export function searchGetQueryParamsFromFiltersByName(
  filters: Partial<SearchFiltersByName> | undefined,
): Record<string, string | string[] | number> {
  if (!filters) return {};

  const keys = Object.keys(filters).filter((key) => key !== 'type') as Array<
    keyof typeof filters
  >;

  const searchParams: Record<string, string | string[] | number> = {};

  keys.forEach((key) => {
    const value = filters[key];
    if (typeof value === 'undefined') return;

    searchParams[key] = value;
  });

  return searchParams;
}
