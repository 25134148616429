import type { ApiUser } from 'api/userEnvironment/types/ApiUserEnvironment';

export function getUserType(user: ApiUser | null | undefined) {
  if (!user) return 'Logged Out User';

  if (user.permissions.includes('staff')) {
    return 'Staff';
  }

  if (user.orgs && user.orgs.length > 0) {
    return 'Org Admin';
  }

  return 'Logged In User';
}
