import { css, keyframes, styled } from 'styled-components';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { colors, cssBreakpoints, spacing, transition } from 'theme/theme';
import type { ToastVariant } from 'types/ToastData';

// TODO(@paprikka): this value doesn't exist in our design system but it's used
// repeatedly in the Figma designs.  let's just add it there.
export const ToastIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-inline-end: 20px;
  align-self: flex-start;
`;

const variants = {
  default: {
    backgroundColor: colors.lightYellow,
    borderColor: colors.decorativeStraw,
  },
  warning: {
    backgroundColor: colors.lightRed,
    borderColor: colors.alertRed,
  },
  success: {
    backgroundColor: colors.lightSage,
    borderColor: colors.brandGreen,
  },
  info: {
    backgroundColor: colors.backgroundBlue,
    borderColor: colors.brandBlue,
  },
  alternate: {
    backgroundColor: colors.lightPurple,
    borderColor: colors.brightPlum,
  },
};

export const ToastHeadline = styled(Text)`
  color: ${colors.darkContentGrey};
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
`;

export const ToastBody = styled(Text)`
  grid-area: body;
  font-size: 14px;
`;

export const ToastActionsAndContent = styled.div<{
  $layout?: 'vertical' | 'compact';
}>`
  flex: 1;
  display: grid;
  grid-template-columns: auto;
  gap: ${spacing[24]} ${spacing[70]};
  align-items: center;
  margin-inline-end: ${spacing[40]};

  & :last-child {
    justify-self: end;
  }

  /* TODO: we should probably drop that vertical layout completely and rely on
   * media queries only, otherwise this will get quite confusing */

  ${({ $layout }) =>
    $layout === 'compact' &&
    css`
      @media (min-width: ${cssBreakpoints.xsUp}) {
        grid-template-columns: auto max-content;
        & :last-child {
          justify-self: end;
        }
      }
    `}

  ${({ $layout }) =>
    $layout === 'vertical' &&
    css`
      grid-template-columns: auto;
      & :last-child {
        justify-self: end;
      }
    `}
`;

export const ToastContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${spacing[8]};
`;

export const ToastActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  &:empty {
    display: none;
  }
`;

export const DismissButtonBase = styled(ButtonOrLink)`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.7;
  transition: all ${transition};
  padding: 10px;

  &:hover {
    opacity: 1;
  }
`;

export function DismissButton({
  onClick,
  'data-qa-id': qaId,
}: {
  onClick: () => void;
  'data-qa-id'?: string;
}) {
  return (
    <DismissButtonBase type="button" onClick={onClick} data-qa-id={qaId}>
      <Icon name="close" colorName="lightContentGrey" size={20} />
    </DismissButtonBase>
  );
}

const onEnter = keyframes`
  from { transform: translateX(-70%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const onExit = keyframes`
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(-70%); opacity: 0; }
`;

const onExitMobile = keyframes`
  from { transform: translateY(0); opacity: 1; }
  to { transform: translateY(100%); opacity: 0; }
`;

export const animationDurationMs = 600;

export const ToastWrapper = styled.div<{
  $variant: ToastVariant;
  $isDismissing?: boolean;
  $isMobile?: boolean;
}>`
  max-width: calc(780px - var(--toast-list-offset) * 2);
  min-inline-size: 0;
  padding: ${spacing[24]} 20px;
  border-radius: 8px;
  color: ${colors.lightContentGrey};
  border: 1px solid;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spacing[24]};
  animation: ${onEnter} ${animationDurationMs}ms
    cubic-bezier(0.23, 1, 0.32, 0.99) both;
  touch-action: none;

  /*
    It makes more sense on desktop to keep the enter/exit animations on the same axis.
    On mobile, the animation should follow user's swipe.
  */

  ${({ $isMobile, $isDismissing }) => {
    if (!$isDismissing) return;
    const animationName = $isMobile ? onExitMobile : onExit;
    return css`
      animation-name: ${animationName};
    `;
  }}

  @media (min-width: ${cssBreakpoints.xsUp}) {
    padding-inline-end: ${spacing[40]};
    max-width: max-content;
  }

  background-color: ${({ $variant: variant = 'default' }) =>
    variants[variant].backgroundColor};
  border-color: ${({ $variant: variant = 'default' }) =>
    variants[variant].borderColor};
`;

export const ToastLayout = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
`;
