import queryString from 'query-string';
import { useEffect, useRef } from 'react';

import {
  useEnvironment,
  useUser,
  useUserEnvironmentActions,
} from 'contexts/UserEnvironmentContext';

export function useOverrideABExperimentsWithUrlParams() {
  const { user } = useUser();
  const { environment } = useEnvironment();
  const { setABExperimentBucket, setUserEnvironment } =
    useUserEnvironmentActions();

  const triggeredRef = useRef(false);

  useEffect(() => {
    if (!environment || triggeredRef.current) return;
    triggeredRef.current = true;

    const queryParams = queryString.parse(window.location.search);
    const overrides = Object.keys(queryParams).reduce((acc, param) => {
      const matches = param.match(/^ab_test\[([^\]]+)\]$/);

      if (matches) {
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
        acc.push({ id: matches[1], bucket: queryParams[param] });
      }

      return acc;
    }, []);

    overrides.forEach(({ id, bucket }) => {
      const experiment = environment.abExperiments.find((e) => e.id === id);

      if (!experiment || experiment.bucket !== bucket) {
        setABExperimentBucket(id, bucket);
      }
    });
  }, [environment, setABExperimentBucket, setUserEnvironment, user]);
}
