import type {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FocusEvent,
} from 'react';
import { useCallback, useRef } from 'react';
import { sprintf } from 'sprintf-js';

import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import type { InputProps } from 'components/Input/InputProps';
import { InputClearButtonSuffix } from 'components/Input/affixes/InputClearButtonSuffix';
import { ClickOutside } from 'utils/ClickOutside';

import {
  AutocompleteHolder,
  AutocompleteSuggestion,
  AutocompleteSuggestions,
  AutocompleteSuggestionsHolder,
  AutocompleteTitle,
} from './Autocomplete.styled';
import { useAutocomplete } from './useAutocomplete';

type Props = {
  inputProps: InputProps;
  onChange: ChangeEventHandler;
  onClear?: () => void;
  onSuggestionSelected?: (suggestion: string) => void;
  openOnFocus: boolean;
  presentation: ComponentProps<typeof Input>['styleVariant'];
  qaId?: string;
  shouldShowSuggestions: (inputValue: string) => boolean;
  suggestions: string[];
  title: string;
};

export function Autocomplete({
  inputProps,
  onChange,
  onClear,
  onSuggestionSelected,
  openOnFocus = false,
  presentation,
  qaId,
  shouldShowSuggestions,
  suggestions,
  title,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { defaultValue, id, maxLength, placeholder } = inputProps;

  const {
    setShowSuggestions,
    showSuggestions,
    suggestionsRef,
    handleInputKeyDown,
    handleInputChange,
    handleInputFocus,
    handleSuggestionClick,
  } = useAutocomplete(
    inputRef,
    openOnFocus,
    shouldShowSuggestions,
    onSuggestionSelected,
  );

  const clearInput = useCallback(() => {
    if (inputRef.current) inputRef.current.value = '';
    onClear?.();
    setTimeout(() => inputRef.current?.focus(), 0);
  }, [onClear]);

  const suggestionsContainerId = 'autocomplete';

  return (
    <ClickOutside onClickOutside={() => setShowSuggestions(false)}>
      <AutocompleteHolder onKeyDown={handleInputKeyDown}>
        <Input
          key={defaultValue?.toString()}
          inputProps={{
            ref: inputRef,
            id,
            name: 'q',
            placeholder,
            defaultValue,
            qaId,
            maxLength,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e);
              onChange(e);
            },
            onFocus: (e: FocusEvent<HTMLInputElement>) => {
              handleInputFocus(e);
            },
            'aria-expanded': showSuggestions,
            autoComplete: 'off',
            'aria-controls': suggestionsContainerId,
            role: 'combobox',
          }}
          suffix={
            <InputClearButtonSuffix
              ariaLabel="Clear search input"
              onClear={clearInput}
              qaId="search-input-clear"
            />
          }
          hideSuffixWhenEmpty
          styleVariant={presentation}
          affixVariant="transparent"
        />

        {showSuggestions && suggestions.length > 0 && (
          <AutocompleteSuggestionsHolder id={suggestionsContainerId}>
            <AutocompleteTitle>{title}</AutocompleteTitle>
            <AutocompleteSuggestions
              data-qa-id="autocomplete-suggestions"
              role="listbox"
              ref={suggestionsRef}
            >
              {suggestions.map((suggestion: string, index) => (
                <AutocompleteSuggestion
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  type="button"
                  aria-label={sprintf(
                    getText('Popular Search Term - %(suggestion)s'),
                    { suggestion },
                  )}
                  data-qa-id="autocomplete-suggestion"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <Icon name="search" size={20} />
                  {suggestion}
                </AutocompleteSuggestion>
              ))}
            </AutocompleteSuggestions>
          </AutocompleteSuggestionsHolder>
        )}
      </AutocompleteHolder>
    </ClickOutside>
  );
}
