import type { PostOnIdealistDropdownData } from 'components/PostOnIdealistDropdown/types/PostOnIdealistDropdownData';
import { postAListingRoute } from 'routing/routes';

export const postOnIdealistDropdownDataPt: PostOnIdealistDropdownData = {
  search: {
    title: 'Anunciar',
    items: [
      {
        type: 'post-a-listing',
        title: 'Vaga ou Evento',
        href: postAListingRoute.with({}),
      },
      {
        type: 'create-org',
        title: 'Cadastre sua Organização',
        href: '/organizations/create',
      },
      {
        type: 'create-community-group',
        title: 'Cadastre seu Coletivo',
        href: '/organizations/create',
      },
    ],
  },
  resources: {
    title: 'Recursos',
    items: [
      {
        type: 'help-desk',
        title: 'Central de Atendimento',
        description:
          'Perguntas frequentes, suporte técnico e formas de se conectar com o movimento idealista',
        href: '/pt/ajuda',
      },
    ],
  },
};
