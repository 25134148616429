import type { ApiGuessedLocation } from 'api/types/ApiGuessedLocation';
import type {
  ApiEnvironment,
  ApiUser,
  ApiUserEnvironmentUserLocation,
} from 'api/userEnvironment/types/ApiUserEnvironment';
import { getItem } from 'utils/localStorage';

export function isStaff(user: ApiUser | null) {
  return user?.permissions.includes('staff');
}

export function isAdmin(user: ApiUser | null) {
  if (!user) return false;

  return (user.orgs?.length || 0) > 0;
}

export function isNonAdmin(user: ApiUser | null) {
  if (!user) return true;

  return Boolean(
    !user.permissions.includes('staff') &&
      !user.permissions.includes('orgAdmin'),
  );
}

export function isLocationInCountry(user: ApiUser | null, countryCode: string) {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  return user && user.location && user.location.countryCode === countryCode;
}

export function isSalarySurveyCompleted(user: ApiUser | null | undefined) {
  if (user) {
    return Boolean(user.salarySurvey.lastSubmitted);
  }

  return Boolean(getItem('salarySurveyLastSubmitted'));
}

export function salarySurveyLastSubmittedDate(user: ApiUser | null) {
  if (user) {
    return user.salarySurvey.lastSubmitted;
  }

  const localStorageLastSubmitted = getItem('salarySurveyLastSubmitted');

  if (Array.isArray(localStorageLastSubmitted)) {
    return localStorageLastSubmitted[0];
  }

  return null;
}

export function getUserLocation(
  user: ApiUser | null,
  environment: ApiEnvironment,
): ApiUserEnvironmentUserLocation | ApiGuessedLocation {
  if (user) {
    return user.location;
  }

  return environment.guessedLocation;
}
