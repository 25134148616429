import type { CSSProperties, ReactNode } from 'react';
import { useMemo } from 'react';

import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import { IDEALIST_UTM_PARAMS } from 'utils/constants/general/idealistUtmParams';
import { getRelativeURL } from 'utils/url/getRelativeURL';
import { hasUTMParam } from 'utils/url/hasUTMParam';
import { isExternalURL } from 'utils/url/isExternalURL';
import { pathWithParams } from 'utils/url/pathWithParams';

import type { ButtonOrLinkDataProps } from './ButtonOrLinkData';

export type ButtonOrLinkOwnProps = {
  id?: string;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
  tabIndex?: number;
  title?: string;
  'data-qa-id'?: string;
  'aria-label'?: string;
};

type Props = ButtonOrLinkDataProps<ButtonOrLinkOwnProps>;

/**
 * This component is a switch between the multiple types of link that we have
 *
 * It allows using the same style regardless of the target element being a button,
 * an external link, or a react-router link
 */
export function ButtonOrLink({
  id,
  className,
  children,
  style,
  title,
  tabIndex,
  'data-qa-id': qaId,
  'aria-label': ariaLabel,
  ...otherProps
}: Props) {
  const data = useMemo(() => {
    if (!('data' in otherProps)) return otherProps;

    const { data: otherPropsData, ...remainingOtherProps } = otherProps;
    return { ...otherPropsData, ...remainingOtherProps };
  }, [otherProps]);

  const sharedProps = {
    id,
    className,
    style,
    title,
    tabIndex,
    'aria-label': ariaLabel,
    'data-qa-id': qaId,
  };

  switch (data.type) {
    case 'inert':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <div {...sharedProps}>{children}</div>;
    case 'button':
      return (
        <button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedProps}
          type="button"
          onClick={data.onClick}
          disabled={data.disabled}
          onBlur={data.onBlur}
          onFocus={data.onFocus}
          onMouseOver={data.onMouseOver}
          onMouseOut={data.onMouseOut}
          onTouchStart={data.onTouchStart}
          onTouchMove={data.onTouchMove}
          onTouchEnd={data.onTouchEnd}
        >
          {children}
        </button>
      );
    case 'submit':
      return (
        <button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedProps}
          type="submit"
          onClick={data.onClick}
          disabled={data.disabled}
          onBlur={data.onBlur}
          onFocus={data.onFocus}
          onMouseOver={data.onMouseOver}
          onMouseOut={data.onMouseOut}
          onTouchStart={data.onTouchStart}
          onTouchMove={data.onTouchMove}
          onTouchEnd={data.onTouchEnd}
          data-qa-submitting={data['data-qa-submitting']}
          data-ready={data['data-ready']}
        >
          {children}
        </button>
      );
    case 'link-with-state':
      return (
        <VersionedRouterLink
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedProps}
          to={data.to}
          state={data.state}
          onClick={data.onClick}
        >
          {children}
        </VersionedRouterLink>
      );
    case 'link': {
      const external = isExternalURL(data.href);

      if (data.nativeAnchorTag || data.target || data.download || external) {
        return (
          <a
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sharedProps}
            href={
              external && !hasUTMParam(data.href)
                ? pathWithParams(data.href, IDEALIST_UTM_PARAMS)
                : data.href
            }
            target={external ? '_blank' : data.target}
            onClick={data.onClick}
            download={data.download}
            rel={external ? 'noopener noreferrer' : undefined}
          >
            {children}
          </a>
        );
      }

      return (
        <VersionedRouterLink
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sharedProps}
          to={getRelativeURL(data.href)}
          onClick={data.onClick}
        >
          {children}
        </VersionedRouterLink>
      );
    }
  }
}
