import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { trackClicked } from 'utils/analytics/track/trackClicked';
import {
  trackDismissToast,
  trackDisplayToast,
} from 'utils/analytics/track/trackToast';
import { dismissToast, showToast } from 'zustand-stores/toastsStore';

export const useOrgSavedToast = () => {
  const navigate = useNavigate();

  const showOrgSavedToast = useCallback(() => {
    trackDisplayToast({
      type: 'success_saved_org',
      current_page_url: window.location.href,
    });

    showToast({
      headline: getText("You've saved this organization"),
      id: 'org-saved-toast',
      durationMs: 5000,
      dismissible: true,
      variant: 'success',
      actions: [
        {
          label: getText('See all'),
          onClick: () => {
            trackClicked('Action Toast', {
              type: 'success_saved_org',
              current_page_url: window.location.href,
            });
            navigate('/dashboard/saved-items?tab=ORG');
            dismissToast('org-saved-toast');
          },
          type: 'button',
        },
      ],
      onDismiss: () => {
        trackDismissToast({
          type: 'success_saved_org',
          current_page_url: window.location.href,
        });
      },
    });
  }, [navigate]);

  return { showOrgSavedToast };
};
