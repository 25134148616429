import type { ApiJobId, ApiOrgId } from 'api/types/ApiTypedId';
import type { OrgDashboardApiSuggestedApplicantMini } from 'modules/orgDashboard/api/types/OrgDashboardApiSuggestedApplicantMini';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  suggestedApplicants: OrgDashboardApiSuggestedApplicantMini[];
};

function mapResponse(response: ApiResponse) {
  return response.suggestedApplicants;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId, jobId: ApiJobId) =>
    `/data/orgdashboard/${orgId}/jobs/${jobId}/suggested-applicants`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchRecommendedCandidates =
  endpoint.createFetchFn();

export const orgDashboardApiHorizonFetchRecommendedCandidates =
  endpoint.createHorizonFetchFn();
