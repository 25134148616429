import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetCheckboxOptions } from 'modules/search/components/Facet/Options/SearchFacetCheckboxOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { EDUCATION_LEVEL_CHECKBOX_OPTIONS } from 'utils/constants/general/educationLevels';

type Props = {
  variant?: SearchRefinementVariant;
};

export function EducationFacet({ variant = 'dropdown' }: Props) {
  const selectedFacetValues = useSearchFacetSelectedValues('education');

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  return (
    <FacetVariant
      facetType="education"
      icon="open-book"
      label={
        <>
          {getText('Education')}{' '}
          <TextBadge content={selectedFacetValues.length} />
        </>
      }
    >
      <SearchFacetCheckboxOptions
        facetType="education"
        initialValue={selectedFacetValues}
        options={EDUCATION_LEVEL_CHECKBOX_OPTIONS()}
      />
    </FacetVariant>
  );
}
