import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { InvalidLinkPageLoadable } from './InvalidLinkPageLoadable';

export async function loader() {
  await InvalidLinkPageLoadable.load();
}

export default function InvalidLinkHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <InvalidLinkPageLoadable />;
}
