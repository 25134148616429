import { useCallback, useEffect, useReducer, useState } from 'react';

import type {
  ListingApplicationPayload,
  NonAtsListingApplicationPayload,
} from 'api/ats';
import { useUserEnvironment } from 'contexts/UserEnvironmentContext';
import type { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import type { SalariesReportFormData } from 'modules/salaries/types/SalariesReportFormData';
import type { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';
import { del } from 'utils/http/del';
import { getJson } from 'utils/http/getJson';
import type {
  Action,
  SaveListingType,
  SubscribeSubsiteType,
} from 'utils/temporaryStorage';
import { saveTemporaryStorage } from 'utils/temporaryStorage';
import { removeParam } from 'utils/url/removeParam';

export type TemporaryStorageState = {
  initialValues: ListingApplicationPayload | NonAtsListingApplicationPayload;
  savedSearch: ApiSavedSearchUnsaved;
  cmsSubscribe: CmsApiSubscribe;
  redirectUrl: string;
  savedListing: SaveListingType;
  subscribedSubsite: SubscribeSubsiteType;
  surveyReport: SalariesReportFormData;
};

export function useTemporaryStorage(
  tmpId: string | null | undefined,
  skipRequiredInfo?: boolean,
): [(actions: Action[]) => Promise<string>, TemporaryStorageState, boolean] {
  const [loaded, setLoaded] = useState(false);
  const { user } = useUserEnvironment();
  const isLoggedIn = user && (user.emailVerified || skipRequiredInfo);
  const hasRequiredInfo = Boolean(user?.firstName && user?.lastName);

  const memoizedReducer = useCallback(
    (state: TemporaryStorageState, action: Action): TemporaryStorageState => {
      switch (action.type) {
        case 'LISTING_APPLICATION_VALUES':
          return {
            ...state,
            initialValues: action.data,
          };

        case 'SAVE_SEARCH':
          return { ...state, savedSearch: action.data };

        case 'CMS_SUBSCRIBE':
          return {
            ...state,
            cmsSubscribe: action.data,
          };

        case 'SIGNUP_BLOCK_REDIRECT': {
          return { ...state, ...action.data };
        }

        case 'SAVE_LISTING': {
          return { ...state, savedListing: action.data };
        }

        case 'SUBSCRIBE_SUBSITE': {
          return {
            ...state,
            subscribedSubsite: action.data,
          };
        }

        case 'SURVEY_REPORT_DATA': {
          return {
            ...state,
            surveyReport: action.data,
          };
        }
      }
    },
    [],
  );
  const [temporaryStorage, dispatch] = useReducer(
    memoizedReducer,
    {} as TemporaryStorageState,
  );
  useEffect(() => {
    const mapTemporaryStorageToState = (serializedTemporaryStorage: string) => {
      if (serializedTemporaryStorage) {
        JSON.parse(serializedTemporaryStorage).forEach((action: Action) =>
          dispatch(action),
        );
      }
    };

    const deleteTemporaryStorage = (temporaryStorageId: string) =>
      del(`/data/tmp-storage/${temporaryStorageId}`);

    const getTemporaryStorage = (
      temporaryStorageId: string,
    ): Promise<{ data: string }> =>
      getJson(`/data/tmp-storage/${temporaryStorageId}`);

    const processTemporaryStorage = (temporaryStorageId: string) =>
      getTemporaryStorage(temporaryStorageId).then(({ data }) => {
        mapTemporaryStorageToState(data);
        setLoaded(true);
        deleteTemporaryStorage(temporaryStorageId);
        const newUrl = `${window.location.pathname}?${removeParam(
          window.location.search.replace('?', ''),
          'tmpId',
        )}`;
        window.history.replaceState(
          {
            path: newUrl,
          },
          '',
          newUrl,
        );
      });

    if (
      tmpId &&
      !loaded &&
      isLoggedIn &&
      (hasRequiredInfo || skipRequiredInfo)
    ) {
      processTemporaryStorage(tmpId);
    }
  }, [hasRequiredInfo, isLoggedIn, loaded, skipRequiredInfo, tmpId]);

  return [saveTemporaryStorage, temporaryStorage, loaded];
}
