import { Button } from 'components/Button/Button';
import { DatePicker } from 'components/Date/DatePicker';
import { Label } from 'components/Label/Label';
import { VerticalSpacer } from 'components/VerticalSpacer/VerticalSpacer';
import { SearchDateRangeAccordionItem } from 'modules/search/components/DateRange/AccordionItem/SearchDateRangeAccordionItem';
import { SearchDateRangeDropdown } from 'modules/search/components/DateRange/Dropdown/SearchDateRangeDropdown';
import { useSearchDateRangeReset } from 'modules/search/hooks/dateRange/useSearchDateRangeReset';
import { useSearchDateRange } from 'modules/search/hooks/facets/useSearchDateRange';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { spacing } from 'theme/theme';

type Props = {
  variant?: SearchRefinementVariant;
};

export function ActionDateRange({ variant = 'dropdown' }: Props) {
  const { startsLT, endsGT, onChangeStart, onChangeEnd } =
    useSearchDateRange('actionDate');

  const resetFacet = useSearchDateRangeReset('actionDate');

  const DateRangeVariant =
    variant === 'accordion'
      ? SearchDateRangeAccordionItem
      : SearchDateRangeDropdown;

  return (
    <DateRangeVariant
      facetType="actionDate"
      icon="calendar"
      label={getText('Date')}
    >
      <Label data-qa-id="endsGT-label">{getText('Start Date')}</Label>
      <DatePicker
        value={endsGT}
        onChange={onChangeStart}
        placeholder={getText('Select')}
        name="endsGT"
        disablePastDates
        isTimeZoneAware
      />
      <VerticalSpacer desktop={spacing[16]} mobile={spacing[16]} />
      <Label data-qa-id="startsLT-label">{getText('End Date')}</Label>
      <DatePicker
        value={startsLT}
        onChange={onChangeEnd}
        placeholder={getText('Select')}
        name="startsLT"
        disablePastDates
        isTimeZoneAware
      />
      <VerticalSpacer mobile={spacing[24]} desktop={spacing[24]} />
      <Button variant="secondary" type="button" onClick={resetFacet}>
        {getText('Reset')}
      </Button>
    </DateRangeVariant>
  );
}
