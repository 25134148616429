import { styled } from 'styled-components';

import { colors, cssBreakpoints, spacing } from 'theme/theme';

const SEARCH_REFINEMENT_CHIP_WIDTH = {
  en: '165px',
  es: '165px',
  pt: '180px',
};

export const SearchRefinementsHolder = styled.form<{ $locale: UserLocale }>`
  z-index: 1;
  background: ${colors.white};
  border: 1px solid ${colors.selectionGrey};
  padding: ${spacing[16]} ${spacing[24]};

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    overflow-x: scroll;

    scrollbar-width: none;

    &:has(details[open]) {
      margin-bottom: calc(
        -1 * var(--search-refinements-active-dropdown-height)
      );
      padding-bottom: calc(
        var(--search-refinements-active-dropdown-height, 16px) + 16px
      );
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .search-refinements-body > :last-child {
      display: none;
    }
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    .search-refinements-body > :nth-child(n + 5):not(:last-child) {
      display: none;
    }

    .search-refinements-body > :nth-last-of-type(1):nth-of-type(-n + 5) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]})) {
    .search-refinements-body > :nth-child(4):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 2)) {
    .search-refinements-body > :nth-child(5):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 3)) {
    .search-refinements-body > :nth-child(6):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 4)) {
    .search-refinements-body > :nth-child(7):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 5)) {
    .search-refinements-body > :nth-child(8):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 6)) {
    .search-refinements-body > :nth-child(9):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 7)) {
    .search-refinements-body > :nth-child(10):not(:last-child) {
      display: initial;
    }
  }

  /* Overflow menu visibility tracking */

  /* JobFamilyFilter should never appear in overflow menu */
  & > div > :last-child menu [data-facet-type='synonymId'] {
    display: none;
  }

  /* First 3 always hidden */
  & > div > :last-child menu details:nth-of-type(-n + 3) {
    display: none;
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]})) {
    & > div > :last-child menu details:nth-of-type(4) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 2)) {
    & > div > :last-child menu details:nth-of-type(5) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} *
    3)) {
    & > div > :last-child menu details:nth-of-type(6) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} *
    4)) {
    & > div > :last-child menu details:nth-of-type(7) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} *
    5)) {
    & > div > :last-child menu details:nth-of-type(8) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} *
    6)) {
    & > div > :last-child menu details:nth-of-type(9) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${(props) =>
      SEARCH_REFINEMENT_CHIP_WIDTH[props.$locale]} * 7)) {
    & > div > :last-child menu details:nth-of-type(10) {
      display: none;
    }
  }
`;

export const SearchRefinementsBody = styled.div`
  display: flex;
  gap: ${spacing[24]};
`;
