import type {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
} from 'react';
import { styled } from 'styled-components';

import { Input } from 'components/Input/Input';
import type { InputProps } from 'components/Input/InputProps';
import { InputIconAffix } from 'components/Input/affixes/InputIconAffix';

export const DatePickerDateInput = styled(
  ({
    className,
    inputProps,
    value: dateValue,
    onChange,
    onClick,
    onKeyDown,
  }: {
    className?: string;
    inputProps: InputProps;
    value?: string;
    onChange?: ChangeEventHandler;
    onClick?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
  }) => (
    <div className={className}>
      <Input
        inputProps={{
          ...inputProps,
          onChange,
          onClick,
          value: dateValue,
          onKeyDown,
        }}
        affixVariant="transparent"
        suffix={<InputIconAffix iconName="calendar" />}
      />
    </div>
  ),
)`
  & input::-webkit-inner-spin-button {
    display: none;
  }
  & input::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
