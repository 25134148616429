import { useCallback } from 'react';
import { useLocation } from 'react-router';

import { useLatest } from 'hooks/useLatest';
import { searchGetTrackingData } from 'modules/search/helpers/analytics/searchGetTrackingData';
import type { SearchFromPageType } from 'modules/search/types/SearchFromPageType';

type LocationState = {
  searchFrom?: string;
  searchFromPageType?: SearchFromPageType;
};

export function useSearchAnalytics() {
  const location = useLocation<LocationState>();

  // Don't trigger analytics change just because the searchFrom value changed
  const searchFromRef = useLatest(location.state?.searchFrom);
  const searchFromPageTypeRef = useLatest(location.state?.searchFromPageType);

  const getSearchTrackingData = useCallback(
    () =>
      searchGetTrackingData({
        searchFrom: searchFromRef.current,
        searchFromPageType: searchFromPageTypeRef.current,
      }),
    [searchFromPageTypeRef, searchFromRef],
  );

  return { getSearchTrackingData };
}
