import type { SearchFacetData } from 'modules/search/types/SearchFacetData';

export const jobFamilyFacet = {
  id: 'jobFamilyFacet',
  name: 'synonymId',
  filterKey: 'compdb.familyId',
  get title() {
    return getText('Similar Jobs');
  },
  type: 'string',
  options: [],
  optionTitles: {},
  hideInSideFilter: true,
} as const satisfies SearchFacetData;
