import type { SearchParamsObject } from '@algolia/client-search';

import type { SearchLocation } from 'modules/search/types/SearchLocation';

import { searchAlgoliaOptionsLocationData } from './searchAlgoliaOptionsLocationData';

type Args = {
  options: SearchParamsObject;
  searchLocation: SearchLocation | undefined;
  radius: string;
};

export function searchAlgoliaOptionsWithSearchLocation({
  options,
  searchLocation,
  radius,
}: Args): SearchParamsObject {
  if (!searchLocation?.latitude || !searchLocation?.longitude) return options;

  const { locationFilters, locationOptions } = searchAlgoliaOptionsLocationData(
    radius,
    searchLocation,
  );

  return {
    ...options,
    filters: [options.filters, ...locationFilters]
      .filter(Boolean) // Filter options.filters if it's empty
      .join(' AND '),
    ...locationOptions,
  } satisfies SearchParamsObject;
}
