export const SEARCH_VOLOP_REFINEMENT_TYPES = [
  'areasOfFocus',
  'functions',
  'locationType',
  'locale',
  'radius',
  'actionDate',
  'actionType',
  'source',
  'date',
  'orgType',
  'recency',
] as const;

export type SearchVolopRefinementType =
  (typeof SEARCH_VOLOP_REFINEMENT_TYPES)[number];
