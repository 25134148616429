import { useSearchRefinementDropdown } from 'modules/search/components/Refinement/Dropdown/useSearchRefinementDropdown';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/facets/useSearchFacetSelectedValues';

export function useSearchFacetDropdown(facetType: SearchRefinementType) {
  const selectedFacetValues = useSearchFacetSelectedValues(facetType);

  const hasSelectedFacetValues = selectedFacetValues.length > 0;

  const { holderRef, toggleDropdown, dropdownStatus } =
    useSearchRefinementDropdown(facetType, hasSelectedFacetValues);

  return { holderRef, dropdownStatus, toggleDropdown };
}
