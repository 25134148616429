export const SEARCH_JOBS_REFINEMENT_TYPES = [
  'areasOfFocus',
  'education',
  'functions',
  'jobType',
  'locale',
  'locationType',
  'orgType',
  'professionalLevel',
  'radius',
  'recency',
  'synonymId',
  'payment',
] as const;

export type SearchJobsRefinementType =
  (typeof SEARCH_JOBS_REFINEMENT_TYPES)[number];
