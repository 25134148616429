import type { MouseEvent } from 'react';

import { trackClicked } from './trackClicked';

/**
 * Wrapper for `trackClicked` that takes the `href` from `event.currentTarget`
 * and sets it as the `url` event property
 */
export function trackAnchorClicked(
  event: MouseEvent<HTMLElement>,
  name: string,
  properties: Record<string, unknown> = {},
) {
  trackClicked(name, {
    ...properties,
    url: event.currentTarget.getAttribute('href'),
  });
}
