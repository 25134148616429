import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardViewJobRecommendedCandidateRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardViewJobRecommendedCandidatePageLoadable } from './OrgDashboardViewJobRecommendedCandidatePageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: false,
  darkBackground: true,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<
    typeof orgDashboardViewJobRecommendedCandidateRoute
  >,
) {
  await OrgDashboardViewJobRecommendedCandidatePageLoadable.load();
}

export default function OrgDashboardViewJobRecommendedCandidateHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <OrgDashboardViewJobRecommendedCandidatePageLoadable />;
}
