import { apiHorizonFetchInvite } from 'api/invite/apiHorizonFetchInvite';
import type { authUserInviteRoute } from 'modules/auth/routing/auth.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { UserInvitePageLoadable } from './UserInvitePageLoadable';

export async function loader({
  request,
  context,
  params: { inviteId },
}: HorizonAppRouteLoaderArgs<typeof authUserInviteRoute>) {
  const [invite] = await Promise.all([
    apiHorizonFetchInvite({ request, context })(inviteId),
    UserInvitePageLoadable.load(),
  ]);

  return { invite };
}

export default function UserInviteHorizonRoute({
  loaderData: { invite },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return <UserInvitePageLoadable invite={invite} />;
}
