import type { ReactNode } from 'react';

import { useIsClient } from 'hooks/useIsClient';

type Props = {
  children: ReactNode;
  placeholder?: ReactNode;
};

export function ClientOnly({ children, placeholder = null }: Props) {
  const isClient = useIsClient();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isClient ? children : placeholder}</>;
}
