import type { ApiVolopId } from 'api/types/ApiTypedId';
import type { ApiUnpublishedVolop } from 'modules/listing/api/volop/types/ApiUnpublishedVolop';
import type { ApiVolop } from 'modules/listing/api/volop/types/ApiVolop';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  data: ApiVolop | ApiUnpublishedVolop;
};

function mapResponse(response: ApiResponse): ApiVolop | ApiUnpublishedVolop {
  return response.data;
}

const endpoint = new FetchEndpoint({
  urlFactory: (volopId: ApiVolopId) => `/data/website/volops/${volopId}`,
  mapResponse,
});

// Exports

export const listingApiHorizonFetchVolop = endpoint.createHorizonFetchFn();
