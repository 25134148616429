import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import type { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { getContentLocaleUrl } from 'modules/cms/helpers/getContentLocaleUrl';

type Props = {
  content: CmsApiPage;
};

export function getPostSearchPageLayoutHandleProps({
  content,
}: Props): LayoutHandleProps {
  const localeUrl = getContentLocaleUrl({ content });

  return {
    urlByLocale: localeUrl,
    darkBackground: true,
    showDesktopSearch: true,
  };
}
