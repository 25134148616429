import { LayoutCenteredBody } from 'layouts/Layout/LayoutCenteredBody';
import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardCreateJobRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { CreateJobPageContainerLoadable } from './CreateJobPageContainerLoadable';

export const handle = {
  variant: 'base',
  orgDashboardSubLayoutVariant: 'base',
  darkBackground: true,
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardCreateJobRoute>,
) {
  await CreateJobPageContainerLoadable.load();
}

export default function OrgDashboardCreateJobHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return (
    <LayoutCenteredBody>
      <CreateJobPageContainerLoadable listingType="JOB" />
    </LayoutCenteredBody>
  );
}
