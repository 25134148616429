import 'trackjs';

import { setConfigOnce } from 'config/appConfigUtils';
import type { AppConfig } from 'config/types/AppConfig';
import { getApplicationContext } from 'utils/analytics/helpers/getApplicationContext';
import { registerMixpanelProperties } from 'utils/analytics/helpers/mixpanel';
import { initXMLHttpRequestActiveCounter } from 'utils/http/initXMLHttpRequestActiveCounter';
import { trackError } from 'utils/trackError';
import { initializeAppVersionStore } from 'zustand-stores/appVersionStore';

export function renderingBootstrapClient(config: AppConfig) {
  setConfigOnce(config);
  initializeAppVersionStore(config.idealist.versionNumber);

  registerMixpanelProperties({
    application_context: getApplicationContext(),
  });

  if (window.Idealist.debugXhr) {
    initXMLHttpRequestActiveCounter();
  }

  const { errorsBeforeTrackJsInstalled, trackErrorBeforeTrackJs } =
    window.Idealist;

  if (errorsBeforeTrackJsInstalled && trackErrorBeforeTrackJs) {
    errorsBeforeTrackJsInstalled.forEach((error) => trackError(error));
    window.removeEventListener('error', trackErrorBeforeTrackJs);
  }
}
