import type { ReactNode } from 'react';

import { ToastListWrapper } from './ToastList.styled';

type Props = {
  children: ReactNode;
};

export function ToastList({ children }: Props) {
  return (
    <ToastListWrapper data-qa-id="toast-list">{children}</ToastListWrapper>
  );
}
