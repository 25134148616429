import { useMemo } from 'react';

import { useAbExperimentBucket } from 'hooks/abExperiment/useAbExperimentBucket';
import { LocaleFacet } from 'modules/search/components/Facet/LocaleFacet';
import { LocationTypeFacet } from 'modules/search/components/Facet/LocationTypeFacet';
import { OrgAreasOfFocusFacet } from 'modules/search/components/Facet/OrgAreasOfFocusFacet';
import { OrgTypeFacet } from 'modules/search/components/Facet/OrgTypeFacet';
import { RadiusFacet } from 'modules/search/components/Facet/RadiusFacet';
import { RecencyFacet } from 'modules/search/components/Facet/RecencyFacet';
import { SearchRefinements } from 'modules/search/components/Refinements/SearchRefinements';

import { EducationFacet } from './EducationFacet';
import { EmploymentTypeFacet } from './EmploymentTypeFacet';
import { JobFamilyFilter } from './JobFamilyFilter';
import { JobKeywordsFacet } from './JobKeywordsFacet';
import { ProfessionalLevelFacet } from './ProfessionalLevelFacet';

export function SearchJobsRefinements() {
  const [bucketValue] = useAbExperimentBucket('NEW_SEARCH_2025_03');

  const refinementsByLocale = useMemo(
    () => ({
      en: [
        JobFamilyFilter,
        LocationTypeFacet,
        EmploymentTypeFacet,
        ProfessionalLevelFacet,
        OrgAreasOfFocusFacet,
        EducationFacet,
        JobKeywordsFacet,
        RecencyFacet,
        OrgTypeFacet,
        LocaleFacet,
        RadiusFacet,
      ],
      es: [
        JobFamilyFilter,
        LocationTypeFacet,
        JobKeywordsFacet,
        LocaleFacet,
        OrgAreasOfFocusFacet,
        RecencyFacet,
        EducationFacet,
        ProfessionalLevelFacet,
        EmploymentTypeFacet,
        OrgTypeFacet,
        RadiusFacet,
      ],
      pt: [
        JobFamilyFilter,
        LocationTypeFacet,
        JobKeywordsFacet,
        OrgAreasOfFocusFacet,
        LocaleFacet,
        ProfessionalLevelFacet,
        EducationFacet,
        EmploymentTypeFacet,
        RecencyFacet,
        OrgTypeFacet,
        RadiusFacet,
      ],
    }),
    [],
  );

  return bucketValue === 'ENABLED' ? (
    <SearchRefinements listingType="JOB">
      {refinementsByLocale[CURRENT_LOCALE()].map((Component) => (
        <Component key={Component.name} />
      ))}
    </SearchRefinements>
  ) : null;
}
