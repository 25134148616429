import type { ComponentProps } from 'react';
import { styled } from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'theme/theme';

import type { LegacyButton } from './LegacyButton';
import { LegacyButtonWithStyles } from './LegacyButtonWithStyles';

const StyledCloseButton = styled(LegacyButtonWithStyles)`
  padding: 8px 12px 8px 0;
  color: ${colors.lightContentGrey};

  &:hover {
    color: ${colors.brandBlue};

    &:disabled {
      &:hover {
        color: ${colors.lightContentGrey};
      }
    }
  }

  background-color: transparent;
  border-radius: 0;
`;

export function LegacyCloseButton({
  children,
  ...props
}: ComponentProps<typeof LegacyButton>) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledCloseButton value="Close" p={0} {...props}>
      {children || <Icon name="close" size={13} />}
    </StyledCloseButton>
  );
}

export function LegacyModalCloseButton({
  children,
  ...props
}: ComponentProps<typeof LegacyButton>) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledCloseButton value="Close" p={0} {...props}>
      {children || <Icon name="close" size={24} />}
    </StyledCloseButton>
  );
}
