import type { ApiListingApplicationId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { UserDashboardApiListingApplication } from './types/UserDashboardApiListingApplication';

type ApiResponse = {
  listingApplication: UserDashboardApiListingApplication;
};

function mapResponse(response: ApiResponse) {
  return response.listingApplication;
}

const endpoint = new FetchEndpoint({
  urlFactory: (applicationId: ApiListingApplicationId) =>
    `/data/listing-applications/${applicationId}`,
  mapResponse,
});

// Exports

export const userDashboardApiHorizonFetchListingApplication =
  endpoint.createHorizonFetchFn();
