import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardApiHorizonFetchInternship } from 'modules/orgDashboard/api/listing/orgDashboardApiFetchInternship';
import { orgDashboardApiHorizonFetchListingApplications } from 'modules/orgDashboard/api/listingApplication/orgDashboardApiFetchListingApplications';
import { orgDashboardViewInternshipRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { OrgDashboardOrgSubLayoutHandleProps } from 'modules/orgDashboard/sub-layouts/Org/OrgDashboardOrgSubLayoutHandleProps';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { OrgDashboardViewInternshipPageLoadable } from './OrgDashboardViewInternshipPageLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
  orgDashboardSubLayoutVariant: 'base',
} satisfies LayoutHandleProps & OrgDashboardOrgSubLayoutHandleProps;

export async function loader({
  request,
  context,
  params: { orgId, internshipId },
}: HorizonAppRouteLoaderArgs<typeof orgDashboardViewInternshipRoute>) {
  const [internship, listingApplications] = await Promise.all([
    orgDashboardApiHorizonFetchInternship({ request, context })(
      orgId,
      internshipId,
    ).catch(() => null),
    orgDashboardApiHorizonFetchListingApplications({ request, context })(
      orgId,
      { type: 'INTERNSHIP', id: internshipId },
    ),
    OrgDashboardViewInternshipPageLoadable.load(),
  ]);

  if (!internship) await NotFoundPageLoadable.load();

  return { internship, listingApplications };
}

export default function OrgDashboardViewInternshipHorizonRoute({
  loaderData: { internship, listingApplications },
}: HorizonAppRouteComponentProps<typeof loader>) {
  if (!internship) return <NotFoundPageLoadable noLayout />;

  return (
    <OrgDashboardViewInternshipPageLoadable
      key={internship.id}
      internship={internship}
      listingApplications={listingApplications}
    />
  );
}
