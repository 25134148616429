import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { searchOrgsRoutePathByLocale } from 'modules/search/routing/search.route-paths';
import type { searchOrgsRouteReference } from 'modules/search/routing/search.routes';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { SearchOrgsPageLoadable } from './SearchOrgsPageLoadable';
import { SearchOrgRefinements } from './components/SearchOrgRefinements';

export const handle = {
  searchRefinements: <SearchOrgRefinements />,
  urlByLocale: searchOrgsRoutePathByLocale,
  darkBackground: true,
  defaultSearchType: 'ORG',
  hideFooterDivider: true,
  showDesktopSearch: true,
  searchFromPageType: 'SEARCH',
  isSearchPage: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonLoaderFunctionArgs<typeof searchOrgsRouteReference>,
) {
  await SearchOrgsPageLoadable.load();
}

export default function SearchOrgsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <SearchOrgsPageLoadable />;
}
