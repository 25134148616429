import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { orgDashboardListInvoicesRoute } from 'modules/orgDashboard/routing/orgDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonAppRouteShouldRevalidateFunctionArgs } from 'routing/types/HorizonAppRouteShouldRevalidateFunctionArgs';

import { InvoicesListContainerLoadable } from './InvoicesListContainerLoadable';

export const handle = {
  fallbackUrlByLocaleToCurrent: true,
  darkBackground: true,
  showDesktopSearch: true,
} satisfies LayoutHandleProps;

// Don't revalidate when search params change, as they won't influence this loader
export function shouldRevalidate(
  _: HorizonAppRouteShouldRevalidateFunctionArgs<
    typeof orgDashboardListInvoicesRoute
  >,
) {
  return false;
}

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof orgDashboardListInvoicesRoute>,
) {
  await InvoicesListContainerLoadable.load();
}

export default function OrgDashboardInvoicesListHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <InvoicesListContainerLoadable />;
}
