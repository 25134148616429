import type { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { searchVolopsRoutePathByLocale } from 'modules/search/routing/search.route-paths';
import type { searchVolopsRouteReference } from 'modules/search/routing/search.routes';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import { SearchVolopsPageLoadable } from './SearchVolopsPageLoadable';
import { SearchVolopsRefinements } from './components/SearchVolopsRefinements';

export const handle = {
  searchRefinements: <SearchVolopsRefinements />,
  urlByLocale: searchVolopsRoutePathByLocale,
  darkBackground: true,
  defaultSearchType: 'VOLOP',
  hideFooterDivider: true,
  showDesktopSearch: true,
  searchFromPageType: 'SEARCH',
  isSearchPage: true,
} satisfies LayoutHandleProps;

export async function loader(
  _: HorizonLoaderFunctionArgs<typeof searchVolopsRouteReference>,
) {
  await SearchVolopsPageLoadable.load();
}

export default function SearchVolopsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <SearchVolopsPageLoadable />;
}
