import { apiFetchInvoice } from 'api/invoice/apiFetchInvoice';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { viewInvoiceRoute } from 'routing/routes';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ViewInvoicePageLoadable } from './ViewInvoicePageLoadable';

export async function loader({
  params: { invoiceId },
}: HorizonAppRouteLoaderArgs<typeof viewInvoiceRoute>) {
  const [data] = await Promise.all([
    apiFetchInvoice(invoiceId),
    ViewInvoicePageLoadable.load(),
  ]);

  return data;
}

export default function ViewInvoiceHorizonRoute({
  loaderData: { org, invoice },
}: HorizonAppRouteComponentProps<typeof loader>) {
  return <ViewInvoicePageLoadable org={org} invoice={invoice} />;
}
