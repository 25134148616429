import type { ApiInternshipId, ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { OrgDashboardApiInternship } from './types/OrgDashboardApiInternship';

type ApiResponse = {
  internship: OrgDashboardApiInternship;
};

function mapResponse(response: ApiResponse): OrgDashboardApiInternship {
  return response.internship;
}

const endpoint = new FetchEndpoint({
  urlFactory: (orgId: ApiOrgId, internshipId: ApiInternshipId) =>
    `/data/orgdashboard/${orgId}/internships/${internshipId}`,
  mapResponse,
});

// Exports

export const orgDashboardApiFetchInternship = endpoint.createFetchFn();

export const orgDashboardApiHorizonFetchInternship =
  endpoint.createHorizonFetchFn();

export const useOrgDashboardApiFetchInternship = endpoint.createUseFetchHook();
