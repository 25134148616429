import type {
  ApiJobSynonymFamilyId,
  ApiJobSynonymId,
} from 'api/types/ApiTypedId';
import type { SalariesJobSynonym } from 'modules/salaries/types/SalariesJobSynonym';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  familyId: ApiJobSynonymFamilyId;
  jobSynonym: SalariesJobSynonym;
};

function mapResponse(response: ApiResponse): {
  familyId: ApiJobSynonymFamilyId;
  jobSynonym: SalariesJobSynonym;
} {
  return response;
}

const endpoint = new FetchEndpoint({
  urlFactory: (jobSynonymId: ApiJobSynonymId) =>
    `/data/salary-surveys/job-synonyms/${jobSynonymId}`,
  mapResponse,
});

// Exports

export const salariesApiFetchJobSynonym = endpoint.createFetchFn();
