import type {
  CSSProperties,
  ForwardedRef,
  MouseEventHandler,
  ReactNode,
} from 'react';

import { useTabContext } from 'containers/TabContext';
import { IDEALIST_UTM_PARAMS } from 'utils/constants/general/idealistUtmParams';
import { hasUTMParam } from 'utils/url/hasUTMParam';
import { isExternalURL } from 'utils/url/isExternalURL';
import { pathWithParams } from 'utils/url/pathWithParams';

type UnstyledLinkProps = {
  children?: ReactNode;
  className?: string;
  target?: string;
  href: string;
  rel?: string;
  noIndex?: boolean;
  style?: CSSProperties;
  'data-qa-id'?: string;
  tabIndex?: number;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  title?: string;
  anchorRef?: ForwardedRef<HTMLAnchorElement>;
};

export function UnstyledNativeLink({
  anchorRef,
  children,
  href,
  rel,
  noIndex,
  style,
  target,
  ...props
}: UnstyledLinkProps) {
  const linkRel = isExternalURL(href)
    ? `noopener noreferrer${noIndex ? ' noindex' : ''}`
    : rel;
  const linkTarget = isExternalURL(href) ? '_blank' : target;
  const isTabbing = useTabContext();

  return (
    <a
      ref={anchorRef}
      href={
        isExternalURL(href) && !hasUTMParam(href)
          ? pathWithParams(href, IDEALIST_UTM_PARAMS)
          : href
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={isTabbing ? style : { ...style, outline: 0 }}
      rel={linkRel}
      target={linkTarget}
    >
      {children}
    </a>
  );
}
