import type { PreSignupReaction } from 'modules/cms/api/reactions/types/CmsApiReactionLocalstorage';

import { createLocalStorageStore } from './utils/createLocalStorageStore';

const IDEALIST_REACTION_KEY = 'idealistPreSignupReaction';

const { useValue, setValue } = createLocalStorageStore<PreSignupReaction>({
  key: IDEALIST_REACTION_KEY,
});

// Actions

export function setPreSignupReaction(reaction: PreSignupReaction | null) {
  setValue(reaction);
}

// Hooks

export function usePreSignupReaction() {
  const value = useValue();
  return { preSignupReaction: value };
}
