import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import { useSearchAnalytics } from 'modules/search/hooks/useSearchAnalytics';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import type { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import type { AreaOfFocus } from 'utils/constants/general/areasOfFocus';

export function useSearchFacetToggleOption(
  facetType: keyof SearchFiltersByName,
) {
  const [searchParams] = useSearchParams();
  const searchFiltersByName = useSearchFiltersByName();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);

  const navigate = useDeferredNavigate();

  const { getSearchTrackingData } = useSearchAnalytics();

  const toggle = useCallback(
    (name: AreaOfFocus) => {
      const searchFacetSelectedValues = searchFiltersByName
        ? searchFiltersByName[facetType] || []
        : [];

      const filterSelected = searchFacetSelectedValues.includes(name);

      if (filterSelected) {
        searchParamsRef.current.delete(facetType, name);
        (
          document.querySelector(
            `[data-facet-type=${facetType}] input[name=${name}]`,
          ) as HTMLInputElement
        ).checked = false;
      } else {
        searchParamsRef.current.append(facetType, name);
        (
          document.querySelector(
            `[data-facet-type=${facetType}] input[name=${name}]`,
          ) as HTMLInputElement
        ).checked = true;
      }

      // Toggling a filter should always reset results to the first page
      if (searchParamsRef.current.has('page')) {
        searchParamsRef.current.delete('page');
      }

      trackEvent('Changed Search Filter', {
        filter_name: facetType,
        [filterSelected ? 'selected_value' : 'deselected_value']: name,
        ...getSearchTrackingData(),
      });

      let newSearchParams;

      if (filterSelected) {
        newSearchParams = {
          ...searchFiltersByName,
          [facetType]: Array.isArray(searchFacetSelectedValues)
            ? searchFacetSelectedValues.filter((value) => value !== name)
            : undefined,
        };
      } else {
        newSearchParams = {
          ...searchFiltersByName,
          [facetType]: Array.isArray(searchFacetSelectedValues)
            ? [...searchFacetSelectedValues, name]
            : [searchFacetSelectedValues, name],
        };
      }

      const newRoute = searchGetRoutePathSwitch({
        lang: CURRENT_LOCALE(),
        q: searchParamsRef.current.get('q') || '',
        radius: searchParamsRef.current.get('radius') || '',
        filtersByName: {
          ...newSearchParams,
        },
      });

      navigate(newRoute, {
        replace: true,
      });
    },
    [
      facetType,
      getSearchTrackingData,
      navigate,
      searchFiltersByName,
      searchParamsRef,
    ],
  );

  return toggle;
}
