export function initXMLHttpRequestActiveCounter() {
  // @ts-expect-error TS(2339): Property '_allRequests' does not exist on type '{ ... Remove this comment to see the full error message
  // eslint-disable-next-line no-underscore-dangle
  XMLHttpRequest._allRequests = [];
  // @ts-expect-error TS(2339): Property '_completedRequests' does not exist on ty... Remove this comment to see the full error message
  // eslint-disable-next-line no-underscore-dangle
  XMLHttpRequest._completedRequests = [];
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const oldOpen = XMLHttpRequest.prototype.open;

  // @ts-expect-error TS(2322): Type '(args_0: string, args_1: string | URL, args_... Remove this comment to see the full error message
  XMLHttpRequest.prototype.open = function open(...args) {
    // TODO: Fix this the next time the file is edited.
    // @ts-expect-error TS(2339): Property '_url' does not exist on type 'XMLHttpReq... Remove this comment to see the full error message
    // eslint-disable-next-line prefer-destructuring, no-underscore-dangle
    this._url = args[1];
    // @ts-expect-error TS(2345): Argument of type '[string, string | URL, unknown, ... Remove this comment to see the full error message
    oldOpen.apply(this, args);
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const oldSend = XMLHttpRequest.prototype.send;

  XMLHttpRequest.prototype.send = function send(...args) {
    // @ts-expect-error TS(2339): Property '_url' does not exist on type 'XMLHttpReq... Remove this comment to see the full error message
    // eslint-disable-next-line no-underscore-dangle
    if (!this._url.includes('sockjs-node')) {
      // @ts-expect-error TS(2339): Property '_allRequests' does not exist on type '{ ... Remove this comment to see the full error message
      // eslint-disable-next-line no-underscore-dangle
      XMLHttpRequest._allRequests.push(this._url);

      this.addEventListener('loadend', () => {
        setTimeout(() => {
          // @ts-expect-error TS(2339): Property '_completedRequests' does not exist on ty... Remove this comment to see the full error message
          // eslint-disable-next-line no-underscore-dangle
          XMLHttpRequest._completedRequests.push(this._url);
        }, 1);
      });
    }

    oldSend.apply(this, args);
  };
}
