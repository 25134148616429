import type { RouteObject } from 'react-router';

import * as searchEventsHorizonRouteExports from 'modules/search/pages/Events/SearchEvents.horizon-route';
import * as searchInternshipsHorizonRouteExports from 'modules/search/pages/Internships/SearchInternships.horizon-route';
import * as searchJobsHorizonRouteExports from 'modules/search/pages/Jobs/SearchJobs.horizon-route';
import * as searchOrgsHorizonRouteExports from 'modules/search/pages/Orgs/SearchOrgs.horizon-route';
import { SavedSearchRedirectPageLoadable } from 'modules/search/pages/SavedSearchRedirect/SavedSearchRedirectPageLoadable';
import * as searchVolopsHorizonRouteExports from 'modules/search/pages/Volops/SearchVolops.horizon-route';
import { userDashboardApiFetchSavedSearch } from 'modules/userDashboard/api/savedSearch/userDashboardApiFetchSavedSearch';
import type { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import {
  searchEventsRoutePaths,
  searchInternshipsRoutePaths,
  searchJobsRoutePaths,
  searchOrgsRoutePaths,
  searchVolopsRoutePaths,
} from './search.route-paths';
import { savedSearchesRoute } from './search.routes';

export const searchRouteObjects = [
  {
    path: savedSearchesRoute.fullPath,
    ...renderLoader({
      loader: async ({
        params,
      }: AppRouteLoaderArgs<typeof savedSearchesRoute>) =>
        userDashboardApiFetchSavedSearch(params.savedSearchId as string),
      render: (savedSearch) => (
        <SavedSearchRedirectPageLoadable savedSearch={savedSearch} />
      ),
    }),
  },

  ...searchJobsRoutePaths.map((path) => ({
    path,
    ...horizonRouteToObject(searchJobsHorizonRouteExports),
  })),

  ...searchInternshipsRoutePaths.map((path) => ({
    path,
    ...horizonRouteToObject(searchInternshipsHorizonRouteExports),
  })),

  ...searchVolopsRoutePaths.map((path) => ({
    path,
    ...horizonRouteToObject(searchVolopsHorizonRouteExports),
  })),

  ...searchEventsRoutePaths.map((path) => ({
    path,
    ...horizonRouteToObject(searchEventsHorizonRouteExports),
  })),

  ...searchOrgsRoutePaths.map((path) => ({
    path,
    ...horizonRouteToObject(searchOrgsHorizonRouteExports),
  })),
] satisfies RouteObject[];
