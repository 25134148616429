import { userDashboardManageEventsRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ManageEventsPageLoadable } from './ManageEventsPageLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof userDashboardManageEventsRoute>,
) {
  await ManageEventsPageLoadable.load();
}

export default function UserDashboardManageEventsHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <ManageEventsPageLoadable />;
}
