import type { RefObject } from 'react';
import { useCallback } from 'react';

import type { InViewportStatus } from './useInViewportEffect';
import { useInViewportEffect } from './useInViewportEffect';

type Props<T extends HTMLElement> = {
  ref: RefObject<T>;
  threshold?: number;
  offset?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  onReach: (inViewportStatus: InViewportStatus) => void | boolean;
};

/**
 * Triggers onReach the first time the element is visible in the
 * viewport
 */
export function useElementReachedEffect<T extends HTMLElement>({
  ref,
  offset,
  threshold,
  onReach,
}: Props<T>): void {
  useInViewportEffect({
    ref,
    threshold,
    offset,
    freezeOnceVisible: true,
    callback: useCallback(
      (inViewportStatus) =>
        inViewportStatus.inViewport && onReach(inViewportStatus),
      [onReach],
    ),
  });
}
