import {
  SEARCH_EVENT_REFINEMENT_TYPES,
  type SearchEventRefinementType,
} from './searchEventRefinementTypes';
import type { SearchInternshipRefinementType } from './searchInternshipRefinementTypes';
import { SEARCH_INTERNSHIP_REFINEMENT_TYPES } from './searchInternshipRefinementTypes';
import type { SearchJobsRefinementType } from './searchJobsRefinementTypes';
import { SEARCH_JOBS_REFINEMENT_TYPES } from './searchJobsRefinementTypes';
import type { SearchOrgsRefinementType } from './searchOrgsRefinementTypes';
import { SEARCH_ORGS_REFINEMENT_TYPES } from './searchOrgsRefinementTypes';
import type { SearchVolopRefinementType } from './searchVolopRefinementTypes';
import { SEARCH_VOLOP_REFINEMENT_TYPES } from './searchVolopRefinementTypes';

export type SearchRefinementType =
  | SearchJobsRefinementType
  | SearchInternshipRefinementType
  | SearchVolopRefinementType
  | SearchOrgsRefinementType
  | SearchEventRefinementType;

export const SEARCH_REFINEMENT_TYPES = {
  JOB: SEARCH_JOBS_REFINEMENT_TYPES,
  INTERNSHIP: SEARCH_INTERNSHIP_REFINEMENT_TYPES,
  VOLOP: SEARCH_VOLOP_REFINEMENT_TYPES,
  ORG: SEARCH_ORGS_REFINEMENT_TYPES,
  EVENT: SEARCH_EVENT_REFINEMENT_TYPES,
} as const;
