import { getStaticImageSrc } from 'utils/getImageSrc';

import { ToastIconImg } from './ToastIcon.styled';

type Props = {
  name: string;
  width: number;
  height: number;
};

export function ToastIcon({ name, width, height }: Props) {
  return (
    <ToastIconImg
      alt=""
      aria-hidden="true"
      width={width}
      height={height}
      src={getStaticImageSrc(`action-toast/toast-icon-${name}.svg`)}
    />
  );
}
