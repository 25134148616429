export class HttpError extends Error {
  statusCode: number;

  responseText: string | null | undefined;

  url?: string | null;

  responseJson?: {
    errors: Array<{
      description: string;
      location: string;
      name: string;
      type?: string;
      data?: Record<string, unknown>;
    }>;
    status: 'error';
  };

  errors: APIErrors | null | undefined;

  constructor(method: string, url: string, xhr: XMLHttpRequest) {
    const messageParts = [
      `Request URL: ${url}`,
      `Request method: ${method}`,
      `Status code: ${xhr.status}`,
      `Response headers: \r\n${xhr.getAllResponseHeaders()}`,
    ];

    if (xhr.responseText) {
      messageParts.push(`\r\nResponse body: \r\n${xhr.responseText}`);
    }

    super(messageParts.join('\r\n'));
    this.name = 'HTTPError';
    this.statusCode = xhr.status;

    if (xhr.responseText) {
      this.responseText = xhr.responseText;

      const contentType = xhr.getResponseHeader('Content-Type');

      if (contentType && contentType.indexOf('json') > -1) {
        try {
          const responseJson = JSON.parse(xhr.responseText);
          this.responseJson = responseJson;

          if (!responseJson || !('errors' in responseJson)) {
            return;
          }
          this.errors = responseJson.errors.reduce(
            (
              acc: APIErrors,
              { description, name }: { description: string; name: string },
            ) => {
              // eslint-disable-next-line no-param-reassign
              acc[name] = description;
              return acc;
            },
            {},
          );
        } catch {}
      }
    }
  }
}
