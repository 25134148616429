import queryString from 'query-string';

import type { ApiListingApplicationListingType } from 'api/types/ApiListingApplicationListingType';
import type { ApiOrgId } from 'api/types/ApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import type { OrgDashboardApiListingApplicationMini } from './types/OrgDashboardApiListingApplicationMini';

type ApiResponse = {
  count: number;
  listingApplications: OrgDashboardApiListingApplicationMini[];
};

function mapResponse(response: ApiResponse) {
  return response.listingApplications;
}

const endpoint = new FetchEndpoint({
  urlFactory: (
    orgId: ApiOrgId,
    params: { type: ApiListingApplicationListingType; id: string },
  ) => {
    const qs = queryString.stringify(params);
    return `/data/orgdashboard/${orgId}/listing-applications?${qs}`;
  },
  mapResponse,
});

// Exports

export const orgDashboardApiFetchListingApplications = endpoint.createFetchFn();

export const orgDashboardApiHorizonFetchListingApplications =
  endpoint.createHorizonFetchFn();
