import type { ShouldRevalidateFunctionArgs } from 'react-router';

import { cmsApiHorizonFetchPage } from 'modules/cms/api/cmsApiFetchPage';
import { CmsContentPageLoadable } from 'modules/cms/containers/ContentPage/CmsContentPageLoadable';
import { CmsContentPageAssignLocation } from 'modules/cms/containers/ContentPageAssignLocation/CmsContentPageAssignLocation';
import { CmsContentPageReplaceLocation } from 'modules/cms/containers/ContentPageReplaceLocation/CmsContentPageReplaceLocation';
import { getCmsSearchLandingPagePageLayoutHandleProps } from 'modules/cms/helpers/getCmsSearchLandingPagePageLayoutHandleProps';
import { CmsPagePageLoadable } from 'modules/cms/pages/Page/CmsPagePageLoadable';
import { getCmsPagePageLayoutHandleProps } from 'modules/cms/pages/Page/getCmsPagePageLayoutHandleProps';
import { CmsPostPageLoadable } from 'modules/cms/pages/Post/CmsPostPageLoadable';
import { getCmsPagePostLayoutHandleProps } from 'modules/cms/pages/Post/getCmsPagePostLayoutHandleProps';
import { PostSearchPageLoadable } from 'modules/postSearch/pages/PostSearch/PostSearchPageLoadable';
import { getPostSearchPageLayoutHandleProps } from 'modules/postSearch/pages/PostSearch/getPostSearchPageLayoutHandleProps';
import { SearchLandingPageLoadable } from 'modules/search/pages/Landing/SearchLandingPageLoadable';
import { NotFoundPageLoadable } from 'pages/NotFound/NotFoundPageLoadable';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';
import type { HorizonLoaderFunctionArgs } from 'routing/types/HorizonLoaderFunctionArgs';

import type { CmsPageLoaderData } from './CmsPageLoaderData';
import { cmsRouteRevalidation } from './cmsRouteRevalidation';

export const id = 'cms-route';

export const cmsRouteId = id;

// Only revalidate if the pathname changes or `?pq=` is added or removed
export function shouldRevalidate({
  currentUrl,
  nextUrl,
}: ShouldRevalidateFunctionArgs): boolean {
  if (cmsRouteRevalidation.force) return true;

  if (currentUrl.pathname !== nextUrl.pathname) return true;
  if (currentUrl.searchParams.has('pq') !== nextUrl.searchParams.has('pq'))
    return true;

  return false;
}

export async function loader({
  request,
  context,
  params,
}: HorizonLoaderFunctionArgs<Record<'*', string>>): Promise<CmsPageLoaderData> {
  const slug = params['*'];

  const { searchParams } = new URL(request.url);

  try {
    const content = await cmsApiHorizonFetchPage({ request, context })(slug);

    if (content.type === 'CMS_REDIRECT') {
      return { type: 'CONTENT_REPLACE_LOCATION', url: content.url };
    }

    // FREE_CODE blocks with <script> tags won't be properly executed in
    // dynamically loaded pages due to browser security restrictions, so we need
    // to reload the page in that case. Previous discussion:
    // https://idealist.slack.com/archives/CHJ9JK7TP/p1730383641821849
    if (
      typeof global.window !== 'undefined' &&
      'contentBlocks' in content &&
      content.contentBlocks?.some((block) => block.type === 'FREE_CODE')
    ) {
      const requestUrl = new URL(request.url);
      const url = requestUrl.pathname + requestUrl.search + requestUrl.hash;

      return { type: 'CONTENT_ASSIGN_LOCATION', url };
    }

    if (content.type === 'SEARCH_LANDING_PAGE') {
      await SearchLandingPageLoadable.load();
      return {
        type: 'SEARCH_LANDING_PAGE',
        content,
        isSsr: typeof global.window === 'undefined',
        handle: getCmsSearchLandingPagePageLayoutHandleProps({ content }),
      };
    }

    if (searchParams.has('pq')) {
      await PostSearchPageLoadable.load();
      return {
        type: 'POST_SEARCH',
        content,
        handle: getPostSearchPageLayoutHandleProps({ content }),
      };
    }

    if (content.type === 'PAGE') {
      await Promise.all([
        CmsContentPageLoadable.load(),
        CmsPagePageLoadable.load(),
      ]);

      return {
        type: 'CONTENT',
        content,
        handle: getCmsPagePageLayoutHandleProps({ content }),
      };
    }

    // content.type === 'POST'
    await Promise.all([
      CmsContentPageLoadable.load(),
      CmsPostPageLoadable.load(),
    ]);

    return {
      type: 'CONTENT',
      content,
      handle: getCmsPagePostLayoutHandleProps({ content }),
    };
  } catch {
    try {
      await NotFoundPageLoadable.load();
    } catch {}

    return null;
  }
}

export default function CmsHorizonRoute({
  loaderData,
}: HorizonAppRouteComponentProps<typeof loader>) {
  switch (loaderData?.type) {
    case undefined:
      return <NotFoundPageLoadable noLayout />;
    case 'CONTENT_REPLACE_LOCATION':
      return <CmsContentPageReplaceLocation url={loaderData.url} />;
    case 'CONTENT_ASSIGN_LOCATION':
      return <CmsContentPageAssignLocation url={loaderData.url} />;
    case 'CONTENT':
      return <CmsContentPageLoadable content={loaderData.content} />;
    case 'SEARCH_LANDING_PAGE':
      return (
        <SearchLandingPageLoadable
          content={loaderData.content}
          isSsr={loaderData.isSsr}
        />
      );
    case 'POST_SEARCH':
      return <PostSearchPageLoadable content={loaderData.content} />;
  }
}
