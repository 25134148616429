import { SearchFacetAccordionItem } from 'modules/search/components/Facet/AccordionItem/SearchFacetAccordionItem';
import { SearchFacetDropdown } from 'modules/search/components/Facet/Dropdown/SearchFacetDropdown';
import { SearchFacetRadioButtonOptions } from 'modules/search/components/Facet/Options/SearchFacetRadioButtonOptions';
import { isPointGeotype } from 'modules/search/helpers/isPointGeotype';
import { useSearchFacetSelectedValue } from 'modules/search/hooks/facets/useSearchFacetSelectedValue';
import { useSearchRadiusOptions } from 'modules/search/hooks/facets/useSearchRadiusOptions';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { useSearchLocation } from 'modules/search/zustand-stores/searchStore';

type Props = {
  variant?: SearchRefinementVariant;
};

export function RadiusFacet({ variant = 'dropdown' }: Props) {
  const searchLocation = useSearchLocation();

  const FacetVariant =
    variant === 'accordion' ? SearchFacetAccordionItem : SearchFacetDropdown;

  const options = useSearchRadiusOptions();
  const selectedFacetValue = useSearchFacetSelectedValue('radius');

  if (!searchLocation || !isPointGeotype(searchLocation.geoType)) return null;

  return (
    <FacetVariant icon="bulls-eye" facetType="radius" label={getText('Radius')}>
      <SearchFacetRadioButtonOptions
        facetType="radius"
        initialValue={selectedFacetValue}
        options={options}
      />
    </FacetVariant>
  );
}
