import { trackEvent } from 'utils/analytics/track/trackEvent';

type TrackDisplayToastRequiredParams = {
  type: string;
  current_page_url: string;
};

type TrackDismissToastRequiredParams = TrackDisplayToastRequiredParams;

export function trackDisplayToast<T extends TrackDisplayToastRequiredParams>(
  params: T,
): void {
  trackEvent('Displayed: Action Toast', params);
}

export function trackDismissToast<T extends TrackDismissToastRequiredParams>(
  params: T,
): void {
  trackEvent('Dismissed: Action Toast', params);
}
