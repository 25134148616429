import { apiHorizonFetchUserEnvironment } from 'api/userEnvironment/apiFetchUserEnvironment';
import type { HorizonAppLoaderContext } from 'horizon/types/horizon-app-loader-context';

type Args = {
  request: Request;
  context: HorizonAppLoaderContext;
};

export function getHorizonUserEnvironmentPromiseFromContext({
  request,
  context,
}: Args) {
  if (!context.userEnvironmentPromise) {
    // eslint-disable-next-line no-param-reassign
    context.userEnvironmentPromise = apiHorizonFetchUserEnvironment({
      request,
      context,
    })();
  }

  return context.userEnvironmentPromise;
}
