let renderingState: 'server' | 'client-not-hydrated' | 'client-hydrated' =
  typeof window === 'undefined' ? 'server' : 'client-not-hydrated';

const listeners: Array<() => void> = [];

// Actions

export function setClientHydrated() {
  renderingState = 'client-hydrated';
  listeners.forEach((listener) => listener());
}

// Hooks

export function isRenderingServer() {
  return renderingState === 'server';
}

export function isRenderingHorizonServer() {
  return isRenderingServer() && process.env.IDEALIST_HORIZON_ENABLED === 'true';
}

export function isClientHydrated() {
  return renderingState === 'client-hydrated';
}

export function onClientHydrated(fn: () => void) {
  if (isClientHydrated()) {
    fn();
    return;
  }

  listeners.push(fn);
}
