import { create } from 'zustand';

import type { FlashMessageStyle } from 'components/FlashMessage/flashMessageTypeMap';
import { deleteCookie, getListCookie } from 'utils/cookies';
import { uuid } from 'utils/functional/string/uuid';

const getFlashMessageId = () => uuid() + new Date().getTime().toString(36);

function getFlashMessages(): FlashMessageType[] {
  if (typeof window !== 'undefined') {
    const messages =
      getListCookie<Omit<FlashMessageType, 'id'>>('flashMessages');
    deleteCookie('flashMessages');

    return messages.map((message) => ({ ...message, id: getFlashMessageId() }));
  }

  return [];
}

const flashMessagesStore = create<{ flashMessages: FlashMessageType[] }>(
  () => ({ flashMessages: getFlashMessages() }),
);

const useFlashMessagesStore = flashMessagesStore; // The store can be used as a hook

// Actions

export function pushFlashMessage(message: Omit<FlashMessageType, 'id'>) {
  flashMessagesStore.setState((state) => ({
    flashMessages: [
      ...state.flashMessages,
      { ...message, id: getFlashMessageId() },
    ],
  }));
}

export function pushCustomFlashMessage(
  message: string,
  style?: FlashMessageStyle,
) {
  pushFlashMessage({
    type: 'CUSTOM',
    data: { message, style: style || 'success' },
  });
}

export function removeFlashMessage(id: string) {
  flashMessagesStore.setState((state) => ({
    flashMessages: state.flashMessages.filter((message) => message.id !== id),
  }));
}

export function clearFlashMessages() {
  // remove all messages that aren't sticky
  flashMessagesStore.setState((state) => ({
    flashMessages: state.flashMessages.filter((message) => message.sticky),
  }));
}

// Hook

export function useFlashMessages() {
  const flashMessages = useFlashMessagesStore((state) => state.flashMessages);
  return { flashMessages };
}
