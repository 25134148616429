import { searchVolopsRoutePathByLocale } from 'modules/search/routing/search.route-paths';
import { searchVolopsRouteReference } from 'modules/search/routing/search.routes';
import type { SearchVolopFiltersByName } from 'modules/search/types/FiltersByName/SearchVolopFiltersByName';
import type { SearchSortType } from 'modules/search/types/SearchSortType';

import { searchGetQueryParamsFromFiltersByName } from './searchGetQueryParamsFromFiltersByName';

enum RadiusType {
  state = 'state',
  country = 'country',
}

type Args = {
  lang: UserLocale;
  q?: string;
  radius?: RadiusType | string | null;
  filtersByName?: Omit<SearchVolopFiltersByName, 'type'>;
  sort?: SearchSortType;
  pageIndex?: number;
};

export function searchGetVolopsRoutePath({
  lang,
  filtersByName,
  q,
  radius,
  sort,
  pageIndex,
}: Args) {
  const queryParams = searchGetQueryParamsFromFiltersByName(filtersByName);
  if (q) queryParams.q = q;
  if (radius) queryParams.radius = radius;
  if (sort && sort !== 'relevance') queryParams.sort = sort;
  if (pageIndex) queryParams.page = pageIndex + 1;

  return searchVolopsRouteReference
    .with(queryParams)
    .replace('/-lang/-segment', searchVolopsRoutePathByLocale[lang]);
}
