import type { RouteObject } from 'react-router';

import * as authForgotPasswordHorizonRouteExports from 'modules/auth/pages/forgotPassword/AuthForgotPassword.horizon-route';
import * as authLoginHorizonRouteExports from 'modules/auth/pages/login/AuthLogin.horizon-route';
import * as authMfaHorizonRouteExports from 'modules/auth/pages/mfa/AuthMfa.route-objects';
import * as authResetPasswordHorizonRouteExports from 'modules/auth/pages/resetPassword/AuthResetPassword.horizon-route';
import * as authSignupHorizonRouteExports from 'modules/auth/pages/signup/AuthSignup.horizon-route';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

import {
  authForgotPasswordRoute,
  authLoginRoute,
  authMfaRoute,
  authResetPasswordRoute,
  authSignupRoute,
} from './auth.routes';

export const authRouteObjects = [
  {
    path: authLoginRoute.fullPath,
    ...horizonRouteToObject(authLoginHorizonRouteExports),
  },
  {
    path: authSignupRoute.fullPath,
    ...horizonRouteToObject(authSignupHorizonRouteExports),
  },
  {
    path: authForgotPasswordRoute.fullPath,
    ...horizonRouteToObject(authForgotPasswordHorizonRouteExports),
  },
  {
    path: authResetPasswordRoute.fullPath,
    ...horizonRouteToObject(authResetPasswordHorizonRouteExports),
  },
  {
    path: authMfaRoute.fullPath,
    ...horizonRouteToObject(authMfaHorizonRouteExports),
  },
] satisfies RouteObject[];
