import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

export const TooltipContext = createContext({
  closeTooltip: () => {},
});

export const useTooltipContext = () => useContext(TooltipContext);

export function TooltipProvider({
  children,
  value,
}: PropsWithChildren<{ value: { closeTooltip: () => void } }>) {
  return (
    <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
  );
}
