import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export const TabContext = createContext<boolean>(true);

export function TabContextProvider({ children }: { children: ReactNode }) {
  const [isTabbing, updateIsTabbing] = useState(true);
  useEffect(() => {
    const keyUpHandler = ({ which }: { which: number }) => {
      if (which === 9 || which === 13) {
        updateIsTabbing(true);
      }
    };

    const clickHandler = () => {
      updateIsTabbing(false);
    };

    window.addEventListener('keyup', keyUpHandler);
    window.addEventListener('mousedown', clickHandler);
  }, []);
  return (
    <TabContext.Provider value={isTabbing}>{children}</TabContext.Provider>
  );
}

export const useTabContext = () => useContext(TabContext);
