// Job

export const searchJobsRoutePathByLang = {
  en: '/en/jobs',
  es: '/es/empleos',
  pt: '/pt/empregos',
} as const;

export const searchJobsRoutePaths = Object.values(searchJobsRoutePathByLang);

// Internship

export const searchInternshipsRoutePathByLocale = {
  en: '/en/internships',
  es: '/es/pasantias',
  pt: '/pt/estagios',
} as const;

export const searchInternshipsRoutePaths = Object.values(
  searchInternshipsRoutePathByLocale,
);

// volop

export const searchVolopsRoutePathByLocale = {
  en: '/en/volunteer',
  es: '/es/voluntariado',
  pt: '/pt/voluntariado',
} as const;

export const searchVolopsRoutePaths = Object.values(
  searchVolopsRoutePathByLocale,
);

// Event

export const searchEventsRoutePathByLocale = {
  en: '/en/events',
  es: '/es/eventos',
  pt: '/pt/eventos',
} satisfies Record<UserLocale, string>;

export const searchEventsRoutePaths = Object.values(
  searchEventsRoutePathByLocale,
);

// Org

export const searchOrgsRoutePathByLocale = {
  en: '/en/organizations',
  es: '/es/organizaciones',
  pt: '/pt/organizacoes',
} satisfies Record<UserLocale, string>;

export const searchOrgsRoutePaths = Object.values(searchOrgsRoutePathByLocale);
