import type { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { VOLOP_SOURCES } from 'utils/constants/general/volopSources';

export const volopSourceFacet = {
  id: 'volopSourceFacet',
  name: 'source',
  get title() {
    return getText('Source');
  },
  type: 'array',
  options: Object.keys(VOLOP_SOURCES),
  optionTitles: VOLOP_SOURCES,
} as const satisfies SearchFacetData;
