import type { PropsWithChildren } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { Accordion } from 'components/Accordion/Accordion';
import { TextBadge } from 'components/Badge/TextBadge';
import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { DropdownMenu } from 'components/Dropdown/menus/Menu/DropdownMenu';
import { Icon } from 'components/Icon/Icon';
import { Pill } from 'components/Pill/Pill';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';
import { SearchRefinementDropdownHolder } from 'modules/search/components/Refinement/Dropdown/SearchRefinementDropdown.styled';
import { useSearchRefinementsOverflow } from 'modules/search/hooks/refinements/useSearchRefinementsOverflow';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { spacing } from 'theme/theme';

export function SearchRefinementsOverflowDropdown({
  children,
}: PropsWithChildren) {
  const { overflowRefinementsRef, selectedRefinementValues } =
    useSearchRefinementsOverflow();

  return (
    <SearchRefinementDropdownHolder
      id="overflow-dropdown"
      ref={overflowRefinementsRef}
      data-qa-id="search-refinements-overflow-dropdown"
    >
      <Dropdown
        trigger={
          <Pill
            size="medium"
            prefix={<Icon size={18} name="plus" />}
            suffix={<DropdownChevron size={16} />}
          >
            {getText('More Filters')}{' '}
            <TextBadge content={selectedRefinementValues} />
          </Pill>
        }
      >
        <DropdownMenu
          offsetY={spacing[8]}
          placement="bottom-left"
          width="350px"
        >
          <Accordion>
            <ScrollArea
              height="calc(100vh - 211px)"
              direction="vertical"
              hideScrollBar
            >
              {Children.map(children, (child) => {
                if (
                  !isValidElement<{
                    variant: SearchRefinementVariant;
                  }>(child)
                ) {
                  return child;
                }

                return cloneElement(child, {
                  variant: 'accordion',
                });
              })}
            </ScrollArea>
          </Accordion>
        </DropdownMenu>
      </Dropdown>
    </SearchRefinementDropdownHolder>
  );
}
