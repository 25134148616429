import type { ApiVolopImportedType } from 'modules/listing/api/volop/types/ApiVolopImportedType';

export const VOLOP_IMPORTED_TYPE_MAP: Record<ApiVolopImportedType, string> = {
  VOLUNTEERMATCH: 'VolunteerMatch',
  POINTS_OF_LIGHT: 'Points of Light',
  AARP: 'AARP',
  DO_SOMETHING: 'DoSomething',
  NEWYORKCARES: 'New York Cares',
  VOLUNTEERGOV: 'Volunteer.gov',
  TRANSFORMA: 'Transforma',
  GOLDEN: 'Golden',
};
