import type { ReactNode, RefObject } from 'react';
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Portal } from 'components/Portal/Portal';
import { useAnimation } from 'hooks/useAnimation';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { DefaultTooltipButton } from './DefaultTooltipButton';
import {
  TargetContainer,
  TooltipCloseButton,
  TooltipContainer,
  TooltipWrapper,
} from './Tooltip.styled';
import { TooltipProvider } from './TooltipContext';
import type { TooltipPosition } from './TooltipPosition';
import type { TooltipSize } from './TooltipSize';
import type { TooltipVariant } from './TooltipVariant';

type Props = {
  ref?: RefObject<unknown>;
  animate?: boolean;
  children?: ReactNode;
  renderInPortal?: boolean;
  closeable?: boolean;
  content: ReactNode;
  className?: string;
  onClose?: () => void;
  placement?: TooltipPosition;
  tooltipWidth?: number;
  renderCondition?: boolean;
  triggerCondition?: boolean;
  size?: TooltipSize;
  variant?: TooltipVariant;
  withArrow?: boolean;
  onRender?: () => void;
};

export function Tooltip({
  ref,
  animate = false,
  renderInPortal = false,
  placement = 'top-center',
  children,
  closeable,
  onClose,
  content,
  className,
  tooltipWidth,
  renderCondition,
  triggerCondition = true,
  size = 'small',
  variant = 'brand-blue',
  withArrow = true,
  onRender,
}: Props) {
  const [currentPlacement, setCurrentPlacement] = useState(placement);
  const [showTooltip, setShowTooltip] = useState(renderCondition || false);
  const [isClosing, setIsClosing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [targetWidth, setTargetWidth] = useState<number>(0);
  const [targetHeight, setTargetHeight] = useState<number>(0);
  const [targetTop, setTargetTop] = useState<number>(0);
  const [targetLeft, setTargetLeft] = useState<number>(0);

  const { stage, shouldRender } = useAnimation(showTooltip, animate ? 200 : 0);

  const handleShowTooltip = () => {
    if (isClosing) {
      setIsClosing(false);
      return;
    }

    setShowTooltip(true);
  };

  const hideTooltip = () => {
    setShowTooltip(false);
    setIsClosing(false);
  };

  const handleTriggerMouseOver = () => {
    const isTouchDevice = 'ontouchstart' in window;

    if (tooltipRef.current !== null) return;

    if (isTouchDevice) {
      return;
    }

    if (triggerCondition) {
      handleShowTooltip();
    }
  };

  const handleTouchEnd = () => {
    if (renderCondition === undefined) {
      setShowTooltip(!showTooltip);
    }
  };

  const handleTriggerMouseLeave = () => {
    if (showTooltip) {
      hideTooltip();
    }
  };

  const closeTooltip = useCallback(() => {
    setIsClosing(true);
    setShowTooltip(false);
    if (onClose) onClose();
  }, [onClose]);

  useOnClickOutside({
    elementRef: containerRef,
    onClickOutside: closeTooltip,
  });

  useImperativeHandle(ref, () => ({
    openTooltip: handleShowTooltip,
    closeTooltip,
  }));

  useEffect(() => {
    setShowTooltip(renderCondition || false);
  }, [renderCondition]);

  useEffect(() => {
    if (!targetWidth && !targetHeight && targetRef.current) {
      const { width, height, top, left } =
        targetRef.current.getBoundingClientRect();

      setTargetWidth(width);
      setTargetHeight(height);

      if (renderInPortal) {
        setTargetTop(Math.ceil(top + window.scrollY));
        setTargetLeft(left + window.scrollX);
      }
    }
  }, [targetWidth, targetHeight, showTooltip, targetRef, renderInPortal]);

  const resizeHandler = useCallback(() => {
    if (targetRef.current) {
      const { bottom, left, right, top } =
        targetRef.current.getBoundingClientRect();
      let newPlacement: TooltipPosition | null = null;

      // @NOTE: Would be nice to find a way to use the real tooltipMinimumWidth
      // width and height more directly, but we don't always have access to it
      // since it is hidden, so we use the target and infer the width/height
      // as best we can
      const tooltipMinimumHeight = 120;
      const tooltipMinimumWidth = tooltipWidth || size === 'large' ? 300 : 50;

      const arrowSize = size === 'large' ? 20 : 10;
      const arrowHorizontalOffset = targetWidth < arrowSize ? 20 : 0;

      if (
        left - tooltipMinimumWidth + (arrowSize + arrowHorizontalOffset) <
        0
      ) {
        newPlacement = currentPlacement.includes('left')
          ? (currentPlacement.replace('left', 'right') as TooltipPosition)
          : currentPlacement;
      } else if (right + tooltipMinimumWidth > window.innerWidth) {
        newPlacement = currentPlacement.includes('right')
          ? (currentPlacement.replace('right', 'left') as TooltipPosition)
          : currentPlacement;
      } else if (bottom + tooltipMinimumHeight > window.innerHeight) {
        newPlacement = currentPlacement.includes('bottom')
          ? (currentPlacement.replace('bottom', 'top') as TooltipPosition)
          : currentPlacement;
      } else if (top - tooltipMinimumHeight < 0) {
        newPlacement = currentPlacement.includes('top')
          ? (currentPlacement.replace('top', 'bottom') as TooltipPosition)
          : currentPlacement;
      }

      // Update the placement if needed
      if (newPlacement && newPlacement !== currentPlacement) {
        setCurrentPlacement(newPlacement);
      } else if (!newPlacement && currentPlacement !== placement) {
        // Revert to the initial placement if conditions no longer apply
        setCurrentPlacement(placement);
      }
    }
  }, [currentPlacement, placement, size, targetWidth, tooltipWidth]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }
  }, [resizeHandler]);

  useEffect(() => {
    if (showTooltip && onRender) onRender();
  }, [showTooltip, onRender]);

  return (
    <TooltipProvider value={{ closeTooltip }}>
      <TargetContainer
        ref={containerRef}
        onTouchEnd={handleTouchEnd}
        onMouseOver={
          renderCondition === undefined ? handleTriggerMouseOver : undefined
        }
        onMouseLeave={closeable ? undefined : handleTriggerMouseLeave}
      >
        <div ref={targetRef}>
          {children || <DefaultTooltipButton type="button" />}
        </div>
        {shouldRender ? (
          <Portal id="tooltip-root" disabled={!renderInPortal}>
            <TooltipContainer
              ref={tooltipRef}
              onAnimationEnd={() => setIsClosing(false)}
              onClick={(e) => e.stopPropagation()}
              className={className}
              $animate={animate}
              $targetWidth={targetWidth}
              $targetHeight={targetHeight}
              $targetTop={targetTop}
              $targetLeft={targetLeft}
              $placement={currentPlacement}
              $variant={variant}
              $size={size}
              $tooltipWidth={tooltipWidth}
              $withArrow={withArrow}
              $stage={stage}
            >
              <TooltipWrapper $size={size}>
                {closeable ? (
                  <TooltipCloseButton
                    onClick={closeTooltip}
                    data-qa-id="close-modal-button"
                    data-modal-focus="false"
                  />
                ) : null}
                {content}
              </TooltipWrapper>
            </TooltipContainer>
          </Portal>
        ) : null}
      </TargetContainer>
    </TooltipProvider>
  );
}
