import type { PropsWithChildren, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { DropdownMenu } from 'components/Dropdown/menus/Menu/DropdownMenu';
import { Icon } from 'components/Icon/Icon';
import type { IconName } from 'components/Icon/IconName';
import { Pill } from 'components/Pill/Pill';
import { SearchRefinementDropdownHolder } from 'modules/search/components/Refinement/Dropdown/SearchRefinementDropdown.styled';
import type { SearchRefinementType } from 'modules/search/constants/refinements/searchRefinementTypes';
import type { SearchRefinementVariant } from 'modules/search/types/SearchRefinementVariant';
import { spacing } from 'theme/theme';

import { useSearchFacetDropdown } from './useSearchFacetDropdown';

type Props = {
  facetType: SearchRefinementType;
} & {
  icon?: IconName;
  label: ReactNode;
};

export function SearchFacetDropdown({
  children,
  facetType,
  icon,
  label,
}: PropsWithChildren<Props>) {
  const { dropdownStatus, holderRef, toggleDropdown } =
    useSearchFacetDropdown(facetType);

  return (
    <SearchRefinementDropdownHolder
      data-qa-id={`search-facet-dropdown-${facetType}`}
      ref={holderRef}
      data-facet-state={dropdownStatus}
    >
      <Dropdown
        onToggle={toggleDropdown}
        trigger={
          <Pill
            qaId="search-filter-dropdown-heading"
            size={{ xs: 'small', md: 'medium' }}
            prefix={icon ? <Icon size={18} name={icon} /> : undefined}
            suffix={<DropdownChevron size={16} />}
          >
            {label}
          </Pill>
        }
      >
        <DropdownMenu offsetY={spacing[8]} placement="bottom-left">
          <div data-facet-type={facetType}>
            {isValidElement<{ variant: SearchRefinementVariant }>(children)
              ? Children.only(cloneElement(children, { variant: 'dropdown' }))
              : children}
          </div>
        </DropdownMenu>
      </Dropdown>
    </SearchRefinementDropdownHolder>
  );
}
