import type { ApiUser } from 'api/userEnvironment/types/ApiUserEnvironment';
import { getUserType } from 'utils/analytics/helpers/getUserType';
import { registerMixpanelProperties } from 'utils/analytics/helpers/mixpanel';
import { deleteCookie } from 'utils/cookies';
import { trackError } from 'utils/trackError';

import { trackEvent } from './trackEvent';

export function trackLogin(user: ApiUser | null | undefined, method: string) {
  trackEvent('User Logged In', {
    user_id: user?.id,
    method,
    user_type: getUserType(user),
  });

  if (user?.id) {
    registerMixpanelProperties({
      user_id: user.id,
      user_type: getUserType(user),
    });
  } else {
    trackError('Missing user id after log in');
  }

  deleteCookie('justLoggedIn');
}
