import type { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import type { CmsApiSearchLandingPage } from 'modules/cms/api/types/CmsApiSearchLandingPage';

type Args = {
  content: CmsApiPage | CmsApiSearchLandingPage;
};

export function getContentLocaleUrl({ content }: Args): Record<string, string> {
  return content.translations.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.locale]: `/${cur.url}`,
    }),
    content.type === 'SEARCH_LANDING_PAGE'
      ? {}
      : { [content.locale]: `/${content.url}` },
  );
}
