import { type ReactNode, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router';

import { BannerAds } from 'components/BannerAds/BannerAds';
import { PageFooterLoadable } from 'components/PageFooter/PageFooterLoadable';
import { AcceptCookiesContainer } from 'containers/AcceptCookiesContainer';
import { PageHeaderContainer } from 'containers/PageHeader/PageHeaderContainer';
import { SignInOrUpReminderContainer } from 'containers/SignInOrUpReminder/SignInOrUpReminderContainer';
import { useUser } from 'contexts/UserEnvironmentContext';
import { useAdManager } from 'hooks/useAdManager';
import { useCombinedRouterMatchesHandle } from 'hooks/useCombinedRouterMatchesHandle';
import { colors } from 'theme/theme';

import {
  LayoutChildrenHolder,
  LayoutHolder,
  LayoutProgressBar,
  LayoutProgressBarAbsoluteBottomHolder,
  LayoutProgressBarFixedHolder,
} from './Layout.styled';
import { LayoutBottomOfPageElement } from './LayoutBottomOfPageElement';
import type { LayoutHandleProps } from './LayoutHandleProps';

type Props = {
  children?: ReactNode;
  // TODO: remove the handle props once all routes use <Layout> through react-router
} & LayoutHandleProps;

export function Layout({ children, ...handleProps }: Props) {
  const location = useLocation();

  const handlePropsWithRouteMatchesHandle =
    useCombinedRouterMatchesHandle(handleProps);

  const {
    noAds: noAdsHandle,
    darkBackground,
    hideFooterDivider: hideFooterDividerHandle,
    defaultSearchType,
    searchFromPageType,
    showDesktopSearch,
    isSearchPage,
    isSearchLandingPage,
    searchRefinements,
    variant = 'default',
    // When children is provided, don't use the handle props
  } = children ? handleProps : handlePropsWithRouteMatchesHandle;

  const { user } = useUser();
  const isLoggedOut = !user;

  const noAds = useMemo(() => {
    if (noAdsHandle === 'no-ads-if-logged-out') return isLoggedOut;

    return noAdsHandle;
  }, [noAdsHandle, isLoggedOut]);

  const hideFooterDivider = useMemo(() => {
    if (hideFooterDividerHandle === 'hide-footer-divider-if-logged-out')
      return isLoggedOut;

    return hideFooterDividerHandle;
  }, [hideFooterDividerHandle, isLoggedOut]);

  useAdManager(noAds);

  const urlByLocale =
    handlePropsWithRouteMatchesHandle.urlByLocale ||
    (handlePropsWithRouteMatchesHandle.fallbackUrlByLocaleToCurrent
      ? { en: location.pathname, es: location.pathname, pt: location.pathname }
      : undefined);

  return (
    <LayoutHolder
      style={{
        background: darkBackground ? colors.backgroundGrey : undefined,
      }}
    >
      {variant !== 'base' && <BannerAds />}

      {variant === 'base' ? (
        <LayoutProgressBarFixedHolder>
          <LayoutProgressBar />
        </LayoutProgressBarFixedHolder>
      ) : (
        <PageHeaderContainer
          defaultSearchType={defaultSearchType || 'JOB'}
          showDesktopSearch={Boolean(showDesktopSearch)}
          searchFromPageType={searchFromPageType}
          isInSearchPage={Boolean(isSearchPage)}
          isInSearchLandingPage={Boolean(isSearchLandingPage)}
          progressBar={
            <LayoutProgressBarAbsoluteBottomHolder>
              <LayoutProgressBar />
            </LayoutProgressBarAbsoluteBottomHolder>
          }
          searchRefinements={searchRefinements}
        />
      )}

      <LayoutChildrenHolder>{children || <Outlet />}</LayoutChildrenHolder>

      {variant !== 'base' && (
        <>
          <LayoutBottomOfPageElement />

          <PageFooterLoadable
            locale={CURRENT_LOCALE()}
            urlByLocale={urlByLocale}
            showDivider={!hideFooterDivider}
          />
        </>
      )}

      <AcceptCookiesContainer />
      <SignInOrUpReminderContainer />
    </LayoutHolder>
  );
}

export default Layout;
