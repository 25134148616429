import { create } from 'zustand';

import type {
  ToastData,
  ToastDismissalReason,
  ToastDismissedEventDetail,
  ToastId,
} from 'types/ToastData';
import { uuid } from 'utils/functional/string/uuid';

// Store

const toastsStore = create<{ toasts: ToastData[] }>(() => ({
  toasts: [],
}));

const useToastsStore = toastsStore; // The store can be used as a hook

// Actions

export function showToast(data: Omit<ToastData, 'id'> & { id?: ToastId }) {
  const id: ToastId = data.id || uuid();

  toastsStore.setState(({ toasts }) => ({
    toasts: [...toasts, { ...data, id }],
  }));

  if (data.durationMs) {
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent<ToastDismissedEventDetail>('idealist:toast-dismissed', {
          detail: { id },
        }),
      );
    }, data.durationMs);
  }

  return id;
}

export function dismissToast(
  toastId: ToastId,
  dismissalReason?: ToastDismissalReason,
) {
  toastsStore.setState(({ toasts }) => {
    const toast = toasts.find((t) => t.id === toastId);
    if (toast?.onDismiss) toast.onDismiss(dismissalReason);

    return {
      toasts: toasts.filter((t) => t.id !== toastId),
    };
  });
}

// Getters

export function isDisplayingToast(toastId: ToastId) {
  return toastsStore.getState().toasts.some((toast) => toast.id === toastId);
}

// Hook

export function useToasts() {
  const toasts = useToastsStore((state) => state.toasts);
  return { toasts };
}

// Note that we're agnostic of the actual value of the localStorage item, we
// just want to know if it's set.
export function isToastDisabledForDebug(toastId: ToastId) {
  if (typeof globalThis.window === 'undefined') return true;
  return (
    globalThis.window.localStorage.getItem(
      `idealist::debug::toasts:${toastId}::disabled`,
    ) !== null
  );
}
