import type { RouteObject } from 'react-router';

import * as viewUserProfileHorizonRouteExports from 'modules/userProfile/pages/ViewUserProfile/ViewUserProfile.horizon-route';
import { horizonRouteToObject } from 'routing/utils/horizonRouteToObject';

import { viewUserProfileRoute } from './userProfile.routes';

export const userProfileRouteObjects = [
  {
    path: viewUserProfileRoute.fullPath,
    ...horizonRouteToObject(viewUserProfileHorizonRouteExports),
  },
] satisfies RouteObject[];
