import { authForgotPasswordRoute } from 'modules/auth/routing/auth.routes';
import type { HorizonAppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import type { HorizonAppRouteComponentProps } from 'routing/types/HorizonAppRouteComponentProps';

import { ForgotPasswordPageContainerLoadable } from './ForgotPasswordPageContainerLoadable';

export async function loader(
  _: HorizonAppRouteLoaderArgs<typeof authForgotPasswordRoute>,
) {
  await ForgotPasswordPageContainerLoadable.load();
}

export default function AuthForgotPasswordHorizonRoute(
  _: HorizonAppRouteComponentProps<typeof loader>,
) {
  return <ForgotPasswordPageContainerLoadable />;
}
