import type { SearchType } from 'modules/search/types/SearchType';
import { LISTING_TYPE_HUMAN_NAME_MAP_SEARCH } from 'utils/constants/general/listingTypeHumanNameMapSearch';

import { ListingTypeSelectStyled } from './ListingTypeSelect.styled';

type Props = {
  name: string;
  defaultValue: SearchType;
  searchTypes: SearchType[];
  variant: 'grey' | 'outline-blue';
  onChange?: (option: SearchType) => void;
};

export function UncontrolledListingTypeSelect({
  name,
  defaultValue,
  searchTypes,
  variant,
  onChange,
}: Props) {
  const options = searchTypes.map((type) => ({
    value: type,
    label: LISTING_TYPE_HUMAN_NAME_MAP_SEARCH()[type],
  }));

  return (
    <ListingTypeSelectStyled
      data-qa-id="listing-type-select"
      aria-label={getText('Select listing type')}
      name={name}
      options={options}
      defaultValue={defaultValue}
      disabled={searchTypes.length === 1}
      height={48}
      $variant={variant}
      onChange={
        onChange ? (newValue) => onChange(newValue as SearchType) : undefined
      }
    />
  );
}
