import { styled } from 'styled-components';

import { LegacyButton } from 'components/LegacyButton/LegacyButton';
import { fontWeights, transition } from 'theme/theme';

import { legacyButtonSize } from './legacyButtonSize';

export const LegacyButtonWithStyles = styled(LegacyButton).attrs((props) => ({
  type: props.type === undefined ? 'button' : props.type,
}))<{ $size?: 'small' | 'medium' | 'large' }>`
  /* button minimal styles */
  display: inline-block;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  transition: all ${transition};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  /* button appearance */
  border-radius: 8px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.5px;
  justify-content: center;

  ${legacyButtonSize};
`;
